<template>
	<div id="rights" ondrag="return false">
		<Top></Top>
		<div class="box1">
			<div class="row1">
				为企业量身定制服务措施
			</div>
			<div class="row2">
				成为科技牛会员企业，让企业成就更多可能
			</div>
			<div class="btn" @click="do_pay">
				立即加入
			</div>
		</div>
		<div class="box2">
			<div class="box-title">
				会员特权
			</div>
			<div class="box-title-bg">
				PRIVILEGE
			</div>
			<div class="box_desc">
				科技牛平台为企业打造会员专享特权服务，入驻会员享受后续业务的9折优惠！
			</div>
			<div class="table">
				<template>
					<el-table :data="tableData" :stripe="true" style="width: 100%">
						<el-table-column align="center" prop="title" label="特权" width="330">
						</el-table-column>
						<el-table-column align="center" label="尊享会员" width="236">
							<!-- <i class="el-icon-check"></i> -->
							<img src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/rights/icon1.png">
						</el-table-column>
						<el-table-column align="center" label="普通用户">
							<!-- <i class="el-icon-minus"></i> -->
							<img src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/rights/icon2.png">
						</el-table-column>
					</el-table>
				</template>
			</div>

		</div>
		<div class="box3">
			<div class="box-title">
				资源丰富
			</div>
			<div class="box-title-bg">
				RESOURCES
			</div>
			<div class="box_desc">
				科技牛平台汇聚20万+科研专家、5万+的成果库和16万+的企业家为企业提供更专业、更优质的解决方案
			</div>
			<div class="row1">
				<img src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/rights/member1.png">
				<div class="col2">
					<div class="number">
						01
					</div>
					<div class="title">
						丰富的专家资源
					</div>
					<div class="content">
						科技牛平台专门面向企业用户提供专家咨询服务。企业在产品研发、技术升级过程中碰到专业难题，可以通过线上文字、电话与专家沟通或者预约专家线下约谈，针对具体的问题专家进行解答，让企业更高效的解决难题。
					</div>
				</div>
			</div>
			<div class="row2">
				<div class="col2">
					<div class="number">
						02
					</div>
					<div class="title">
						多元化的成果库
					</div>
					<div class="content">
						目前科技牛成果库已有5万+，如果企业对某个成果感兴趣，可以以技术入股和许可转让等多种方式进行合作，进而实现产学研有效合作与对接。
					</div>
				</div>
				<img src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/rights/member2.png">
			</div>
			<div class="row1">
				<img src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/rights/member3.png">
				<div class="col2">
					<div class="number">
						03
					</div>
					<div class="title">
						各行业的企业精英
					</div>
					<div class="content">
						目前科技牛汇聚了16万+优秀的企业家，在这里企业可以获取更多的知识，结实到各行业的精英，减少企业间的交易成本。使市场供求关系更为稳定，更好地推动产品研发和营销。</div>
				</div>
			</div>

		</div>
		<div class="box4"></div>
		<div class="warp">
			<div class="rights-s">
				<h1>加入科技牛会员，助力企业腾飞</h1>
				<span v-if="member_info.member_price"><em>￥</em>{{ member_info.member_price / 100 }}/年</span>
				<button @click="do_pay">立即加入</button>
			</div>
		</div>
		<el-dialog :visible.sync="centerDialogVisible" width="20%" center>
			<span class="title">科技牛企业会员</span>

			<!-- <el-image style="width: 180px; height: 180px" src="https://ossweb-img.qq.com/images/lol/web201310/skin/big84000.jpg" fit="cover"></el-image> -->
			<div id="qrcode" style="width: 100px; height: 180px;margin: 0 auto;display: flex;align-items: center;"
				ref="qrcode">
			</div>
			<span class="price">￥{{ member_info.member_price / 100 }}</span>
			<span slot="footer" class="dialog-footer">
				<span class="footer-text">支持使用微信支付</span>
				<span class="footer-deal">支付即表示同意<span class="deal">《科技牛企业会员服务协议》</span></span>
			</span>
		</el-dialog>
		<flexRight></flexRight>
	</div>
</template>

<script>
import Top from "../../components/top.vue"
import flexRight from "@/components/flexRight.vue"
import QRCode from 'qrcodejs2'
export default {
	name:'RightsIndex',
	metaInfo: {
		title: '科技牛—为企业精准匹配科研专家以及提供技术解决方案的服务平台',
		meta: [{
			name: 'keywords',
			content: '技术对接,技术交易,技术对接平台,技术创新服务,技术转移,成果转化,技术推广,解决方案'
		},
		{
			name: 'description',
			content: '科技牛是一家专业为企业提供四技服务:技术开发、技术转让、技术咨询、技术服务，精准匹配科研专家及技术解决方案平台公司'
		},

		],

	},
	components: {
		Top,
		flexRight
	},
	data() {
		return {
			centerDialogVisible: false,
			scan: {},
			code_url: '',
			member_info: {},
			tableData: [{
				title: '企业总裁通讯录',
			}, {
				title: '服务费用9折优惠',
			}, {
				title: '定制沙龙见面会',
			}, {
				title: '会员年会',
			}, {
				title: '公众号企业宣传',
			}, {
				title: '成交案例宣传',
			}, {
				title: '服务号需求推送',
			}, {
				title: '平台活动优先通知',
			}, {
				title: '企业分析报告',
			}]
		}
	},
	mounted() {
		this.get_company_member()
	},
	methods: {
		get_company_member() {
			this.$http_qm.post('company/member', {
				name: 'member_price'
			}).then(res => {
				this.member_info = res.data;
			}).catch(err => {
				console.log(err)
			})
		},
		do_pay() {
			this.centerDialogVisible = true
			if (this.code_url != '') {
				return false;
			}

			console.log('do_pay')
			this.$http.post('pay/pay_submit', {
				type: "wx_scan",
				order_type: 3,
			}).then(result => {
				if (result.code !== 200) {
					this.$message.info("获得订单信息失败")
					return;
				}
				this.code_url = result.data.code_url;
				new QRCode(this.$refs.qrcode, {
					text: result.data.code_url, // 需要转换为二维码的内容
					width: 100,
					height: 100,
					colorDark: '#000000',
					colorLight: '#ffffff',
					correctLevel: QRCode.CorrectLevel.H
				})
				// let data = JSON.parse(result.data)
				//函数为分装过得  (就是调用微信支付)

			}).catch(err => {
				console.log(err);
			})
		},
	}
}
</script>

<style lang="less">
#rights {
	min-width: 1400px;

	.box1 {
		height: 680px;
		width: 100%;
		background: url('https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/rights/member_bg.jpg') no-repeat center top;
		text-align: center;

		.row1 {
			padding: 246px 0px 0px;
			font-size: 44px;

			font-weight: 800;
			color: #FFFFFF;
			line-height: 59px;
		}

		.row2 {
			padding: 32px 0px 0px;
			font-size: 18px;
			font-family: PingFang SC;
			font-weight: 500;
			color: #FFFFFF;
			line-height: 59px;
		}

		.btn {
			width: 160px;
			height: 56px;
			line-height: 56px;
			margin: 99px auto 0px;
			background: #176EFF;
			border-radius: 4px;
			font-size: 20px;
			font-weight: 500;
			color: #FFFFFF;
			cursor: pointer;
		}
	}

	.box-title {
		text-align: center;
		font-size: 34px;
		font-family: PingFang SC;
		font-weight: 800;
		color: #333333;
		line-height: 59px;
		margin-top: 109px;
	}

	.box-title-bg {
		font-size: 52px;
		font-family: PingFang SC;
		font-weight: 800;
		color: #333333;
		line-height: 24px;
		opacity: 0.05;
		text-align: center;
		z-index: -1;
		top: -42px;
		position: relative;
	}

	.box_desc {
		text-align: center;
		font-size: 18px;
		font-family: PingFang SC;
		font-weight: 500;
		color: #333333;
		margin: 25px 0px 52px;

	}

	.box2 {
		.table {
			width: 800px;
			margin: auto;
			border-top: 2px solid #FFD86F;

			table {
				tr {
					height: 84px;
				}

				thead {
					th {
						border: 1px solid #EBEEF5;
						font-size: 24px;
						font-family: PingFang SC;
						font-weight: 800;
						color: #333333;
						line-height: 24px;
					}
				}

				td {
					border-color: #EBEFF1;
					font-size: 20px;
					font-family: PingFang SC;
					font-weight: 500;
					color: #333333;
				}

			}

		}
	}

	.box3 {
		width: 930px;
		margin: auto;

		.col2 {
			.number {
				font-size: 60px;
				font-family: DIN;
				font-weight: bolder;
				color: #333333;
				opacity: 0.1;
			}

			.title {

				font-size: 28px;
				font-family: PingFang SC;
				font-weight: 800;
				color: #333333;
				line-height: 24px;
			}

			.content {
				width: 360px;
				font-size: 20px;
				font-family: PingFang SC;
				font-weight: 500;
				color: #666666;
				margin: 23px 0px 0px;
				line-height: 30px;
			}
		}

		.row1,
		.row2,
		.row3 {
			display: flex;
			justify-content: space-between;
			margin-bottom: 150px;
		}
	}

	.warp {
		width: 1350px;
		margin: 0 auto;
	}

	.img {
		margin: 0 auto !important;
		width: 100%;
	}

	.rights-s {
		height: 520px;
		display: flex;
		text-align: center;
		justify-content: center;
		flex-direction: column;

		h1 {
			font-size: 42px;
			font-family: PingFang SC;
			font-weight: 800;
			color: #333333;
		}

		span {
			font-size: 40px;
			font-family: PingFang SC;
			font-weight: 800;
			color: #176EFF;
			font-size: 60px;
			margin-top: 33px;

			em {
				font-size: 40px;
			}
		}

		button {
			width: 400px;
			height: 100px;
			background: #176EFF;
			box-shadow: 0px 11px 20px 1px rgba(19, 79, 180, 0.26);
			border-radius: 10px;
			margin: 50px auto 0px;
			cursor: pointer;
			outline: none;
			border: none;
			font-size: 46px;
			font-family: PingFang SC;
			font-weight: 800;
			color: #FFFEFE;
			line-height: 24px;
		}

		button:hover {
			background: #0088ff;
		}
	}

	@media(max-width:1200px) {
		.rights-s {
			height: 460px;

			h1 {
				font-size: 26px;
				font-weight: 700;
			}

			span {
				font-size: 32px;
				font-weight: 700;
				margin: 20px 0;

				em {
					font-size: 12px;
				}
			}

			button {
				width: 340px;
				height: 65px;
				font-size: 22px;
			}
		}
	}

	.el-dialog__body {
		padding: 15px 25px 0;
		display: flex;
		justify-content: center;
	}

	.el-dialog {
		border-radius: 10px;
	}

	.dialog-footer {
		display: flex;
		flex-direction: column;

		.footer-text {
			color: #333;
			font-size: 16px;
			font-weight: 600;
			letter-spacing: 1px;
		}

		.footer-deal {
			color: #333;
			font-size: 14px;
			font-weight: 600;
			letter-spacing: 1px;

			.deal {
				color: #0088ff;
				cursor: pointer;
			}
		}
	}

	.el-dialog__body {
		display: flex;
		flex-direction: column;
		justify-content: center;
		text-align: center;

		.title {
			font-weight: 600;
			color: #825015;
			letter-spacing: 2px;
			font-size: 22px;
		}

		.el-image {
			margin: 15px auto 0;
			box-sizing: border-box;

			.el-image__inner {
				padding: 15px;
				background: #Fbfbfb;
				box-sizing: border-box;
				border: 1px solid #f0f0f0;
			}
		}

		.price {
			color: #e10100;
			font-size: 26px;
			font-weight: 700;
			margin-top: 18px;
		}
	}
}
</style>
