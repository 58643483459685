<template>
	<div class="index-left fl">
		<div class="new-shop-info">
			<div class="base-info level-15">
				<div class="main-info">
					<h3 class="f16 text-bold text-overflow-2" :title="data.name">
						{{ data.name }} </h3>
					<div class="shop-level"></div>
					<div class="info-line margin-top-25 flex">
						<label for="" class="text-muted f14">认证：</label>
						<div>
							<i class="company on" title="企业认证"></i>
							<i class="phone margin-left-10 on" title="手机认证"></i>
						</div>
					</div>

					<div class="info-line margin-top-10 " v-if="data.operation_mode">
						<label for="" class="text-muted f14">擅长：</label>
						<div>
							<p class="f14">{{ data.operation_mode }}</p>
						</div>
					</div>

					<div class="info-line margin-top-10 flex">
						<label for="" class="text-muted f14">服务：</label>
						<div>
							<p class="f14"> <span v-for="tag in data.tag" :key="tag"> {{ tag }} </span> </p>
						</div>
					</div>

				</div>
				<div class="confirm-box  clearfix">
					<span :class="collect ? 'collect-active' : 'collect'" @click="do_collect()">{{ data.is_collect ?
						'已收藏' : '收藏店铺' }}</span>
					<span class="ask-for f12 fr">
						<router-link
							:to="{ path: '/store/leave', query: { id: id, product_id: product_id } }">留言咨询</router-link>
					</span>

				</div>
			</div>

			<div class="contact-box margin-top-20">
				<p class="f16 text-bold">联系我们</p>
				<p class="f14 text-bold margin-top-20" v-if="data.contact_name">{{ data.contact_name }}</p>
				<div class="info-line margin-top-10 flex">
					<label for="" class="text-muted f14">电话：</label>
					<div>
						<p class="f14" v-if="data.contact_tel">{{ data.contact_tel }}</p>
					</div>
				</div>
				<div class="info-line margin-top-10 flex">
					<label for="" class="text-muted f14">地址：</label>
					<div>
						<p class="f14" v-if="data.addr">{{ data.province }}{{ data.city }} {{ data.area }}{{ data.addr}}</p>
					</div>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
export default {
	name: 'StoreLeft',
	data() {
		return {
			collect: false,
			LocalData:this.data,
		}
	},
	props: {
		data: {
			type: Object,
		},
		id: {
			type: String,
		},
		product_id: {
			type: String,
		}
	},
	methods: {
		do_collect() {
			this.$http.post('collect/add', { collect_id: this.id, type: 4, is_collect: this.data.is_collect }).then(res => {
				if (res.code == 200) {
					this.LocalData.is_collect = res.data.is_collect
				}
			}).catch(err => {
				console.log(err);
			})
		}
	}
}
</script>

<style></style>
