<template>
	<div id="demand">
		<div class="f-content fn-clear">
			<!--面包屑end-->
			<div class="column-search">
				<el-breadcrumb separator-class="el-icon-arrow-right">
					<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item :to="{ path: '/demand' }">需求列表</el-breadcrumb-item>
					<el-breadcrumb-item>需求详情</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
			<div class="xq-content fn-clear">
				<!--左边-->
				<div class="cg_l fn-clear">
					<!--介绍-->
					<div class="cg_tit fn-clear">
						<div class="en-on-01" v-if="data.status == 0">方案征集中</div>
						<div class="cg_img fn-clear">
							<el-image style="width: 260px; height: 190px" v-if="data.cover" :src="data.cover[0]"
								fit="cover"></el-image>
						</div>
						<div class="cg_txt fn-clear">
							<div class="cg_name fn-clear">{{ data.title }}</div>
							<div class="dtl_conts fn-clear">
								<div class="top-box">
									<div class="l-box">
										<div class="yd">
											<i class="icon-btn-18 icon-comment-18"></i>{{ data.view_num }}
										</div>
										<div class="yd">
											<i class="icon-btn-18 icon-collect-18"></i>{{ data.collect_num }}
										</div>
									</div>
								</div>
								<ul class="fn-clear">
									<li>合作金额：{{ data.budget_amount }}万元</li>
									<li>剩余时间：{{ data.rest_day }}天</li>
									<li class="w400">关键字:
										<span class="label-nac" v-for="(tag, k) in data.tag" :key="k">{{ tag }}</span>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<!--介绍end-->
					<div class="pat-process pat-sty">背景</div>
					<div class="description">
						{{ data.background }}
					</div>
					<div class="pat-process2 pat-sty">需求描述</div>
					<div class="description" v-html="data.describe"></div>
					<div class="pat-process9 pat-sty">约束条件</div>
					<div class="description">
						{{ data.condition }}
					</div>
					<div class="pat-process3 pat-sty">技术要求</div>
					<div class="description">
						{{ data.requirements }}
					</div>
				</div>
				<!--左边-->
				<!--右边-->
				<div class="cg_r fn-clear">
					<!--按钮-->
					<div class="requirements">
						<div id="planBtn" class="requirements-btn" @click="centerDialogVisible = true">
							<img src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/other/requirements.png">提交方案
						</div>
					</div>

					<!--按钮end-->
					<!--按钮-->
					<div class="poster-btn-line">
						<div class="ta-btn" @click="dialogVisible = true">
							<i class="icon-btn-19"></i>联系TA
						</div>
						<el-popover placement="bottom" width="180" trigger="hover"
							content="这是一段内容,这是一段内容,这是一段内容,这是一段内容。">
							<share :config="config"></share>
							<div class="share-btn" slot="reference">
								<i class="icon-btn-20"></i>分享
							</div>
						</el-popover>
					</div>
					<!--按钮end-->
					<el-dialog title="站内消息" :visible.sync="dialogVisible" width="40%" :before-close="handleClose">
						<div class="msg_dialogue">
							<!--聊天记录一列 -->
							<div class="msg_dialogue_list">
								<div class="wp-chatlist wp-chatlist-self" v-for="(v, index) in chat.list" :key="index">
									<!-- right -->
									<template v-if="user.id == v.uid">
										<div class="avatar_right">{{ v.avatar_name }}</div>
										<div class="wp-right-content">
											<em class="wp-arrow"></em>
											<em class="wp-arrow wp-arrow-inner"></em>
											<p v-html="v.content"></p>
										</div>
										<div class="wp-chat-time">{{ v.time }}</div>
									</template>
									<template v-else>
										<!-- left -->
										<div class="avatar_left">{{ v.avatar_name }}</div>
										<div class="wp-left-content">
											<em class="wp-arrow"></em>
											<em class="wp-arrow wp-arrow-inner"></em>
											<p v-html="v.content"></p>
										</div>
										<div class="wp-chat-time">{{ v.time }}</div>
									</template>
								</div>

							</div>
							<!--聊天记录一列end-->
						</div>
						<div class="send">
							<span>发消息</span>
							<el-input type="textarea" v-model="message" :rows="2" placeholder="请输入内容"></el-input>
						</div>
						<span slot="footer" class="dialog-footer">
							<el-button type="danger" size="small" @click="dialogVisible = false">关闭</el-button>
							<el-button type="primary" size="small" @click="send()">发送</el-button>
						</span>
					</el-dialog>
					<!--按钮-->
					<!--方案列表-->

					<!--方案列表end-->
					<!--热点需求-->
					<div class="news-wrapper ">
						<div class="news-rank-title">热点需求</div>
						<div class="news-item">
							<ul class="news-ul">
								<li v-for="(v, index) in hot_demand" @click="demand_href(v.id)" :key="index">
									<div class="news-li" v-if="index < 4">
										<el-image class="news-img" v-if="v.cover" :src="v.cover[0]"
											fit="cover"></el-image>
										<div class="news-li-r">
											<div class="news-tit">{{ v.title }}</div>
											<div class="news-date">剩余时间：{{ v.rest_day }}天</div>
										</div>
									</div>
								</li>
							</ul>
						</div>
					</div>
					<!--热点需求end-->

					<el-dialog title="需求应答" :visible.sync="centerDialogVisible" width="40%" center>
						<p class="sketch">我承接的需求： <span>{{ data.title }}</span></p>
						<el-form :model="form" label-position="right">
							<el-form-item label="姓名" :label-width="formLabelWidth" :rules="[{ required: true }]"
								:inline="true">
								<el-input v-model="form.name" autocomplete="off"></el-input>
							</el-form-item>
							<el-form-item label="电话" :label-width="formLabelWidth" :rules="[{ required: true }]">
								<el-input v-model="form.tel" autocomplete="off"></el-input>
							</el-form-item>
							<el-form-item label="方案描述" :label-width="formLabelWidth" :rules="[{ required: true }]">
								<el-input type="textarea" v-model="form.describe"
									placeholder="请对提交方案进行描述及分析,建议文字300字以上"></el-input>
							</el-form-item>
							<el-form-item label="附件" :label-width="formLabelWidth">
								<el-upload class="upload-demo" ref="upload" :action="baseUrlqm + '/upload/file'"
									:on-preview="handlePreview" :on-remove="handleRemove" :data="upload_data"
									:on-success="upload_success" :limit="3" :file-list="fileList">
									<el-button slot="trigger" size="small" type="primary">选取文件</el-button>
								</el-upload>
							</el-form-item>
						</el-form>
						<p class="sketch">上传详细的解决方案有利于对接，文档格式为.docx，.doc或.pdf，大小控制在5M以内。</p>
						<span slot="footer" class="dialog-footer">
							<el-button @click="centerDialogVisible = false">取 消</el-button>
							<el-button type="primary" @click="submit_scheme()">确 定</el-button>
						</span>
					</el-dialog>
				</div>
				<!--右边-->
			</div>
		</div>
		<flexRight></flexRight>
	</div>
</template>

<script>
import config from '@/config'
var wsUrl = process.env.NODE_ENV === 'development' ? config.wsUrl.dev : config.wsUrl.pro;
import flexRight from "@/components/flexRight.vue"
import {
	mapGetters
} from 'vuex'
export default {
	name:'DemandDetail',
	metaInfo: {
		title: '科技牛—为企业精准匹配科研专家以及提供技术解决方案的服务平台',
		meta: [{
			name: 'keywords',
			content: '技术对接,技术交易,技术对接平台,技术创新服务,技术转移,成果转化,技术推广,解决方案'
		},
		{
			name: 'description',
			content: '科技牛是一家专业为企业提供四技服务:技术开发、技术转让、技术咨询、技术服务，精准匹配科研专家及技术解决方案平台公司'
		}
		]
	},
	components: {
		flexRight
	},
	data() {
		return {
			centerDialogVisible: false,
			data: {},
			form: {
				name: '',
				tel: '',
				describe: '',
				annex: [],
				demand_id: '',
			},
			upload_data: {
				dir: 'demand'
			},
			formLabelWidth: '100px',
			fileList: [],
			id: '',
			hot_demand: [],

			dialogVisible: false,
			textarea: '',
			chat_post: {
				page: 1,
				limit: 1000,
				room_id: '',
			},
			chat: {
				list: [],
			},
			room_id: '',
			to_uid: '',
			message: '',
			ws: null,
			config: {
				title: '科技牛需求分享',
				description: '科技牛最新需求链接内容详情分享',
				url: 'http://pc.keji6.cn/#/demand/detail?id=' + this.id,
				source: 'http://pc.keji6.cn/#/demand/detail?id=' + this.id,
				image: 'http://hellofood.fun/webicon.png',
				// summary : '吃货召唤', //相当于description
				// sites   : ['qzone', 'qq', 'weibo','wechat', 'douban'], // 启用的站点
				disabled: ['google', 'facebook', 'twitter', 'linyin'], // 禁用的站点
				wechatQrcodeTitle: "微信扫一扫：分享", // 微信二维码提示文字
				wechatQrcodeHelper: '立即下载发送专属二维码,邀请朋友加入',
			}
		}
	},
	computed: {
		...mapGetters(['user'])
	},
	mounted() {
		let param = this.$route.query;
		this.id = param.id;
		this.form.demand_id = param.id;
		this.get_detail()
		this.get_hot_demand();
		console.log(this.user);
	},

	methods: {
		demand_href(id) {
			this.id = id
			this.get_detail()
			this.get_hot_demand();
		},
		get_chat_list() {
			console.log('room_id', this.room_id);
			if (this.room_id == '') {
				return
			}
			this.chat_post.room_id = this.room_id
			this.$http.post('chat/list', this.chat_post).then(res => {

				if (res.data != null) {
					this.chat = res.data
					// this.chat_post.page += 1
				} else {
					// this.chat.loading = false
				}
			}).catch(function (error) {
				console.log(error)
			})
		},
		send() {

			let send_query = {
				'method': 'chat.emit',
				'params': {
					'message': this.message,
					'uid': this.user.id,
					'to_uid': this.data.uid,
					'room_id': this.room_id
				},
				'id': this.room_id
			}
			send_query = JSON.stringify(send_query)
			this.ws.send(send_query)
		},

		webSocket_init() {

			if ('WebSocket' in window) {
				console.log('您的浏览器支持 WebSocket!')
				let ws_url = wsUrl
				this.ws = new WebSocket(ws_url)
				// 连接成功建立的回调方法
				this.ws.onopen = this.onopen
				this.ws.onmessage = this.onmessage
				// 连接发生错误的回调方法
				this.ws.onerror = this.onerror
				// 连接关闭的回调方法
				this.ws.onclose = this.onclose
			} else {
				// 浏览器不支持 WebSocket
				this.$message.info('您的浏览器不支持 WebSocket!')
			}
		},
		onclose() {
			// 关闭 websocket
			console.log('连接已关闭...')
		},
		onerror(e) {
			console.log('websocket服务出错了---onerror', e)
		},
		onopen() {
			this.join_room()
		},
		onmessage(res) {
			let data = JSON.parse(res.data)
			this.chat.list.push(data.result)
			this.$nextTick(() => {
				this.message = ''
			})
		},

		get_room_id() {
			this.$http.post('room/get_id', {
				to_uid: this.data.uid
			}).then(res => {
				if (res.code == 200) {
					this.room_id = res.data.id;
					this.webSocket_init()

					this.get_chat_list();
				}
			}).catch(function (error) {
				console.log('error')
				console.log(error)
			})
		},
		join_room() {
			let add_room_data = {
				'method': 'join.room',
				'params': {
					'roomid': this.room_id,
					'uid': this.data.uid,
				},
				id: this.room_id
			}
			// id 必须存在 否则回报错
			add_room_data = JSON.stringify(add_room_data)
			this.ws.send(add_room_data)
			console.log('加入房间' + this.room_id)
		},
		bShare() {

		},
		handleClose(done) {
			this.$confirm('确认关闭？')
				.then(() => {
					done();
				})
				.catch(() => { });
		},
		upload_success(response, file, fileList) {
			console.log(response);
			this.form.annex.push(response.data.img_url);
			console.log(file);
			console.log(fileList);
		},
		submit_scheme() {
			this.centerDialogVisible = false;
			/* 	let config = {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				};
				let formData = new FormData();
				formData.append("file", '111'); */
			console.log(this.form);
			this.$http_qm.post('scheme/add', this.form).then(res => {
				this.$message.info(res.msg);
			}).catch(err => {
				console.log(err)
			})
		},
		get_hot_demand() {
			this.$http_qm.post('demand/list', {
				type: 'hot',
				page: 1,
				limit: 3,
			}).then(res => {
				this.hot_demand = res.data;
			}).catch(err => {
				console.log(err)
			})
		},
		get_detail() {
			this.$http_qm.post('demand/detail', {
				id: this.id
			}).then(res => {
				if (res.code == 200) {
					this.data = res.data;
					this.get_room_id()
				}
			}).catch(err => {
				console.log(err)
			})
		},
		handleRemove(file, fileList) {
			console.log(file, fileList);
		},
		handlePreview(file) {
			console.log(file);
		},

	}
}
</script>

<style lang="less" src="../../assets/css/patter.less" />
