<template>
	<div id="zc_message">
		<div class="wrapper clearfix">
			<div class="main_left">
				<Left></Left>
			</div>
			<div class="content">
				<div class="page_nav clearfix">
					<div class="page_nav_tit">账号管理</div>
				</div>
				<div class="g_info">
					<h3>帐号信息</h3>
					<div><span></span></div>
				</div>
				<ul class="g_info_list clearfix">
					<li><span>会员名：</span><em>{{ userInfo.username }}</em></li>
					<!-- <li><span>微信：</span><b class="g_not">绑定账号</b></li> -->
					<li><span>密码：</span>
						<router-link to="./zc_password"> <b class=" g_not">修改密码</b></router-link>
					</li>
				</ul>
				<div class="g_info">
					<h3>个人信息</h3>
					<div>
						<span></span>
					</div>
				</div>
				<el-form ref="form" label-position="left" :model="form" label-width="80px">
					<div class="g_form clearfix">
						<div class="g_f_pic">
							<el-upload class="avatar-uploader" :action="baseUrl + 'file/upload_one'" :data="avatar_data"
								:show-file-list="false" :on-success="handleAvatarSuccess"
								:before-upload="beforeAvatarUpload">
								<img v-if="imageUrl" :src="imageUrl" class="avatar">
								<img v-else-if="userInfo.avatar" :src="userInfo.avatar" class="avatar">
								<i v-else class="el-icon-plus avatar-uploader-icon"></i>
							</el-upload>
							<span>上传图片</span>
						</div>
						<div class="g_f_box">
							<el-form-item label="姓名">
								<el-input v-model="userInfo.username"></el-input>
							</el-form-item>
							<el-form-item :inline="true" label="手机" class="demo-form-inline">
								<strong class="veri">{{ userInfo.tel }}</strong>
								<span class="veri_end">已验证</span>
							</el-form-item>
							<el-form-item :inline="true" label="邮箱" class="demo-form-inline">
								<span class="veri">{{ userInfo.email }}</span>
								<span class="veri_off" @click="to('/user_center/zc_verify')">去验证</span>
							</el-form-item>
							<el-form-item label="公司">
								<el-input v-model="userInfo.company"></el-input>
							</el-form-item>
							<el-form-item label="我的职位">
								<el-input v-model="userInfo.post"></el-input>
							</el-form-item>
							<el-form-item label="QQ">
								<el-input v-model="userInfo.qq"></el-input>
							</el-form-item>
							<el-form-item>
								<el-button :disabled="edit_disabled" type="primary" @click="do_edit()">确认</el-button>
							</el-form-item>
						</div>
					</div>
				</el-form>
			</div>
		</div>
	</div>
</template>

<script>
import Left from "./Left.vue"
import {
	mapMutations
} from 'vuex';
export default {
	name: 'CompanyzcMessage',
	metaInfo: {
		title: '科技牛—为企业精准匹配科研专家以及提供技术解决方案的服务平台',
		meta: [{
			name: 'keywords',
			content: '技术对接,技术交易,技术对接平台,技术创新服务,技术转移,成果转化,技术推广,解决方案'
		},
		{
			name: 'description',
			content: '科技牛是一家专业为企业提供四技服务:技术开发、技术转让、技术咨询、技术服务，精准匹配科研专家及技术解决方案平台公司'
		}
		]
	},
	components: {
		Left
	},
	data() {
		return {
			imageUrl: '',
			userInfo: {},
			avatar_data: {
				dir: 'user'
			},
			form: {},
			edit_disabled: false,
		}
	},
	mounted() {
		this.get_info();
	},
	methods: {
		...mapMutations(['setLogout', 'editUser']),
		get_info() {
			this.$http.post('user/info').then(res => {
				if (res.code == 200) {
					this.userInfo = res.data;
					this.editUser(res.data)
				} else if (res.code == 401) {
					this.userInfo = {}
					this.setLogout();
				}
			}).catch(err => {
				console.log(err);
			})
		},
		do_edit() {
			this.edit_disabled = true;
			let edit_data = {
				username: this.userInfo.username,
				tel: this.userInfo.tel,
				email: this.userInfo.email,
				company: this.userInfo.company,
				post: this.userInfo.post,
				qq: this.userInfo.qq,
			}
			if (this.imageUrl != '') {
				edit_data.avatar = this.imageUrl
			}
			this.$http.post('user/edit', edit_data).then(res => {
				if (res.code == 200) {
					this.$message.info(res.msg)
					this.$router.go(-1);
				} else {
					this.$message.error(res.msg)
				}
				this.edit_disabled = false;
			}).catch(err => {
				console.log(err);
			})
		},
		handleAvatarSuccess(res) {
			this.imageUrl = res.data.img_url;
		},
		to(url) {
			this.$router.push({
				path: url
			})
		},
		beforeAvatarUpload(file) {
			const isJPG = file.type === 'image/jpeg';
			const isLt2M = file.size / 1024 / 1024 < 5;

			if (!isJPG) {
				this.$message.error('上传头像图片只能是 JPG 格式!');
			}
			if (!isLt2M) {
				this.$message.error('上传头像图片大小不能超过 5MB!');
			}
			return isJPG && isLt2M;
		}
	}
}
</script>

<style lang="less" src="../../assets/css/expert.less"></style>
