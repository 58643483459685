<template>
	<div class="index">
		<Top></Top>
		<div class="box">
			<div class="main">
				<div class="normal_div_6">

					<div class="normal_div_7">
						<div class="li" @click="href({ path:'/service' })">
							<div class="title">企业服务</div>
							<div class="des">提升企业创新力量与 <br>
								产品核心竞争力</div>
						</div>
						<div class="li" @click="href({ path:'/service/government' })">
							<div class="title">政府服务</div>
							<div class="des">助力区域产学研升级，
								<br>
								服务地区企业创新发展
							</div>
						</div>
						<div class="li" @click="href({ path:'/service/colleges' })">
							<div class="title">科院服务</div>
							<div class="des">链接企业与科研院所， <br>
								推动产业化落地
							</div>
						</div>
					</div>
					<div class="normal_div_8">
						<el-carousel class="block_5" trigger="click">
							<el-carousel-item v-for="(item, k) in banner.list" :key="k">
								<template>
									<el-image class="layer" @click="href({ path: item.url })"
										style="width: 1010px; height: 420px" :src="item.img" fit="cover"></el-image>
								</template>
							</el-carousel-item>
						</el-carousel>

						<div class="block_6">
							<div class="group_1" @click="web_url('https://megagame.keji6.cn/')">
								<div class="normal_div_9">
									<span class="text_2">精彩赛事</span>
									<img class="largeIcon_1"
										src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/other/index4.jpg" />
								</div>
								<span class="info_14">科技牛技术需求 <br />暨解决方案暨征集大赛</span>
							</div>
							<div class="group_2" @click="toUrl('/service/list')">
								<div class="normal_div_10">
									<span class="text_3">服务项目</span>
									<img class="largeIcon_2"
										src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/other/index5.jpg" />
								</div>
								<span class="info_16">覆盖企业科技创新需求</span>
								<span class="info_17">金牌顾问全程保驾护航</span>
							</div>
							<div class="group_3" @click="toUrl('/news')">
								<div class="normal_div_11">
									<span class="text_4">政策专栏</span>
									<img class="largeIcon_3"
										src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/other/index6.jpg" />
								</div>
								<span class="info_18">掌握最新政策福利</span>
								<span class="info_19">发展机遇不容错过</span>
							</div>
							<div class="block_7">
								<div class="block_7_top" @click="toUrl('/user_center/zc_message')">
									<div class="normal_div_12">
										<el-avatar :size="56" v-if="user&&user.avatar" :src="user.avatar"></el-avatar>
										<img class="largeIcon_5" v-else
											src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/other/index7.jpg" />
									</div>
									<div class="normal_div_name">
										<template v-if="user">
											<span class="desc_2">{{ user.username }}</span>
											<span class="desc_post">{{ user.post }}</span>
										</template>
										<span class="desc_jks" v-else>您好！欢迎来到科技牛平台！</span>
									</div>
								</div>

								<div class="user-panel-task" v-if="user">
									<span>{{ user.company }}</span>
								</div>
								<div v-else class="normal_div_13">
									<div class="tagWrap">
										<router-link class="tag" to="/register">注册</router-link>
									</div>
									<div class="tagWrap_1">
										<router-link class="tag_1" to="/login">登录</router-link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="normal_div_84">
					<div class="line_4"></div>
					<div class="normal_div_85">
						<img class="icon_21"
							src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/other/index14.jpg" />
						<span class="info_54">最新技术成果</span>
					</div>
					<div class="colorDiv_14" />

					<div class="normal_div_86 text-center">
						<ul>
							<li :class="{ active: v.active }" @click="change_cg(v.name, k)" v-for="(v, k) in cg_cate"
								:key="k">{{ v.name }}</li>
							<li>
								<router-link :to="{ path: '/achievement' }">更多>></router-link>
							</li>
						</ul>
					</div>

					<div class="normal_div_87">
						<ul>
							<li class="entryPicWrap" v-for="(v, cg_index) in cg.list" :key="cg_index"
								@click="cg_item(v.id)">
								<el-image class="entryPic" :src="v.img[0]" fit="cover"></el-image>
								<router-link :to="{ path: 'achivement/detail', query: { id: v.id } }" class="txt_55">
									{{ v.title }}
								</router-link>
								<div class="bgWrap">
									<div class="entryPicWrap_4">
										<p class="desc_19">{{ v.jstd }}</p>
									</div>
								</div>
							</li>
						</ul>
					</div>
				</div>
				<div class="normal_div_14">
					<div class="line"></div>
					<div class="normal_div_15">
						<img class="icon_1" src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/other/index8.jpg" />
						<span class="text_5">需求大厅</span>
					</div>

					<div class="grid index_demand">
						<div class="col" v-for="(v, k) in demand_cate" :key="k">
							<router-link :to="{ path: '/demand', query: { demand_type: v.name } }"
								class="flex space-between">
								<div class="block_8">
									<span class="txt_4">{{ v.name }}</span>
									<span class="info_20">{{ v.name2 }}</span>
								</div>
								<span class="text_6">&gt;&gt;</span>
							</router-link>
							<ul>
								<li class="flex space-between" v-for="(v2, k2) in v.list" :key="k2">
									<div class="block_11">
										<router-link :to="{ name: 'demandDetail', query: { id: v2.id } }"
											class="info_21">{{ v2.title }}</router-link>
										<!-- <img src="https://via.placeholder.com/16x18" class="icon_2"> -->
									</div>
									<span class="word_4">{{ v2.bid_num }}人投标</span>
								</li>
							</ul>
						</div>
					</div>
				</div>


				<div class="normal_div_26">
					<div class="line_2"></div>
					<div class="normal_div_27">
						<img class="icon_13"
							src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/other/index11.jpg" />
						<span class="word_23">推荐服务商</span>
					</div>
					<div class="colorDiv_13"></div>
					<div class="grid_2" v-if="company && company.list">
						<template v-for="(v, company_index) in company.list">
							<div class="col_4" :key="company_index" v-if="v">
								<div class="group_14">
									<div class="normal_div_28">
										<!-- <img class="largeIcon_22" :src="v.logo" /> -->
										<el-image style="width: 65px; height: 65px" :src="v.logo" fit="cover">
										</el-image>
									</div>
									<div class="normal_div_29">
										<router-link class="address" target="_blank"
											:to="{ path: '/store/index', query: { id: v.id } }">{{ v.name }}
										</router-link>
										<div class="normal_div_30">
											<div class="normal_div_31" v-for="(tag, tag_index) in v.tag"
												:key="tag_index">
												<span class="txt_29">{{ tag }}</span>
											</div>
										</div>
										<div class="product-warp">
											<span class="desc_12">主营：</span>
											<span class="desc_12_tag" v-for="(g, l) in v.product"
												:key="l">{{ g.title }}</span>
											<span class="info_36"></span>
										</div>
									</div>
								</div>
								<div class="block_26">
									<router-link class="text_29" target="_blank"
										:to="{ path: '/store/index', query: { id: v.id } }">了解更多>></router-link>
									<router-link class="locationWrap" target="_blank"
										:to="{ path: '/store/index', query: { id: v.id } }">
										<span class="location_1">我要合作</span>
									</router-link>
								</div>
							</div>
						</template>
					</div>
				</div>
				<div class="normal_div_58">
					<div class="line_3"></div>
					<div class="normal_div_59">
						<img class="icon_14"
							src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/other/index12.jpg" />
						<span class="text_36">认证专家</span>
					</div>
					<div class="color_10"></div>
					<div class="grid_5">
						<div class="col_10" v-for="(v, expert_key) in expert.list" :key="expert_key">
							<div class="group_20">
								<div class="normal_div_60">
									<div class="normal_div_61">
										<el-image style="width: 65px; height: 65px" v-if="v.avatar" :src="v.avatar"
											fit="cover"></el-image>
									</div>
									<div class="normal_div_62">
										<div class="normal_div_63">
											<router-link class="txt_37"
												:to="{ path: '/expert/detail', query: { id: v.id } }">{{ v.name }}
											</router-link>
											<!-- 	<span class="word_32">服务</span>
											<span class="num">{{ v.service_num }}</span>
											<span class="word_33">人</span> -->
										</div>
										<span class="address_6">{{ v.danwei }}{{ v.zhiwu }}</span>
									</div>
								</div>
								<router-link :to="{ path: '/expert/detail', query: { id: v.id } }">
									<div class="markWrap_6"><span class="text_37">查看专家</span></div>
								</router-link>
							</div>
							<div class="block_32">
								<template v-for="(tag, index) in v.territory_tag">
									<div class="label1" v-if="index < 5" :key="index">
										<div class="txt_38">{{ tag }}</div>
									</div>
								</template>
							</div>

							<!-- <div class="bg"></div> -->
							<!-- <div class="block_33">
								<img class="icon_15"
									src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/other/index13.jpg" />
								<span class="count">{{ v.star_num }}</span>
								<span class="txt_39">{{ v.consulting_num }}条问题</span>
							</div> -->
						</div>
					</div>
				</div>
				<div class="normal_div_24">
					<div class="line_1"></div>
					<div class="normal_div_25">
						<img class="icon_12"
							src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/other/index10.jpg" />
						<span class="txt_16">企业服务</span>
					</div>
					<div class="color_5"></div>
					<div class="grid">
						<div class="col">
							<div class="group_4">
								<span class="word_10">智能制造</span>
								<div class="colorDiv_5"></div>
								<span class="text_18">委托研发</span>
								<span class="txt_17">生产新品</span>
								<span class="word_11">产品设计</span>
								<span class="text_19">产品代加工</span>
							</div>
							<div class="block_24">
								<img class="largeIcon_6"
									src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/index/syfw1.png" />
								<span class="txt_18">机械制造</span>
								<img class="largeIcon_7"
									src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/index/syfw5.png" />
								<span class="word_12">电子元件</span>
							</div>
							<div class="color_6"></div>
							<div class="colorDiv_6"></div>
							<div class="group_5">
								<img class="largeIcon_8"
									src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/index/syfw2.png" />
								<span class="text_20">人工智能</span>
								<img class="largeIcon_9"
									src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/index/syfw6.png" />
								<span class="txt_19">自动化设备</span>
							</div>
						</div>
						<div class="col_1">
							<div class="group_6">
								<span class="word_13">大健康</span>
								<div class="colorDiv_7"></div>
								<span class="text_21">节能环保</span>
								<span class="txt_20">医疗器械</span>
								<span class="word_14">健康家具</span>
								<span class="text_22">保健食品</span>
							</div>
							<div class="group_7">
								<img class="largeIcon_10"
									src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/index/syfw3.png" />
								<span class="txt_21">节能环保</span>
								<img class="largeIcon_11"
									src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/index/syfw7.png" />
								<span class="word_15">健康家居</span>
							</div>
							<div class="color_7"></div>
							<div class="colorDiv_8"></div>
							<div class="group_8">
								<img class="largeIcon_12"
									src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/index/syfw4.png" />
								<span class="txt_22">医疗服务</span>
								<img class="largeIcon_13"
									src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/index/syfw8.png" />
								<span class="word_16">保健食品</span>
							</div>
						</div>
					</div>
					<div class="grid_1">
						<div class="col_2">
							<div class="group_9">
								<span class="word_17">新材料</span>
								<div class="colorDiv_9"></div>
								<span class="text_23">生物材料</span>
								<span class="txt_23">能源材料</span>
								<span class="word_18">化工材料</span>
								<span class="text_24">纳米材料</span>
							</div>
							<div class="block_25">
								<img class="largeIcon_14"
									src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/index/syfw9.png" />
								<span class="txt_24">生物材料</span>
								<img class="largeIcon_15"
									src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/index/syfw13.png" />
								<span class="info_35">新型化工材料</span>
							</div>
							<div class="color_8"></div>
							<div class="colorDiv_10"></div>
							<div class="group_10">
								<img class="largeIcon_16"
									src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/index/syfw10.png" />
								<span class="text_25">能源材料</span>
								<img class="largeIcon_17"
									src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/index/syfw14.png" />
								<span class="txt_25">纳米材料</span>
							</div>
						</div>
						<div class="col_3">
							<div class="group_11">
								<span class="word_19">其他</span>
								<div class="colorDiv_11"></div>
								<span class="text_26">热门服务</span>
								<span class="txt_26">科技咨询</span>
								<span class="word_20">创业孵化</span>
								<span class="text_27">资质认证</span>
							</div>
							<div class="group_12">
								<img class="largeIcon_18"
									src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/index/syfw11.png" />
								<span class="txt_27">网站建设</span>
								<img class="largeIcon_19"
									src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/index/syfw15.png" />
								<span class="word_21">技术转移</span>
							</div>
							<div class="color_9"></div>
							<div class="colorDiv_12"></div>
							<div class="group_13">
								<img class="largeIcon_20"
									src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/index/syfw12.png" />
								<span class="txt_28">项目申报</span>
								<img class="largeIcon_21"
									src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/index/syfw16.png" />
								<span class="word_22">知识产权</span>
							</div>
						</div>
					</div>
				</div>

				<div class="normal_div_89">
					<div class="normal_div_90" v-if="news.list.length > 0">
						<div class="flex width100 box-title space-between">
							<div class="block_46">
								<div class="color_11"></div>
								<span class="text_49">新闻中心</span>
							</div>
							<router-link :to="{path:'/news'}" class="more">
								更多 <i class="el-icon-arrow-right"></i>
							</router-link>
						</div>
						
						<ul>
							<template v-for="(v, news_index) in news.list">
								<li class="summary_3" :key="news_index">
									<router-link class="flex space-between"
										:to="{ path: '/news/detail', query: { id: v.id } }">
										<div class="title">{{ v.title }}</div>
										<div class="time">{{ v.created_at }}</div>
									</router-link>
								</li>
							</template>
						</ul>
					</div>
					<div class="normal_div_91">
						<div class="flex box-title" >
							<div class="block_49">
								<div class="colorDiv_16"></div>
								<span class="text_50">精彩活动</span>
							</div>
							<router-link :to="{path:'/activity'}" class="more">
								更多 <i class="el-icon-arrow-right"></i>
							</router-link>
						</div>
						
						<div class="block_50">
							<div class="group_32"
								@click="herf({ path: '/activity/detail', query: { id: activity.list[0].id } })"
								v-if="activity.list.length > 0">
								<el-image class="actionBg" fit="cover" :src="activity.list[0].cover[0]"></el-image>

								<span class="desc_22">{{ activity.list[0].title }}</span>
								<span
									class="addressPrev">会议时间：{{ activity.list[0].time_start }}-{{ activity.list[0].time_end }}</span>
								<span
									class="address_9">会议地点：{{ activity.list[0].province }}{{ activity.list[0].city }}</span>
								<span class="shopTitle" v-html="activity.list[0].describe"></span>
								<div class="flex space-between ">
									<div class="col1">
										<i class="el-icon-date"></i>
										{{ activity.list[0].created_at }}
									</div>
									<div class="col1">
										<i class="el-icon-location"></i>
										{{ activity.list[0].province }}{{ activity.list[0].city }}
									</div>
								</div>
							</div>
							<div class="list" v-if="activity.list.length > 1">
								<template v-for="(v, k) in activity.list">
									<div class="row" @click="herf({ path: '/activity/detail', query: { id: v.id } })"
										v-if="k > 0 && k < 3" :key="k">
										<el-image class="buttonBg" style="width: 266px; height: 99px" :src="v.cover[0]"
											fit="cover"></el-image>
										<span class="desc_23">{{ v.title }}</span>
									</div>
								</template>
							</div>
						</div>
					
					</div>
				</div>
				
			</div>

			<FirmEnter :title="title" :drawer="drawer" :direction="direction"></FirmEnter>
		</div>
		<flexRight></flexRight>
	</div>
</template>
<script>
	import Top from '../../components/top.vue';
	import FirmEnter from '@/components/firmEnter.vue';
	import flexRight from "@/components/flexRight.vue"
	import {
		mapGetters
	} from 'vuex';
	export default {
		name:'IndexIndex',
		metaInfo: {
			title: '科技牛—为企业精准匹配科研专家以及提供技术解决方案的服务平台',
			meta: [{
					name: 'keywords',
					content: '技术对接,技术交易,技术对接平台,技术创新服务,技术转移,成果转化,技术推广,解决方案'
				},
				{
					name: 'description',
					content: '科技牛是一家专业为企业提供四技服务:技术开发、技术转让、技术咨询、技术服务，精准匹配科研专家及技术解决方案平台公司'
				}
			]
		},
		components: {
			Top,
			flexRight,
			FirmEnter
		},
		data() {
			return {
				drawer: false,
				direction: 'btt',
				title: '',
				constants: {},
				userInfo: {},
				company: {
					page: 1,
					limit: 6,
					list: [],
					reload: false
				},
				expert: {
					page: 1,
					limit: 6,
					list: [],
					reload: false
				},
				news: {
					page: 1,
					limit: 8,
					list: [],
					reload: false
				},
				cg: {
					page: 1,
					limit: 8,
					list: [],
					industry: ''
				},
				banner: {
					page: 1,
					limit: 6,
					list: [],
					reload: false
				},
				expert_cate: {
					page: 1,
					limit: 4,
					list: [],
					reload: false
				},
				demand_cate: [],
				city_value: '',
				industry_cate: [],
				cg_cate: [],
				activity: {
					list: []
				},
				cate_arr: []
			};
		},
		computed: {
			...mapGetters(['user', 'token', 'city', 'province'])
		},
		mounted() {
			let submenu1 = [{
					name: '建筑建材'
				},
				{
					name: '冶金矿产'
				},
				{
					name: '石油化工'
				},
				{
					name: '水利水电'
				},
				{
					name: '交通运输'
				},
				{
					name: '信息产业'
				},
				{
					name: '机械机电'
				},
				{
					name: '轻工食品'
				},
				{
					name: '服装纺织'
				},
				{
					name: '专业服务'
				},
				{
					name: '安全防护'
				},
				{
					name: '环保绿化'
				},
				{
					name: '电子电工'
				}
			];
			this.industry_cate = [{
				name: '自动化',
				submenu: submenu1
			}];

			this.get_localStorage();
			this.get_cate();
			this.get_banner();
			this.get_demand_cate();
			this.get_company();
			this.get_expert_cate();
			this.get_expert();
			this.get_cg_cate();
			this.get_cg();
			this.get_news();
			this.get_active();
		},

		methods: {
			get_cg_cate() {
				this.$http_qm
					.post('achievement/cate', {
						type: 'industrial'
					})
					.then(res => {
						let industry = res.data.industrial;
						industry.unshift('不限')
						var cg_cate = this.cg_cate;
						industry.forEach(function(v, k) {
							if (k == 0) {
								cg_cate.push({
									name: v,
									active: true
								});
							} else {
								cg_cate.push({
									name: v,
									active: false
								});
							}
						});
					})
					.catch(err => {
						console.log(err);
					});
			},
			get_cate() {

				this.$http_qm
					.post('product_cate/list', {})
					.then(res => {
						this.cate_arr = res.data;
					})
					.catch(err => {
						console.log(err);
					});
			},
			web_url(url) {
				window.open(url, '_blank');
			},
			href(url) {
				if (url != '') {
					if (url.path.includes('http')) {
						window.open(url.path, '_blank');
					} else {
						var router = this.$router.resolve(url);
						window.open(router.href, '_blank');
					}

				}
			},
			herf(url) {
				this.$router.push(url);
			},
			get_active() {
				this.$http_qm
					.post('activity/list', {
						page: 1,
						status: 1,
						limit: 3
					})
					.then(res => {
						if (res.code == 200) {
							this.activity.list = res.data;
						}
					})
					.catch(err => {
						console.log(err);
					});
			},
			change_cg(name, k) {
				this.cg_cate.forEach(function(v, k1) {
					if (k1 == k) {
						v.active = true;
					} else {
						v.active = false;
					}
				});
				if (name == '不限') {
					this.cg.industry = '';
				} else {
					this.cg.industry = name;
				}
				this.get_cg();
			},
			get_localStorage() {
				this.city_value = localStorage.getItem('city');
			},

			async get_demand_cate() {
				await this.$http_qm
					.post('demand/index_list', {
						limit: 3,
						page: 1
					})
					.then(res => {
						this.demand_cate = res.data;
					})
					.catch(err => {
						console.log(err);
					});
			},
			async get_expert_cate() {
				let search_data = {
					page: this.expert_cate.page,
					limit: this.expert_cate.limit
				};
				await this.$http
					.post('expert/cate', search_data)
					.then(res => {
						if (this.reload) {
							this.expert_cate.list = res.data;
						} else {
							this.expert_cate.list = this.expert_cate.list.concat(res.data);
						}
						this.expert_cate.reload = false;
						if (res.data.length == search_data.limit) {
							search_data.page++;
						} else {
							this.expert_cate.loading = false;
						}
					})
					.catch(err => {
						console.log(err);
					});
			},
			async get_banner() {


				let search_data = {
					page: this.banner.page,
					limit: this.banner.limit,
					type: 2,
				};
				const city = localStorage.getItem('city')
				if (city) {
					search_data.city = city
				}
				const province = localStorage.getItem('province')
				if (province) {
					search_data.province = province
				}
				await this.$http
					.post('banner/list', search_data)
					.then(res => {
						this.banner.list = res.data;
					})
					.catch(err => {
						console.log(err);
					});
			},
			async get_cg() {
				let search_data = {
					page: this.cg.page,
					limit: this.cg.limit,
					industrial: this.cg.industry
				};
				await this.$http
					.post('achievement/list', search_data)
					.then(res => {
						this.cg.list = res.data;
					})
					.catch(err => {
						console.log(err);
					});
			},
			async get_news() {
				let search_data = {
					page: this.news.page,
					limit: this.news.limit,
					request_type: 'new'
				};
				this.$http_qm
					.post('news/list', search_data)
					.then(res => {
						if (this.reload) {
							this.news.list = res.data;
						} else {
							this.news.list = this.news.list.concat(res.data);
						}
						this.news.reload = false;
						if (res.data.length == search_data.limit) {
							search_data.page++;
						} else {
							this.news.loading = false;
						}
					})
					.catch(err => {
						console.log(err);
					});
			},
			async get_company() {
				let search_data = {
					page: this.company.page,
					limit: this.company.limit,
					status: 1,
				};
				await this.$http_qm
					.post('company/list', search_data)
					.then(res => {
						if (this.reload) {
							this.company.list = res.data;
						} else {
							this.company.list = this.company.list.concat(res.data);
						}
						this.company.reload = false;
						if (res.data.length == search_data.limit) {
							search_data.page++;
						} else {
							this.company.loading = false;
						}
					})
					.catch(err => {
						console.log(err);
					});
			},
			async get_expert() {
				let search_data = {
					page: this.expert.page,
					limit: this.expert.limit
				};
				await this.$http
					.post('expert/search', search_data)
					.then(res => {
						if (this.reload) {
							this.expert.list = res.data;
						} else {
							this.expert.list = this.expert.list.concat(res.data);
						}
						this.expert.reload = false;
						if (res.data.length == search_data.limit) {
							search_data.page++;
						} else {
							this.expert.loading = false;
						}
					})
					.catch(err => {
						console.log(err);
					});
			},

			cg_item(id) {
				this.$router.push({
					path: '/achivement/detail',
					query: {
						id: id
					}
				});
			},
			toUrl(url) {
				this.$router.push({
					path: url
				});
			}
		}
	};
</script>
<style lang="less" src="../../assets/css/common.less" />
<style lang="less" src="../../assets/css/index.response.less" />
