<template>
	<div id="move">
		<div class="content">
			<img src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/soar/move-icon-01.gif" />
			<div class="content-from" id="content-from">
				<div class="counter">
					<div class="msg svelte-soflc2">
						<img src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/soar/voide.png" />
						<span class="prefix">截止目前已有</span>
						<span class="number svelte-soflc2">1746</span>
						<span class="suffix">家企业获取准入资格</span>
					</div>
				</div>
				<el-form label-position="top" :rules="rules" ref="ruleForm" :model="ruleForm" label-width="80px">
					<el-form-item label="姓名" prop="name">
						<el-input v-model="ruleForm.name"></el-input>
					</el-form-item>
					<el-form-item label="电话" prop="tel">
						<el-input v-model="ruleForm.tel"></el-input>
					</el-form-item>
					<el-form-item label="公司名称" prop="company_name">
						<el-input v-model="ruleForm.company_name"></el-input>
					</el-form-item>
					<el-form-item label="您的行业分类(单选)" prop="cate">
						<el-radio-group v-model="ruleForm.cate">
							<el-col :span="12">
								<el-radio label="电子信息技术"></el-radio>
							</el-col>
							<el-col :span="12">
								<el-radio label="互联网"></el-radio>
							</el-col>
							<el-col :span="12">
								<el-radio label="生物与医药"></el-radio>
							</el-col>
							<el-col :span="12">
								<el-radio label="新材料"></el-radio>
							</el-col>
							<el-col :span="12">
								<el-radio label="新能源与节能环保"></el-radio>
							</el-col>
							<el-col :span="12">
								<el-radio label="高技术服务业"></el-radio>
							</el-col>
						</el-radio-group>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="onSubmit('ruleForm')">免费领取精准评估方案</el-button>
					</el-form-item>
				</el-form>
				<div class="jskom">
					<img src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/soar/hot.jpg">
					<div class="inner-container">
						<p class="text" v-for="(text, index) in arr" :key="index">{{ text }}</p>
					</div>
				</div>
			</div>
			<img src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/soar/move-icon-02.gif" />
			<img src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/soar/move-icon-03.gif" />
			<img src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/soar/move-icon-04.gif" />
			<img src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/soar/move-icon-05.gif" />
		</div>
		<div class="brick-multiline">
			<ul>
				<li class="multiline-phone" @click="callPhone">
					<span>
						<i class="el-icon-phone-outline"></i>
					</span>
					<span class="btn-text">咨询电话</span>
				</li>
				<li class="multiline-form" @click="bot_submit('ruleForm')">
					<span>
						<i class="el-icon-document"></i>
					</span>
					<span class="btn-text">免费获取精准评估方案</span>
				</li>
			</ul>
		</div>
		<el-dialog :visible.sync="centerDialogVisible" width="80%" center>
			<div style="width: 100%;text-align: center;"> <img width="80" height="80"
					src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/other/move.jpg">
				<h2 style="margin-top: 10px;">表单提交成功</h2>
			</div>

			<span slot="footer" class="dialog-footer">
				<el-button style="width: 240px;" type="primary" @click="close">关 闭</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
export default {
	name:'SoarMove',
	metaInfo: {
		title: '高新技术企业认定，税收最高减免80%，最高可获得200万补助',
		meta: [{
			name: 'keywords',
			content: '技术对接,技术交易,技术对接平台,技术创新服务,技术转移,成果转化,技术推广,解决方案'
		},
		{
			name: 'description',
			content: '科技牛是一家专业为企业提供四技服务:技术开发、技术转让、技术咨询、技术服务，精准匹配科研专家及技术解决方案平台公司'
		}
		]
	},
	data() {
		return {
			ruleForm: {
				name: '',
				tel: '',
				company_name: '',
				cate: '',
			},
			rules: {
				name: [{
					required: true,
					message: '请输入姓名',
					trigger: 'blur'
				},],
				tel: [{
					required: true,
					message: '请输入电话',
					trigger: 'blur'
				},],
				company_name: [{
					required: true,
					message: '请输入公司名称',
					trigger: 'blur'
				},],
				cate: [{
					required: true,
					message: '请选择行业分类',
					trigger: 'change'
				},],
			},
			arr: ['楼**  189****2499  5天前',
				'张**  183****4090  27分钟前',
				'杨**  137****9996  3小时前',
				'张**  137****9240  1天前',
				'马**  157****9540  2天前',
				'肖**  132****9249  5天前',
				'陈**  137****9120  5天前'],
			number: 0,

			centerDialogVisible: false
		}
	},
	computed: {
		text() {
			return {
				id: this.number,
				val: this.arr[this.number]
			}
		}
	},
	mounted() {
		var bot = document.querySelector('.bottom_box');
		bot.style.display = 'none'
		var top = document.querySelector('.top');
		top.style.display = 'none'
		this.startMove()
	},
	methods: {
		onSubmit(ruleForm) {
			this.$refs[ruleForm].validate((valid) => {
				if (valid) {
					let from_ = this.ruleForm;
					this.$http_qm.post('/form/add', {
						info: from_,
						type: 1
					}).then(res => {
						console.log(res)
						this.centerDialogVisible = true
					}).catch(err => {
						console.log(err)
					})
				} else {
					console.log('error!');
					return false;
				}
			});
		},
		startMove() {
			setTimeout(function () {
				if (this.number === 0) {
					this.number = 0;
				} else {
					this.number += 1;
				}
			}, 5000)
		},
		callPhone() {
			window.location.href = 'tel://18721259685'
		},
		bot_submit(ruleForm) {
			document.querySelector('#content-from').scrollIntoView(true);
			this.onSubmit(ruleForm);
		},
		close() {
			this.centerDialogVisible = false;
			this.$forceUpdate()
		}
	}
}
</script>

<style lang="less">
#move {
	padding-bottom: 40px;

	.content {
		img {
			display: block;
			width: 100%;
			height: 100%;
		}

		.el-dialog__body {
			text-align: center !important;
			display: flex;
			justify-content: center;
		}

		.content-from {
			padding: 0 30px;

			.el-button {
				width: 100%;
			}

			.el-col-12 {
				margin: 10px 0;
			}

			.jskom {
				width: 100%;
				height: 50px;
				background: #fff;
				box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.15);
				border-radius: 25px;
				color: #333;
				padding: 0 30px;
				font-size: 14px;
				display: flex;
				box-sizing: border-box;
				align-items: center;
				flex-direction: row;
				overflow: hidden;

				img {
					width: 30px;
					height: 30px;
					margin-right: 8px;
				}

				.inner-container {
					animation: myMove 8s linear infinite;
					animation-fill-mode: forwards;
				}

				@keyframes myMove {
					0% {
						transform: translateY(0);
					}

					10% {
						transform: translateY(-50px);
					}

					20% {
						transform: translateY(-50px);
					}

					30% {
						transform: translateY(-100px);
					}

					40% {
						transform: translateY(-100px);
					}

					50% {
						transform: translateY(-150px);
					}

					60% {
						transform: translateY(-150px);
					}

					70% {
						transform: translateY(-200px);
					}

					80% {
						transform: translateY(-200px);
					}

					90% {
						transform: translateY(-250px);
					}

					100% {
						transform: translateY(-250px);
					}
				}

				.text {
					display: block;
					height: 50px;
					line-height: 50px;
				}

				.slide-enter-active {
					transition: all 1.5s linear;
				}

				.slide-leave-active {
					transition: all 1.5s linear;
				}

				.slide-leave-to {
					transform: translateY(-50px);
				}

				.slide-enter {
					transform: translateY(50px);
				}

			}

			.counter {
				margin-bottom: 10px;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-wrap: nowrap;
				width: 100%;
				height: 50px;
				overflow: hidden;
				background: #FFFFFF;
				box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.15);
				border-radius: 25px;
				color: #333;
				font-size: 14px;

				.number {
					font-weight: bold;
					font-size: 17px;
					color: #D0021B;
				}

				.msg {
					display: flex;
					flex-direction: row;
					align-items: center;
				}

				img {
					width: 30px;
					height: 30px;
					margin-right: 8px;
				}
			}
		}
	}

	.brick-multiline {
		height: 50px;
		width: 100%;
		color: #fff;
		position: fixed;
		bottom: 0;

		ul {
			height: 50px;
			display: flex;
			flex-direction: row;
			align-items: center;
			list-style: none;
			overflow: hidden;
			cursor: pointer;

			.multiline-phone {
				color: #666;
				width: 25%;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				background: #fff;
				height: 100%;

				i {
					font-size: 20px;
				}
			}

			.multiline-form {
				width: 75%;
				background: rgba(78, 144, 255, 1);
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: center;
				height: 100%;

				.btn-text {
					font-size: 18px;
				}

				i {
					font-size: 18px;
					margin-right: 10px;
				}
			}
		}
	}
}
</style>
