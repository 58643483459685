<template>
	<div class="data_index">
		<div class="wrappage">
			<div class="page-border pgB1"></div>
			<div class="page-border pgB2"></div>
			<div class="page-border pgB3"></div>
			<div class="page-border pgB4"></div>
			<div style="padding-top: 45px;padding-left: 137px;">
				<div
					style="width: 15px;height: 15px;background: #06DFF9;opacity: 0.25;border-radius: 50%;display: inline-block;">
				</div>
				<div
					style="width: 8px;height: 8px;background: #06DFF9;opacity: 0.35;border-radius: 50%;margin-left: -14.4px;display: inline-block;margin-bottom: 3px;">
				</div>
				<div
					style="width: 2px;height: 2px;background: #06DFF9;border-radius: 50%;margin-left: -9px;display: inline-block;margin-bottom: 6px;">
				</div>
				<div
					style="font-size: 20px;font-family: Alibaba PuHuiTi;font-weight: 400;color: #FFFFFF;display: inline-block;margin-left: 10px;">
					科技牛全国服务中心</div>
			</div>
			<div class="pageTitle">
				<div
					style="-webkit-box-reflect: below -8px -webkit-gradient(linear,left top,left bottom, from(rgba(0,0,0,0)),to(rgba(255,255,255,0.1)));">
					科技牛大数据平台(对接平台)</div>
				<div style="width: 726px;height: 3px;background: #00FFFF;margin-top:23px;"></div>
			</div>
			<div style="float: right;display: inline-block;margin-right: 167px;font-size: 20px;font-family: Alibaba PuHuiTi;font-weight: 400;color: #FFFFFF;margin-top: -20px;"
				id="curTime">{{ time }}</div>
			<div class="pageContent" style="margin-left:20px;">
				<div class="mapBox">
					<div class="map-border mapB1"></div>
					<div class="map-border mapB2"></div>
					<div class="map-border mapB3"></div>
					<div class="map-border mapB4"></div>
					<div id="mapDiv">
						<!-- 上海 -->
						<div class="addr" style="margin-top:208px;margin-left: 445px;position: absolute;">
							<div class="live">
								<!-- <img src="../../assets/img/data/icon-blue.png" alt=""> -->
								<!-- <span></span> -->
								<!-- <span></span> -->

							</div>
							<p class="title"> 上海一实网络科技有限公司 </p>
						</div>
						<div class="addr" style="margin-top:200px;margin-left: 405px;position: absolute;">
							<div class="live">
								<!-- <img src="../../assets/img/data/icon-blue.png" alt="">
								<span></span>
								<span></span> -->

							</div>
							<p class="title"> 合肥一实科技服务有限公司 </p>
						</div>
						<div class="addr" style="margin-top:170px;margin-left: 380px;position: absolute;">
							<div class="live">
								<!-- <img src="../../assets/img/data/icon-blue.png" alt="">
								<span></span>
								<span></span> -->

							</div>
							<p class="title"> 新乡一实科技服务有限公司 </p>
						</div>
						<div class="addr" style="margin-top:228px;margin-left: 443px;position: absolute;">
							<div class="live">
								<!-- <img src="../../assets/img/data/icon-blue.png" alt="">
								<span></span>
								<span></span> -->

							</div>
							<p class="title"> 台州一实科技服务有限公司 </p>
						</div>
						<div class="addr" style="margin-top:155px;margin-left: 440px;position: absolute;">
							<div class="live">
								<!-- <img src="../../assets/img/data/icon-blue.png" alt="">
								<span></span>
								<span></span> -->

							</div>
							<p class="title"> 青岛一实合一信息科技有限公司 </p>
						</div>
					</div>
					<div class="mapCont"
						style="height:118px;    margin-top: -40px;   background-size: 100%;  padding-top: 55px;  padding-left: 64px;">
						<p style="text-align: center;"> 企业技术的研发中心 &nbsp; 科研成果的转化中心 </p>
					</div>
				</div>
				<div class="chartWrap">
					<div class="map-border mapB1"></div>
					<div class="map-border mapB2"></div>
					<div class="map-border mapB3"></div>
					<div class="map-border mapB4"></div>
					<div class="">
						<div class="chartBox" style="width:350px;">
							<div class="chartTitle">
								<i class="icon-chart"></i>
								<span
									style="-webkit-box-reflect: below -5px -webkit-gradient(linear,left top,left bottom, from(rgba(0,0,0,0)),to(rgba(255,255,255,0.1)));">全国入库成果分类</span>
								<i class="icon-chart right"></i>
							</div>
							<div class="pieChart" id="cg1">

							</div>
						</div>
						<div class="chartBox" style="margin-top:36px;">
							<div class="chartTitle">
								<i class="icon-chart"></i>
								<span
									style="-webkit-box-reflect: below -5px -webkit-gradient(linear,left top,left bottom, from(rgba(0,0,0,0)),to(rgba(255,255,255,0.1)));">海外成果入库热点</span>
								<i class="icon-chart right"></i>
							</div>
							<div class="radarChart" id="cg2">

							</div>
						</div>
					</div>
				</div>
				<div class="chartWrap">
					<div class="map-border mapB1"></div>
					<div class="map-border mapB2"></div>
					<div class="map-border mapB3"></div>
					<div class="map-border mapB4"></div>
					<div>
						<div class="chartBox lineBox">
							<div class="chartTitle">
								<i class="icon-chart"></i>
								<span
									style="-webkit-box-reflect: below -5px -webkit-gradient(linear,left top,left bottom, from(rgba(0,0,0,0)),to(rgba(255,255,255,0.1)));">全国技术交易指数</span>
								<i class="icon-chart right"></i>
							</div>
							<div class="lineChart" id="js1">

							</div>
						</div>
						<div class="chartBox barBox" style="margin-top:50px;width:360px;">
							<div class="chartTitle">
								<i class="icon-chart"></i>
								<span
									style="-webkit-box-reflect: below -5px -webkit-gradient(linear,left top,left bottom, from(rgba(0,0,0,0)),to(rgba(255,255,255,0.1)));padding:0 14px;">2020年上海技术合同交易情况</span>
								<i class="icon-chart right"></i>
							</div>
							<div class="barChart" id="js2">

							</div>
						</div>
					</div>
				</div>
				<div class="chartWrap">
					<div class="map-border mapB1"></div>
					<div class="map-border mapB2"></div>
					<div class="map-border mapB3"></div>
					<div class="map-border mapB4"></div>
					<div>
						<div class="chartWrap" style="    width: 221px;
								height: 217px;
								margin-left: 0px;
								box-shadow: none;">
							<div class="map-border mapB3" style="margin-left:-20px;"></div>
							<div class="map-border mapB4" style="margin-right:-20px;"></div>
							<div>
								<div class="dataInfo" style="margin-top:50px;">
									<div class="dataTitle">
										<i class="icon-chart right" style="margin-left:-20px;"></i>
										<span>平台专家</span>
										<i class="icon-chart " style="margin-right:-20px;"></i>
									</div>
									<div class="dataNum"><em>{{ expert_count }}</em>位</div>
								</div>
							</div>
						</div>
						<div class="dataInfo" style="margin-top:50px;">
							<div class="dataTitle">
								<i class="icon-chart right" style="margin-left:-22px;"></i>
								<span>企业联盟会员</span>
								<i class="icon-chart" style="margin-right:-22px;"></i>
							</div>
							<div class="dataNum"><em>{{ company_count }}</em>位</div>
						</div>
						<div class="chartWrap" style="width: 221px;
								height: 217px;
								margin-left: 0px;
								box-shadow: none;">
							<div class="map-border mapB1" style="margin-left:-20px;"></div>
							<div class="map-border mapB2" style="margin-right:-20px;"></div>
							<div>
								<div class="dataInfo" style="margin-top:50px;cursor:pointer;" onclick="chengguo()">
									<div class="dataTitle">
										<i class="icon-chart right" style="margin-left:-20px;"></i>
										<span>入库成果</span>
										<i class="icon-chart " style="margin-right:-20px;"></i>
									</div>
									<div class="dataNum"><em>{{ achievement_count }}</em>项</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="pageList" style="margin-left:20px;">
				<div class="map-border mapB1"></div>
				<div class="map-border mapB2"></div>
				<div class="map-border mapB3"></div>
				<div class="map-border mapB4"></div>
				<div class="listData">
					<div class="list-title">
						<i class="icon-title icon1"></i>
						<span>技术需求</span>
					</div>
					<div class="dataBox">
						<div style="width: 314px;height: 145px;overflow: hidden;" id="jsxqdiv">
							<ul id="jsxq" ref="scroll1" :style="pos1">
								<li v-for="(v, k) in demand_list" :key="k">{{ v.title }}</li>
							</ul>

						</div>
					</div>
				</div>
				<div class="listData">
					<div class="list-title">
						<i class="icon-title icon2"></i>
						<span>全国入库成果</span>
					</div>
					<div class="dataBox">
						<div style="width: 314px;height: 145px;overflow: hidden;" id="rkcgdiv">
							<ul id="rkcg" ref="scroll2" :style="pos2">
								<li v-for="(v, k) in achievement_list" :key="k">{{ v.title }} </li>
							</ul>

						</div>
					</div>
				</div>
				<div class="listData">
					<div class="list-title">
						<i class="icon-title icon3"></i>
						<span>产学研案例</span>
					</div>
					<div class="dataBox">
						<div style="width: 314px;height: 145px;overflow: hidden;" id="kjcgdiv">
							<ul id="kjcg" ref="scroll3" :style="pos3">
								<li v-for="(v, k) in case_list" :key="k">{{ v.title }}</li>
							</ul>

						</div>
					</div>
				</div>
				<div class="listData">
					<div class="list-title">
						<i class="icon-title icon4"></i>
						<span>海外入库成果</span>
					</div>
					<div class="dataBox">
						<div style="width: 314px;height: 145px;overflow: hidden;" id="qgzddiv">
							<ul id="qgzd" ref="scroll4" :style="pos4">
								<li v-for="(v, k) in achievement2_list" :key="k">{{ v.title }}</li>
							</ul>

						</div>
					</div>
				</div>
				<div class="listData">
					<div class="list-title">
						<i class="icon-title icon5"></i>
						<span>企业联盟</span>
					</div>
					<div class="dataBox">
						<div style="width: 314px;height: 145px;overflow: hidden;" id="qgjsdiv">
							<ul id="qgjs" ref="scroll5" :style="pos5">
								<li v-for="(v, k) in company_list" :key="k">{{ v.name }}</li>
							</ul>

						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import * as echarts from 'echarts';
export default {
	name: 'DataIndex',
	data() {
		return {
			height1: 0,
			height2: 0,
			height3: 0,
			height4: 0,
			height5: 0,
			yPos1: 0,
			yPos2: 0,
			yPos3: 0,
			yPos4: 0,
			yPos5: 0,
			demand_list: [],
			achievement_list: [],
			achievement2_list: [],
			company_list: [],
			case_list: [],
			time: '',
			expert_count: 0,
			achievement_count: 0,
			company_count: 0,
		}
	},
	computed: {
		pos1() {
			return {
				transform: `translateY(${this.yPos1}px`
			}; // 计算属性改变style
		},
		pos2() {
			return {
				transform: `translateY(${this.yPos2}px`
			}; // 计算属性改变style
		},
		pos3() {
			return {
				transform: `translateY(${this.yPos3}px`
			}; // 计算属性改变style
		},
		pos4() {
			return {
				transform: `translateY(${this.yPos4}px`
			}; // 计算属性改变style
		},
		pos5() {
			return {
				transform: `translateY(${this.yPos5}px`
			}; // 计算属性改变style
		}
	},
	mounted() {
		//获取当前时间
		var myDate = new Date();
		var month = myDate.getMonth() + 1;
		this.time = myDate.getFullYear() + '年' + month + '月' + myDate.getDate() + '日';

		//入库专家数：
		this.get_expert_count();
		//入库成果
		this.get_achievement_count();
		//获取入驻企业数
		this.get_company_count();
		this.get_demand_list();
		this.get_achievement_list();
		this.get_achievement2_list();
		this.get_company_list();
		this.get_case_list();
		this.initScroll1()
		this.set_cg1()
		this.set_cg2()
		this.set_sj1()
		this.set_js2()
	},
	methods: {
		get_case_list() {
			this.$http_qm.post('case/list', {
				page: 1,
				limit: 20,
				status: 1
			}).then(res => {
				if (res.code == 200) {
					this.case_list = res.data;
				}
			}).catch(err => {
				console.log(err);
			})
		},
		get_company_count() {
			this.$http_qm.post('company/count', {}).then(res => {
				if (res.code == 200) {
					this.company_count = res.data;
				}
			}).catch(err => {
				console.log(err);
			})
		},
		get_achievement_count() {
			this.$http_qm.post('achievement/count', {}).then(res => {
				this.achievement_count = res.data;
			}).catch(err => {
				console.log(err);
			})
		},
		get_expert_count() {
			this.$http_qm.post('expert/count', {}).then(res => {
				this.expert_count = res.data;
			}).catch(err => {
				console.log(err);
			})
		},
		get_company_list() {
			this.$http_qm.post('company/list', {
				page: 1,
				limit: 20,
				status: 1
			}).then(res => {
				this.company_list = res.data;
			}).catch(err => {
				console.log(err);
			})
		},
		get_achievement2_list() {
			this.$http_qm.post('achievement2/list', {
				page: 1,
				limit: 10,
				status: 1
			}).then(res => {
				this.achievement2_list = res.data;
			}).catch(err => {
				console.log(err);
			})
		},
		get_achievement_list() {
			this.$http_qm.post('achievement/list', {
				page: 1,
				limit: 10,
				status: 1
			}).then(res => {
				this.achievement_list = res.data;
			}).catch(err => {
				console.log(err);
			})
		},
		async get_demand_list() {
			await this.$http_qm.post('demand/list', {
				page: 1,
				limit: 10,
				status: 1
			}).then(res => {
				this.demand_list = res.data;
			}).catch(err => {
				console.log(err);
			})
		},
		initScroll1() {
			this.$nextTick(() => {

				setInterval(() => {
					if (this.$refs.scroll1&&this.$refs.scroll1.offsetHeight) {
						this.height1 = this.$refs.scroll1.offsetHeight;
						if (this.demand_list.length > 4 && this.yPos1 > -this.height1 / 2) {
							this.yPos1 = this.yPos1 - 1;
						} else {
							this.yPos1 = 0;
						}
					}
				}, 200);

				setInterval(() => {
					if (this.$refs.scroll2&&this.$refs.scroll2.offsetHeight) {
						this.height2 = this.$refs.scroll2.offsetHeight;
						if (this.yPos2 > -this.height2 / 2) {
							this.yPos2 = this.yPos2 - 1;
						} else {
							this.yPos2 = 0;
						}
					}
				}, 200);

				setInterval(() => {
					if (this.$refs.scroll3&&this.$refs.scroll3.offsetHeight) {
						this.height3 = this.$refs.scroll3.offsetHeight;
						if (this.yPos3 > -this.height3 / 2) {
							this.yPos3 = this.yPos3 - 1;
						} else {
							this.yPos3 = 0;
						}
					}
				}, 200);


				setInterval(() => {
					if (this.$refs.scroll4&&this.$refs.scroll4.offsetHeight) {
						this.height4 = this.$refs.scroll4.offsetHeight;
						if (this.yPos4 > -this.height4 / 2) {
							this.yPos4 = this.yPos4 - 1;
						} else {
							this.yPos4 = 0;
						}
					}
				}, 200);

				setInterval(() => {
					if (this.$refs.scroll5&&this.$refs.scroll5.offsetHeight) {
						this.height5 = this.$refs.scroll5.offsetHeight;
						if (this.yPos5 > -this.height5 / 2) {
							this.yPos5 = this.yPos5 - 1;
						} else {
							this.yPos5 = 0;
						}
					}
				}, 200);
			});


		},
		set_js2() {
			var chartDom = document.getElementById('js2');
			var myChart = echarts.init(chartDom);
			var option;

			option = {
				tooltip: {
					show: true
				},
				xAxis: {
					type: 'category',
					data: ['一季度', '二季度', '三季度', '四季度']
				},
				yAxis: {
					type: 'value'
				},
				series: [{
					data: [120, 200, 150, 80],
					type: 'bar',
					showBackground: true,
					backgroundStyle: {
						color: 'rgba(180, 180, 180, 0.2)'
					}
				}]
			};

			option && myChart.setOption(option);
		},
		set_sj1() {
			var chartDom = document.getElementById('js1');
			var myChart = echarts.init(chartDom);
			var option;

			option = {

				tooltip: {
					trigger: 'axis'
				},
				legend: {
					data: ['Email', 'Union Ads', 'Video Ads', 'Direct', 'Search Engine']
				},
				grid: {
					left: '3%',
					right: '4%',
					bottom: '3%',
					containLabel: true
				},
				toolbox: {
					feature: {
						saveAsImage: {}
					}
				},
				xAxis: {
					type: 'category',
					boundaryGap: false,
					data: ['1月', '2月', '3月', '4月', '5月', '6月']
				},
				yAxis: {
					type: 'value'
				},
				series: [{
					name: '技术合同交易数量',
					type: 'line',
					stack: 'Total',
					data: [120, 132, 101, 134, 90, 230]
				},
				{
					name: '技术合同交易额',
					type: 'line',
					stack: 'Total',
					data: [220, 182, 191, 234, 290, 330]
				},


				]
			};

			option && myChart.setOption(option);

		},
		set_cg2() {
			var chartDom = document.getElementById('cg2');
			var myChart = echarts.init(chartDom);
			var option;
			this.$http_qm.post('achievement2/analyse', {
				request_type: 1,
			}).then(res => {
				option = res.data.data1;
				option && myChart.setOption(option);
			}).catch(err => {
				console.log(err);
			})
		},
		set_cg1() {
			var chartDom = document.getElementById('cg1');
			var myChart = echarts.init(chartDom);
			var option;
			this.$http_qm.post('achievement/analyse', {
				request_type: 1,
			}).then(res => {
				option = res.data.data1;
				option && myChart.setOption(option);
			}).catch(err => {
				console.log(err);
			})


		}
	},

}
</script>
<style type="text/css">
.addr .title {
	display: none;
	color: #FFFFFF;
	z-index: 99;

}

.addr:hover .title {
	display: block;
}
</style>
<style lang="less">
.data_index {
	font: 14px/1.231 'AlibabaPuHuiTiR', 'Acens';
	width: 1919px;
	background: url(../../assets/img/data/bg.png) no-repeat top left;
	background-size: cover;

	* {
		margin: 0px;
		padding: 0px;
		box-sizing: border-box;
		-moz-box-sizing: border-box;
		-webkit-box-sizing: border-box;
	}

	#mapDiv {
		background: url(../../assets/img/data/map.png) no-repeat top left;
		background-size: contain;
		height: 492px;

	}

	a {
		text-decoration: none;
		outline: none;
		cursor: pointer;
	}

	a:hover {
		text-decoration: none;
		outline: none;
	}

	ul,
	ul li {
		list-style: none;
	}

	li {
		list-style-position: outside;
	}

	em {
		font-weight: normal;
		font-style: normal;
	}

	img {
		border: none;
	}

	@font-face {
		font-family: 'Acens';
		src: url('../../assets/font/Acens-1.ttf');
	}

	@font-face {
		font-family: 'AlibabaPuHuiTiR';
		src: url('../../assets/font/Alibaba-PuHuiTi-Regular.ttf');
	}

	.wrappage {
		width: 1890px;
		height: 1053px;
		background: url(../../assets/img/data/body-bg-img.png) no-repeat;
		;
		margin: 12px 19px 11px 20px;
		padding: 0 55px 0 23px;
		position: relative;
	}

	.page-border {
		width: 22px;
		height: 22px;
		background: url(../../assets/img/data/sanjiao-img.png) no-repeat;
		position: absolute;
	}

	.page-border.pgB1 {
		top: 10px;
		left: 5px;
	}

	.page-border.pgB2 {
		top: 10px;
		right: 5px;
		transform: rotate(90deg);
		-webkit-transform: rotate(90deg);
	}

	.page-border.pgB3 {
		bottom: 4px;
		left: 4px;
		transform: rotate(-90deg);
		-webkit-transform: rotate(-90deg);
	}

	.page-border.pgB4 {
		bottom: 4px;
		right: 4px;
		;
		transform: rotate(-180deg);
		-webkit-transform: rotate(-180deg);
	}


	.pageTitle {
		color: #FFF;
		font-size: 36px;
		text-align: center;
		position: absolute;
		top: 24px;
		left: 50%;
		transform: translateX(-50%);
		-webkit-transform: translateX(-50%);
	}

	.pageContent {
		padding-top: 55px;
		display: flex;
	}

	.mapBox {
		height: 658px;
		padding: 42px 24px 29px;
		box-shadow: 0px 0px 10px 0px rgba(21, 213, 241, 0.5) inset;
		border-radius: 40px;
		position: relative;
	}

	.mapCont {
		width: 643px;
		background: url(../../assets/img/data/BG.gif) no-repeat;
		padding: 22px 108px 22px 39px;
	}

	.mapCont p {
		font-size: 16px;
		color: #FFF;
		font-weight: 400;
		line-height: 24px;
	}

	.chartWrap {
		width: 406px;
		height: 658px;
		margin-left: 25px;
		padding: 20px;
		box-shadow: 0px 0px 10px 0px rgba(21, 213, 241, 0.5) inset;
		position: relative;
		border-radius: 40px;
	}

	.map-border {
		width: 51px;
		height: 54px;
		position: absolute;
		background: url(../../assets/img/data/map-bg-left-img.png) no-repeat;
	}

	.map-border.mapB1 {
		top: 0;
		left: 0;
	}

	.map-border.mapB2 {
		top: -2px;
		right: 2px;
		transform: rotate(90deg);
		-webkit-transform: rotate(90deg);
	}

	.map-border.mapB3 {
		bottom: -2px;
		left: 1px;
		transform: rotate(-90deg);
		-webkit-transform: rotate(-90deg);
	}

	.map-border.mapB4 {
		bottom: 0;
		right: -1px;
		transform: rotate(180deg);
		-webkit-transform: rotate(180deg);
	}

	.chartTitle {
		color: #FFF;
		font-size: 20px;
		display: flex;
		display: -webkit-flex;
		justify-content: center;
		-webkit-justify-content: center;
	}

	.chartTitle .icon-chart {
		width: 86px;
		height: 21px;
		background: url(../../assets/img/data/icon-chart.png) no-repeat;
	}

	.chartTitle .icon-chart.right {
		transform: scaleX(-1);
	}

	.chartTitle span {
		padding: 0 9px;
	}

	/* 饼图*/
	.pieChart {
		width: 322px;
		height: 186px;
		margin: 30px auto 0;
	}

	/*雷达图*/
	.radarChart {
		width: 340px;
		height: 277px;
		margin: 30px auto 0;
	}

	.chartBox.lineBox .chartTitle .icon-chart {
		width: 49px;
		height: 21px;
		background: url(../../assets/img/data/icon-chart1.png) no-repeat;
	}

	.chartBox.barBox .chartTitle .icon-chart {
		width: 33px;
		height: 23px;
		background: url(../../assets/img/data/icon-chart_small.png) no-repeat;
	}

	/* 线图*/
	.lineChart {
		width: 100%;
		height: 241px;
		margin-top: 10px;
	}

	/* 柱状图*/
	.barChart {
		width: 100%;
		height: 224px;
		margin-top: 20px;
	}

	/*.dataBox{margin-left:20px;} */
	.dataBox .dataInfo {
		width: 226px;
		margin-bottom: 28.2px;
		box-shadow: 0px 0px 10px 0px rgba(21, 213, 241, 0.5) inset;
	}

	.dataBox .dataInfo:last-child {
		margin-bottom: 0;
	}

	.dataTitle {
		color: #FFF;
		padding: 10px 9px;
		display: flex;
		display: -webkit-flex;
		justify-content: center;
		-webkit-justify-content: center;
	}

	.dataTitle .title-left {
		width: 58px;
		background: url(../../assets/img/data/small-bg_left.png) no-repeat;
		margin-top: 3px;
	}

	.dataTitle .title-right {
		width: 58px;
		background: url(../../assets/img/data/small-bg_left.png) no-repeat;
		margin-bottom: 4px;
		transform: scaleY(-1);
		-webkit-transform: scaleY(-1);
	}

	.dataTitle span {
		color: #FFF;
		font-size: 20px;
		text-align: center;
		flex: 1;
		-webkit-flex: 1;
	}

	.dataNum {
		color: #FFF;
		font-size: 17px;
		text-align: center;
		padding: 10px 0 26px;
	}

	.dataNum em {
		font-size: 29px;
		font-family: 'Acens' !important;
		font-weight: 400;
		color: #FAAD14;
		font-style: normal;
		margin-right: 2px;
		vertical-align: -1px;
	}

	.pageList {
		width: 100%;
		height: 234px;
		margin: 14px 0 30px;
		padding: 15px 16px;
		border-radius: 40px;
		box-shadow: 0px 0px 10px 0px rgba(21, 213, 241, 0.5) inset;
		position: relative;
		display: flex;
		display: -webkit-flex;
	}

	.listData {
		width: 349px;
		height: 204px;
		background: url(../../assets/img/data/bottom-bg-img.png) no-repeat;
		background-size: contain;
		margin-right: 16px;
		padding: 17px 15px;
	}

	.listData:last-child {
		margin-right: 0;
	}

	.list-title {
		color: #FFF;
		font-size: 20px;
	}

	.list-title .icon-title {
		width: 58px;
		height: 21px;
		background: url(../../assets/img/data/icon/img-001.png) no-repeat;
		margin-right: 6px;
		display: inline-block;
		vertical-align: -2px;
	}

	.list-title .icon-title.icon1 {
		width: 46px;
		margin-left: 17px;
	}

	.list-title .icon-title.icon2 {
		background: url(../../assets/img/data/icon/img-002.png) no-repeat;
	}

	.list-title .icon-title.icon3 {
		background: url(../../assets/img/data/icon/img-003.png) no-repeat;
	}

	.list-title .icon-title.icon4 {
		background: url(../../assets/img/data/icon/img-004.png) no-repeat;
	}

	.list-title .icon-title.icon5 {
		background: url(../../assets/img/data/icon/img-005.png) no-repeat;
	}

	.dataBox ul li {
		color: #BCCBFC;
		font-size: 14px;
		line-height: 28px;
		border-bottom: 1px dashed rgba(188, 203, 252, 0.2);
	}

	.dataBox ul li:last-child {
		border-bottom: none;
	}



	.dataTitle .icon-chart {
		width: 49px;
		height: 21px;
		background: url(../../assets/img/data/head-img-bg.png) no-repeat;
	}

	.dataTitle .icon-chart.right {
		transform: scaleX(-1);
	}

	.live {
		position: relative;
		// width: 23px;
		// height: 23px;
		width: 10px;
		height: 10px;
		z-index: 0;
		background: rgb(5, 228, 249);
		border-radius: 10px;
	}


	.live img {
		width: 23px;
		height: 23px;
		z-index: 0;
	}

	@keyframes living {
		0% {
			transform: scale(1);
			opacity: 0.2;
		}

		50% {
			transform: scale(1.5);
			opacity: 0;
			/*圆形放大的同时，透明度逐渐减小为0*/
		}

		100% {
			transform: scale(1);
			opacity: 0.1;
		}
	}

	.live span {
		position: absolute;
		width: 23px;
		height: 23px;
		left: 0;
		bottom: 0;
		background: #06dff9;
		border-radius: 50%;
		-webkit-animation: living 3s linear infinite;
		z-index: -1;
	}

	.live span:nth-child(2) {
		-webkit-animation-delay: 0.8s;
		/*第二个span动画延迟1.5秒*/
	}




}
</style>
