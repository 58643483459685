<template>
<div class="bottom_box">
				<div class="  grid">
					<div class="col">
						<div class="title">关于我们</div>
						<p>
							上海一实网络科技有限公司(
							商标“科技牛”)是由上海交通大学海外教育学院项目中心团队与全国名校具有丰富经验的技术转移专家、技术研发专家、企业家，共同组建而成的一家专业从事：技术升级改造、新产品研发、技术咨询、技术服务、技术转移、为企业匹配合适的技术研发团队及技术解决方案。
						</p>
						<div class="grid">
							<div class="col"> 电话 <br>021-60496259</div>
							<!-- <div class="col"> 传真 <br>021-60450199</div> -->
						</div>
						<div class="grid">
							<div class="col"  style="width: 190px;">  备案号：<a href="https://beian.miit.gov.cn" target="_blank" >  沪ICP备16055134号</a></div>
						</div>
					</div>
					<div class="col">
						<div class="title">提供的服务</div>
						<router-link to="/achievement"> 研究成果 </router-link><br>
						<router-link to="/expert">技术专家 </router-link><br>
						<router-link to="/demand/add">现有需求</router-link><br>
						<router-link to="/news">行业新闻</router-link><br>
					</div>
					<div class="col col3 ">
						<div class="grid">
							<div class="col">
								<div class="title">发送邮箱</div>
								如果您有什么疑问请联系我们期待与您<br>的合作 <br>
								<br> 邮箱 <br>
								postmaster@keji6.cn
							</div>
							<div class="col">
								<div class="grid">
									<div class="col">
										<div class="title2">科技牛APP</div>
										<img src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/other/index1.jpg" alt="">
									</div>
									<div class="col">
										<div class="title2"> 官方公众号</div>
										<img src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/other/index15.jpg" alt="">
									</div>
								</div>
							</div>
						</div>
						<div class="friendship">
							<div class="title">友情链接</div>
							<p>
								<span v-for="link in friendLinks" :key="link.id">
									<a :href="link.url" target="_blank">{{ link.name }}</a>
								</span>
							</p>
						</div>
					</div>

				</div>
			</div>
		
</template>

<script>
	export default {
		name: 'HelloWorld',
		props: {
			msg: String
		},
		data() {
			return {
				friendLinks: []
			}
		},
		created() {
			this.fetchFriendLinks();
		},
		methods: {
			async fetchFriendLinks() {
				try {
					const response = await this.$http_qm
					.post('friend-link/list', {
						page: 1,
						limit: 20
					});
					if (response.code === 200) {
						this.friendLinks = response.data;
					}
				} catch (error) {
					console.error('获取友情链接失败:', error);
				}
			}
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
