<template>
	<div id="shop">

		<Top v-if="data.name" :data="data" :TabCur="0"></Top>

		<div class="new-shop-index shop-case about-shop">
			<!-- top 店铺轮播图 -->
			<div class="swper margin-top-20" v-if="TabCur==0">
				<el-carousel v-if="linkList.list&&linkList.list.length>0" trigger="click" height="650px">
					<el-carousel-item v-for="(item,index) in linkList.list" :key="index">
						<el-image style="width: 1200px; height: 640px" :src="item.img" fit="cover" :alt="item.title">
						</el-image>
					</el-carousel-item>
				</el-carousel>
			</div>
			<!-- bottom -->
			<div class="shop-case-conten">
				<!-- left -->
				<Left :data="data" :id="id"></Left>
				<!-- cur1 案例+公司简介 -->
				<div class="index-right" v-if="TabCur==0">
					<div class="case-latest">
						<div class="column-box">
							<p class="f16 text-bold">最新产品</p>
							<span class="more-link text-muted" v-on:click="more('/store/anli')">更多 ></span>
						</div>
						<ul>
							<li class="fl" v-for="(item,index) in new_product.list" :key="index">
								<router-link :to="{path:'/store/anli_detail?',query:{id:item.id,company_id:id}}">
									<div class="img-box">
										<el-image v-if="item.cover" style="width: 280px; height: 160px"
											:src="item.cover[0]" fit="cover"></el-image>
									</div>
									<div class="txt-box padding-top-10">
										<span class="f14 cur-por text-overflow">
											{{item.title}}
											<p class="margin-top-10 text-muted">
												<template v-for="(v,k) in item.tag">
													<el-tag effect="plain" size="small" v-if="k<2" :key="k">
														{{v}}
														<!-- <span v-if="k<item.tag.length-1">, </span> -->
													</el-tag>
												</template>
											</p>
										</span>
									</div>
								</router-link>
							</li>
						</ul>
					</div>
					<div class="company-introduce">
						<div class="column-box">
							<p class="f16 text-bold fl">公司介绍</p>
							<span class="more-link fr f14 text-muted" v-on:click="more('/store/about')">更多 ></span>
						</div>
						<div class="info-box">
							<div class="img-box">
								<el-image style="width: 166px; height: 120px" :src="linkList.list[0].img"
									v-if="linkList.list&&linkList.list.length > 0" fit="cover"></el-image>
							</div>
							<div class="txt-box">
								<div id="compute_height">
									{{data.introduction}}
									<a href="/store/store38673/aboutus/" class="padding-right-40 more-detail text-left"
										style="display: none;"><em
											class="text-light-gray margin-right-5">...</em>详情&gt;</a>
								</div>
							</div>
						</div>
					</div>
				</div>

			</div>

		</div>
		<flexRight></flexRight>
	</div>
</template>

<script>
	import Top from "./top.vue"
	import Left from "./left.vue"
	import flexRight from "@/components/flexRight.vue"
	export default {
		name:'StoreIndex',
		metaInfo: {
			title: '科技牛—为企业精准匹配科研专家以及提供技术解决方案的服务平台',
			meta: [{
					name: 'keywords',
					content: '技术对接,技术交易,技术对接平台,技术创新服务,技术转移,成果转化,技术推广,解决方案'
				},
				{
					name: 'description',
					content: '科技牛是一家专业为企业提供四技服务:技术开发、技术转让、技术咨询、技术服务，精准匹配科研专家及技术解决方案平台公司'
				}
			]
		},
		components: {
			Top,
			Left,
			flexRight
		},
		data() {
			return {
				id: '',
				title_list: [{
					title: '店铺首页',
					path: '/store/index'
				}, {
					title: '最新产品',
					path: '/store/anli'
				}, {
					title: '关于我们',
					path: '/store/about'
				}, {
					title: '留言咨询',
					path: '/store/leave'
				}],
				TabCur: 0,
				currentPage: 1, //初始页
				pageSize: 10, //每页个数
				Case: false,
				// 显示案例图片
				img: '',
				data: {},
				new_product: {
					list: [],
					limit: 8,
					page: 1,
					count: 0,
				},
				linkList: {
					list: [],
					limit: 5,
					page: 1
				}
			}
		},
		computed: {},
		mounted() {
			document.querySelector('#app').scrollIntoView({
				behavior: "smooth"
			});
			let query = this.$route.query;
			this.id = query.id;
			this.get_detail();
		},
		methods: {
			get_new_product() {
				this.$http_qm.post('product/list', {
					limit: this.new_product.limit,
					page: this.new_product.page,
					uid: this.data.uid,
					request_type: 'store'
				}).then(res => {
					this.new_product.list = res.data;
					this.new_product.count = res.count;
				}).catch(err => {
					console.log(err);
				})
			},
			get_detail() {
				this.$http_qm.post('company/detail', {
					id: this.id
				}).then(res => {
					this.data = res.data;
					this.linkList.company_id = this.data.id;
					this.get_new_product()
					this.banner_list()
				}).catch(err => {
					console.log(err);
				})
			},
			banner_list() {
				let data = this.linkList;
				this.$http_qm.post('company_banner/list', data).then(res => {
					if (res.code == 200) {
						this.linkList.list = res.data;
					}
				}).catch(err => {
					console.log(err);
				})
			},
			handleCurrentChange(val) {
				console.log(`当前页: ${val}`);
			},
			more(url) {
				this.$router.push({
					path: url,
					query: {
						id: this.id
					}
				})
			}
		}
	}
</script>
<style type="text/css" lang="less" src="../../assets/css/store.less"></style>
<style lang="less">
	#shop {
		ackground: #EFEFEF;
		padding-bottom: 40px;

		.index-right {
			.cur-por {
				p {
					margin-top: 10px;
				}

				.el-tag {
					margin: 0px 10px 0px 0px;
				}
			}
		}

		.shop-case {
			padding: 0;

			.shop-case-conten {
				display: flex;
				width: 1200px;
				margin: 20px auto 0;

				.index-right {
					padding: 0;
					margin-left: 20px;

					.case-latest {
						// width: 1200px;
						padding: 20px;
						background: #fff;
						overflow: hidden;
						display: flex;
						flex-direction: column;
						box-sizing: border-box;

						.column-box {
							display: flex;
							align-items: center;
							justify-content: space-between;

							.text-muted {
								color: #999999;
								cursor: pointer;
							}
						}

						ul {
							width: 100%;

							.img-box {
								width: 280px;
								height: 160px;
								overflow: hidden;

								img {
									width: 100%;
									height: 100%;
								}
							}
						}
					}

					.company-introduce {
						width: 940px;
						padding: 20px;
						background: #fff;
						overflow: hidden;
						box-sizing: border-box;
						display: flex;
						flex-direction: column;
						margin-top: 20px;

						.info-box {
							margin-top: 20px;
							display: flex;
							flex-direction: row;

							.txt-box {
								margin-left: 20px;
								width: 100%;
								font-size: 16px;
							}
						}
					}
				}
			}
		}
	}
</style>
