<template>
	<div id="templet">
		<div class="container">
			<div class="col_left">
				<Left></Left>
			</div>
			<div class="col_right">
				<div class="title">
					<h3>模板设置</h3>
					<p>当前位置：<router-link to="/user_center/zc_message">会员中心</router-link> / 主页设置 / 模板设置</p>
				</div>
				<div class="select_template">
					<div class="template_unit" v-for="(item, index) in select_list" :key="index">
						<dl>
							<dt><img :src="item.img" /></dt>
							<dd>{{ item.name }}<span>{{ item.shape }}</span></dd>
							<dd>
								<button @click="employ(item.update_type, item.template)"
									v-html="company.template == item.template ? '使用中' : '使用'"
									:class="company.template == item.template ? 'bg-grey' : ''"></button>
								<button @click="Company(item.template)">预览</button>
								<!-- <button style="visibility:hidden;">查看模板效果</button> -->
							</dd>
						</dl>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Left from "../company/Left.vue"
import {
	mapGetters
} from 'vuex'
export default {
	name: 'EditTemplet',
	components: {
		Left
	},
	data() {
		return {
			select_list: [{
				img: require('../../assets/img/template/template_1.png'),
				name: '普通模板01',
				shape: '免费',
				update_type: 'template',
				template: 0
			},
			// {
			// 	img: require('../../assets/img/template/c_vip01.jpg'),
			// 	name: 'VIP模板01',
			// 	shape: 'vip专用',
			// 	update_type: 'template',
			// 	template: 1
			// }, 
			{
				img: require('../../assets/img/template/c_vip02.jpg'),
				name: 'VIP模板02',
				shape: 'vip专用',
				update_type: 'template',
				template: 2
			},
				// {
				// 	img: require('../../assets/img/template/c_vip03.jpg'),
				// 	name: 'VIP模板03',
				// 	shape: 'vip专用',
				// 	update_type: 'template',
				// 	template: 3
				// }
			],
			company: {},
		}
	},
	mounted() {
		this.get_detail();
	},
	computed: {
		...mapGetters(['user'])
	},
	methods: {
		get_detail() {
			this.$http_qm.post('company/detail', {
				type: 'me'
			}).then(res => {
				if (res.code == 200) {
					this.company = res.data;
				}
			}).catch(err => {
				console.log(err);
			})
		},
		employ(updateType, tempLate) {
			let data = this.company;
			let obj_data = {
				update_type: updateType,
				template: tempLate
			}
			Object.assign(data, obj_data);
			if (this.user.company_is_certification == 0) {
				this.$http_qm.post('company/update', data).then(res => {
					if (res.code == 200) {
						this.$message.info(res.msg);
						this.$forceUpdate();
					}
				}).catch(err => {
					console.log(err)
				})
			} else {
				this.$message.info('清先开通会员');
				this.$router.push({
					path: '/rights',
					query: {
						id: this.company.id
					}
				})
			}
		},
		Company(type) {
			switch (type) {
				case 0:
					var routeUrl = this.$router.resolve({
						path: '/store/index',
						query: {
							id: this.company.id
						}
					})
					window.open(routeUrl.href, '_blank');
					break;
				case 2:
					var vipUrl = this.$router.resolve({
						path: '/c/vip01/index',
						query: {
							id: this.company.id
						}
					})
					window.open(vipUrl.href, '_blank');
					break;
			}
		}
	}
}
</script>

<style lang="less">
#templet {
	background: #f7f7f7 !important;

	.container {
		width: 1200px;
		margin: auto;
		padding: 20px 0;
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		.col_left {
			width: 230px;
			border: 1px solid #e6e6e6;
			background: #fff;
			border-top: none;
		}

		.col_right {
			width: 940px;
			border: 1px solid #e6e6e6;
			background: #fff;
			box-sizing: border-box;

			.title {
				height: 60px;
				padding: 0 20px;
				display: flex;
				flex-direction: row;
				justify-content: space-between;

				h3 {
					line-height: 60px;
					font-size: 16px;
					color: #333;
				}

				p {
					line-height: 60px;
					font-size: 12px;
					color: #999;
				}
			}

			.select_template {
				float: left;
				height: auto;
				padding: 40px 35px;
				border-top: 1px solid #e6e6e6;

				.template_unit {
					float: left;
					width: 434px;
					height: auto;
					overflow: hidden;
					margin-bottom: 80px;

					dl {
						width: 284px;
						height: auto;
						margin: 0 auto;

						dt {
							width: 284px;
							height: 234px;

							img {
								display: block;
								width: 100%;
								height: 100%;
							}
						}

						dd {
							&:nth-child(2) {
								height: 60px;
								line-height: 60px;
								font-size: 16px;
								text-align: center;
							}

							&:nth-child(3) {
								display: flex;
								flex-direction: row;
								justify-content: center;
							}

							span {
								display: inline-block;
								height: 18px;
								line-height: 18px;
								font-size: 12px;
								color: #2abe7e;
								padding: 0 8px;
								margin-left: 10px;
								border: 1px solid #2abe7e;
								border-radius: 4px;
							}

							button {
								float: left;
								width: 80px;
								height: 40px;
								line-height: 40px;
								font-size: 14px;
								color: #fff;
								text-align: center;
								background: #3995db;
								outline: none;
								border: none;
								cursor: pointer;

								&:nth-child(1):hover {
									background: #2585cf;
								}

								&:nth-child(2) {
									background: #2abe7e;
									margin-left: 5px;

									&:hover {
										background: #1cac6e;
									}
								}

								&:nth-child(3) {
									width: 116px;
									height: 40px;
									background: #fff;
									color: #2abe7e;
									border: 1px solid #2abe7e;
									margin-left: 2px;

									&:hover {
										color: #fff;
										background: #2abf7e;
										border: 1px solid #2abf7e;
									}
								}
							}

							.bg-grey {
								background-color: gray;
							}
						}

					}
				}
			}
		}
	}
}
</style>
