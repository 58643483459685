<template>
	<div id="top">
		<div class="employer-header-nav">
			<div class="employer-nav-cont">
				<div class="employer-nav-logo">
					<img src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/other/lucency-Logo.png">
					<span class="employer-logo-sub">账号设置</span>
				</div>
				<div class="employer-nav-links">
					<ul class="clearfix">
						<li>
							<span>雇主中心</span>
						</li>
						<li>
							<span>服务商中心</span>
						</li>
					</ul>
				</div>
				<div class="employer-nav-search">
					<div class="employer-nav-search-form">
						<input data-placeholder="" placeholder="LOGO设计" class="employer-nav-search-input" name="kw"
							autocomplete="off" type="text">
						<button class="employer-nav-search-btn" @click="search">搜索</button>
						<div class="employer-nav-pub-task" @click="to('/demand/add')">发需求</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'SetTop',
	data() {
		return {}
	},
	methods: {
		search() {
			console.log('search')
		},
		to(url) {
			this.$router.push({
				path: url
			})
		}
	}
}
</script>

<style lang="less" src="../../assets/css/set.less"></style>
