<template>
	<div id="list">
		<Top></Top>
		<div class="mui-home-item">
			<div class="bannerImg">
				<img src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/other/activity.png" />
			</div>
			<div class="main">
				<div class="column-search">
					<!-- 面包屑导航-->
					<el-breadcrumb separator-class="el-icon-arrow-right">
						<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
						<el-breadcrumb-item>活动列表</el-breadcrumb-item>
					</el-breadcrumb>
				</div>
				<div class="selectNumberScreen">
					<div class="sxrr">
						<div class="cate-item ">
							<div class="cate-item-inner">
								<label class="item-label">所在地区：</label>
								<div :class="city_active ? 'active' : 'unfold'">
									<ul class="box">
										<li v-for="(c, index) in cate.city" :key="index"
											:class="{ checked: index == province_index }"
											@click="province_click(index, c.name)">{{ c.name }}</li>
									</ul>
								</div>
								<span class="item-span" v-html="city_active ? '收起' : '展开'"
									@click="city_active = !city_active"></span>
							</div>
						</div>
						<div class="flex select_time ">
							<div class="title">发布时间:</div>
							<ul style="flex: 1; display: flex;">
								<li v-for="(v, k) in time_arr" @click="time_change(k, v)" :class="{ active: k == time_index }"
									:key="k">{{ v }}</li>
							</ul>
						</div>

					</div>
				</div>
				<div class="roadContent">
					<div class="meet_list">
						<ul>
							<li v-for="(v, k) in activity.list" :key="k" class="li" @click="detail(v.id)">
								<div class="img_show">
									<el-image style="width: 381px !important; height: 192px" :src="v.cover[0]"
										fit="cover"></el-image>
								</div>
								<div class="content-view">
									<div class="v-time">
										<i class="el-icon-alarm-clock"></i>
										<span>{{ v.time_start }}</span>
									</div>
									<div class="v-title">
										<span>{{ v.title }}</span>
									</div>
									<div class="v-location">
										<i class="el-icon-location-information"></i>
										<span>{{ v.addr }}</span>
									</div>
								</div>
							</li>
						</ul>
					</div>
					<div class="page">
						<el-pagination @current-change="handleCurrentChange" background :page-size="activity_post.limit"
							layout="prev, pager, next" :total="activity.count" :current-page="currentPage">
						</el-pagination>
					</div>
				</div>
			</div>
		</div>
		<flexRight></flexRight>
	</div>
</template>

<script>
import Top from "../../components/top.vue"
import flexRight from "@/components/flexRight.vue"
export default {
	name: 'ActivityList',
	components: {
		Top, flexRight
	},
	metaInfo: {
		title: '科技牛—为企业精准匹配科研专家以及提供技术解决方案的服务平台',
		meta: [{
			name: 'keywords',
			content: '技术对接,技术交易,技术对接平台,技术创新服务,技术转移,成果转化,技术推广,解决方案'
		},
		{
			name: 'description',
			content: '科技牛是一家专业为企业提供四技服务:技术开发、技术转让、技术咨询、技术服务，精准匹配科研专家及技术解决方案平台公司'
		}
		]
	},
	data() {
		return {
			radio: '全部',
			province_index: 0,
			time_index: 0,
			time_arr: ['全部', '今天', '最近三天', '最近一周', '一个月内'],
			city_active: false,
			cate: [],
			activity: {
				list: [],
				count: 0,
			},
			activity_post: {
				limit: 6,
				page: 1,
				create_time: '',
				status: 1,
				province: '',
			},
			currentPage: 1
		}
	},
	mounted() {
		this.get_cate();
		this.get_list();
	},
	created() {
		let query = this.$route.query
		if (typeof (query.page) != 'undefined') {
			this.activity_post.page = parseInt(query.page);
			this.currentPage = parseInt(query.page);
		} else {
			this.activity_post.page = 1;
			this.currentPage = 1;
		}
	},
	watch: {
		$route() {
			let query = this.$route.query
			if (typeof (query.page) != 'undefined') {
				this.news_post.page = parseInt(query.page);
				this.currentPage = parseInt(query.page);
			} else {
				this.news_post.page = 1;
				this.currentPage = 1;
			}
			this.get_list();
		},
	},
	methods: {
		time_change(index, name) {
			console.log(index, name)
			this.time_index = index;
			if (name == '全部') {
				this.activity_post.create_time = '';
			} else {
				this.activity_post.create_time = name;
			}
			this.activity_post.page = 1;
			this.get_list();
		},
		handleCurrentChange(page) {
			this.$router.push({
				path: '/activity',
				query: {
					page: page
				}
			})
			// this.activity_post.page = page;
			// this.get_list();
			document.querySelector('#app').scrollIntoView({
				behavior: "auto"
			});
		},
		async get_cate() {
			await this.$http_qm.post('demand/cate', {}).then(res => {
				this.cate = res.data;
				this.cate.city.unshift({
					name: '不限'
				})
				this.cate.industry.unshift({
					name: '不限'
				})
				this.cate.type.unshift({
					name: '不限'
				})
			}).catch(err => {
				console.log(err)
			})
		},
		province_click(index, name) {
			console.log(index, name);
			this.province_index = index;
			if (name == '不限') {
				this.activity_post.province = ''
			} else {
				this.activity_post.province = name
			}
			this.activity_post.page = 1;
			this.get_list();
		},
		get_list() {
			this.$http_qm.post('activity/list', this.activity_post).then(res => {
				if (res.code == 200) {
					this.activity.list = res.data;
					this.activity.count = res.count;
				}
			}).catch(err => {
				console.log(err)
			})
		},
		detail(id) {
			this.$router.push({
				path: '/activity/detail',
				query: {
					id: id
				}
			})
		}
	}
}
</script>

<style lang="less" src="../../assets/css/activity.less"></style>
