<template>
	<div id="vip01_introduction">
		<Header :data="data" :id="id"></Header>
		<div class="pro_banner"><img src="../../../../assets/img/template/pro_banner.png" alt=""></div>
		<div class="product wrap">
			<el-tabs v-model="activeName">
				<el-tab-pane label="公司简介" name="first">
					<div class="newsCont01">
						<div class="newstext">
							<img src="../../../../assets/img/template/introduction.jpg">
							<h3>{{ data.name }}</h3>
							{{ data.introduction }}
						</div>
					</div>
				</el-tab-pane>
				<!-- <el-tab-pane label="企业文化" name="second" disabled></el-tab-pane> -->
				<!-- <el-tab-pane label="荣誉资质" name="third" disabled></el-tab-pane> -->
			</el-tabs>

		</div>
		<Footer ref="mychild" :data="data" :id="id"></Footer>
	</div>
</template>

<script>
import Header from "./header.vue"
import Footer from "./footer.vue"
export default {
	name:'Vip1Introduction',
	components: {
		Header, Footer
	},
	data() {
		return {
			activeName: 'first',
			id: '',
			data: {},
		}
	},
	mounted() {
		let query = this.$route.query;
		this.id = query.id;
		this.get_detail();
	},
	methods: {
		get_detail() {
			this.$http_qm.post('company/detail', {
				id: this.id
			}).then(res => {
				this.data = res.data;
				this.$refs.mychild.link_list(this.data.id);
			}).catch(err => {
				console.log(err);
			})
		},
	}
}
</script>

<style lang="less">
#vip01_introduction {
	.pro_banner {
		height: 280px;
		overflow: hidden;

		img {
			width: 100%;
			height: 100%;
		}
	}

	.el-tabs__item {
		height: 36px;
		line-height: 36px;
		width: 75px;
		margin: 15px 15px 15px 0;
		padding: 0;
		text-align: center;
	}

	.is-active {
		color: #fff;
		background-color: #0088ff;
	}

	.el-tabs__active-bar {
		background-color: none;
	}

	.wrap {
		width: 1200px;
		margin: 0 auto;
	}

	.newsCont01 {
		width: 1200px;
		margin: auto;
		min-height: 500px;

		.newstext {
			width: 1200px;
			padding: 16px 0;
			overflow: hidden;
			color: #666;
			font-size: 16px;
			line-height: 34px;

			img {
				float: left;
				margin-right: 20px;
				margin-bottom: 20px;
			}

			h3 {
				color: #0088ff;
				font-size: 24px;
				text-align: center;
				line-height: 83px;
			}
		}
	}
}
</style>
