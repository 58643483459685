<template>
	<div id="achievemenAdd">
		<Top></Top>
		<div class="grzx-bgcont">
			<div class="container">
				<div class="grzx_left">
					<Left></Left>
				</div>
				<div class="grzx-right fr">
					<div class="grzx-box">
						<div class="grzx-wxts">
							<div class="wxts-wzts">
								<h3>温馨提示：</h3>
								<p>○ 为了保证您的信息能顺利通过我们的审核，请将信息的真实情况全面的发布出来。</p>
								<p>○ 根据我们的长期跟踪统计，信息完整度越高，越容易获得目标客户的关注哦！</p>
								<p>○ 信息完整度越高，将在我们的平台搜索结果排序更靠前，以及获得推荐和享受增值服务试用的机会更大哦！</p>
							</div>
						</div>
						<el-form :model="ruleForm" label-position="left" :rules="rules" ref="ruleForm"
							label-width="180px" class="demo-ruleForm">
							<el-form-item label="技术名称" prop="title">
								<el-input class="from_name" v-model="ruleForm.title" placeholder="请输入您的技术名称,最多不超过35个字"
									maxlength="35" style="width: 380px;"></el-input>
							</el-form-item>
							<el-form-item label="产业领域" prop="industrial">
								<el-select v-model="ruleForm.industrial" placeholder="请选择" style="width: 200px;">
									<el-option v-for="(v, k) in industrial" :key='k' :value="v" :label="v"></el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="是否拥有知识产权" prop="property_right">
								<el-radio-group v-model="ruleForm.property_right">
									<el-radio label="是">是</el-radio>
									<el-radio label="否">否</el-radio>
								</el-radio-group>
							</el-form-item>
							<el-form-item label="项目简介" prop="xmhjsqk">
								<el-input type="textarea" v-model="ruleForm.xmhjsqk" :autosize="{ minRows: 2 }"
									placeholder="请为该项目进行简介描述" style="width:690px;"></el-input>
							</el-form-item>
							<el-form-item label="技术效果图" prop="img">
								<el-upload :action="baseUrl + 'file/upload_one'" :data="{ dir: 'achivement' }"
									:on-success="handleAvatarSuccess" list-type="picture-card" :file-list="img_list"
									:limit="4">
									<i slot="default" class="el-icon-plus"></i>
								</el-upload>
								<el-dialog :visible.sync="dialogVisible">
									<img width="100%" :src="dialogImageUrl" alt="">
								</el-dialog>
							</el-form-item>
							<el-form-item label="合作方式" prop="cooperation_type">
								<el-radio-group v-model="ruleForm.cooperation_type" class="join">
									<div class="price" v-for="(v, k) in cooperation_type_arr" :key="k">
										<el-radio :label="v.val">{{ v.name }}</el-radio>
										<span>价格</span>
										<el-input v-model="v.price" @change="price_change"
											:disabled="ruleForm.cooperation_type != v.val || v.discuss" placeholder="请输入价格"
											style="width: 120px;"></el-input>
										<span>万元</span>
										<el-checkbox v-model="v.discuss" @change="discuss_change(k)"
											:disabled="ruleForm.cooperation_type != v.val">面议</el-checkbox>
									</div>

								</el-radio-group>
							</el-form-item>
							<el-form-item label="专利状态" prop="zl_status">
								<el-radio-group v-model="ruleForm.zl_status">
									<el-radio :label="1">是</el-radio>
									<el-radio :label="2">否</el-radio>
								</el-radio-group>
							</el-form-item>

							<el-form-item label="应用领域" prop="yyly">
								<el-input type="textarea" v-model="ruleForm.yyly" placeholder="请为该技术进行详细描述"
									style="width:690px;"></el-input>
							</el-form-item>
							<el-form-item label="技术成熟度" prop="maturity">
								<el-radio-group v-model="ruleForm.maturity">
									<el-radio label="研发阶段"></el-radio>
									<el-radio label="已有样品"></el-radio>
									<el-radio label="批量生产"></el-radio>
									<el-radio label="应用市场"></el-radio>
								</el-radio-group>
							</el-form-item>
							<el-form-item label="关键词" prop="tag">
								<el-tag :key="tag" v-for="tag in ruleForm.tag" closable :disable-transitions="false"
									@close="handleClose(tag)">
									{{ tag }}
								</el-tag>
								<el-input class="input-new-tag" v-if="inputVisible" v-model="inputValue"
									ref="saveTagInput" size="small" @keyup.enter.native="handleInputConfirm"
									@blur="handleInputConfirm">
								</el-input>
								<div v-else>
									<el-button v-if="ruleForm.tag.length < 3" class="button-new-tag" size="small"
										@click="showInput">点击输入标签
									</el-button>
								</div>

							</el-form-item>
							<el-form-item>
								<el-button type="primary" @click="submitForm('ruleForm')">提交审核</el-button>
								<el-button>保存草稿</el-button>
							</el-form-item>
						</el-form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Top from './top.vue';
import Left from "../company/Left.vue"
export default {
	name: 'AchievementAdd',
	metaInfo: {
		title: '科技牛—为企业精准匹配科研专家以及提供技术解决方案的服务平台',
		meta: [{
			name: 'keywords',
			content: '技术对接,技术交易,技术对接平台,技术创新服务,技术转移,成果转化,技术推广,解决方案'
		},
		{
			name: 'description',
			content: '科技牛是一家专业为企业提供四技服务:技术开发、技术转让、技术咨询、技术服务，精准匹配科研专家及技术解决方案平台公司'
		}
		]
	},
	components: {
		Top,
		Left
	},
	data() {
		return {
			ruleForm: {
				title: '',
				industrial: '',
				property_right: '',
				xmhjsqk: '',
				img: [],
				cooperation_type: [],
				reference_price: '',
				zl_status: 1,
				yyly: '',
				maturity: '',
				tag: []
			},
			inputVisible: false,
			inputValue: '',
			img_list: [],
			dialogImageUrl: '',
			dialogVisible: false,
			disabled: false,
			rules: {
				title: [{
					required: true,
					message: '请输入技术名称'
				}],
				industrial: [{
					required: true,
					message: '请选择适用行业'
				}],
				property_right: [{
					required: true,
					message: '请选择是否有知识产权'
				}],
				xmhjsqk: [{
					required: true,
					message: '请填写项目简介'
				}],
				img: [{
					required: true,
					message: '请填上传成果图片'
				}],
				cooperation_type: [{
					required: true,
					message: '请选择合作方式'
				}],
				zl_status: [{
					required: true,
					message: '请选择专利状态'
				}],
				yyly: [{
					required: true,
					message: '请填写应用领域'
				}],
				maturity: [{
					required: true,
					message: '请选择技术成熟度'
				}],
				tag: [{
					required: true,
					message: '请输入关键词'
				}]
			},
			industrial: [],
			cooperation_type_arr: [{
				name: '完全转让',
				val: 6,
				price: '',
				discuss: false,
			}, {
				name: '许可转让',
				price: '',
				val: 7,
				discuss: false,
			}, {
				name: '技术入股',
				price: '',
				val: 1,
				discuss: false,
			}],
			id: '',
		}
	},
	mounted() {
		if (typeof (this.$route.query.id) != 'undefined') {
			this.id = this.$route.query.id;
			this.get_detail();
		}
		this.get_cate();
		this.company_detail();
	},
	methods: {
		company_detail() {
			this.$http_qm
				.post('company/detail', {
					type: 'me'
				}).then(res => {
					if (res.code == 200) {
						this.ruleForm.company_id = res.data.id;
					} else if (res.code == 400) {
						this.member = false
					}
				})
				.catch(err => {
					console.log(err);
				});
		},
		get_cate() {
			this.$http_qm.post('expert/cate', {
				type: 'industrial'
			}).then(res => {
				this.industrial = this.industrial.concat(res.data.industrial);
			}).catch(err => {
				console.log(err)
			})
		},
		get_detail() {
			this.$http.post('achievement/detail', {
				id: this.id
			}).then(res => {
				if (res.code == 200) {
					this.ruleForm.title = res.data.title;
					this.ruleForm.industrial = res.data.industrial_one;
					this.ruleForm.img = res.data.img;
					this.ruleForm.tag = res.data.tag;
					if (res.data.tag && res.data.tag.length > 0) {
						this.ruleForm.tag = res.data.tag;
					}
					if (res.data.zl_status == 1) {
						this.ruleForm.property_right = '是'
					} else {
						this.ruleForm.property_right = '否'
					}
					this.ruleForm.xmhjsqk = res.data.xmhjsqk;
					let img_list = this.img_list
					if (Array.isArray(res.data.img)) {
						res.data.img.forEach(function () {
							img_list.push({
								url: res.data.img
							})
						})
					} else {
						img_list = [{
							url: res.data.img
						}]
					}
					let ruleForm = this.ruleForm
					this.cooperation_type_arr.forEach(function (v) {
						if (res.data.cooperation_type == v.val) {
							ruleForm.cooperation_type = v.val
							if (res.data.reference_price == '面议') {
								v.discuss = true
								ruleForm.reference_price = '面议'
							} else {
								v.price = res.data.reference_price
								ruleForm.reference_price = res.data.reference_price
							}
						}
					})


					this.ruleForm.zl_status = res.data.zl_status;
					this.ruleForm.yyly = res.data.yyly;
					this.ruleForm.maturity = res.data.maturity;
				}

			}).catch(err => {
				console.log(err);
			})
		},
		handleAvatarSuccess(res, file) {
			if (res.code == 200) {
				this.imageUrl = URL.createObjectURL(file.raw);
				this.ruleForm.img.push(res.data.img_url);
			} else {
				this.$message.error(res.msg)
			}
		},
		discuss_change(k) {
			if (this.cooperation_type_arr[k].discuss) {
				this.ruleForm.reference_price = '面议'
			}
		},
		price_change(v) {
			this.ruleForm.reference_price = v;
		},

		submitForm(formName) {
			this.$refs[formName].validate((valid) => {
				let add_data = this.ruleForm;
				console.log(add_data);
				if (!valid) {
					console.log('error submit!!');
					return false;
				}
				let url = '';
				if (this.id == '') {
					url = 'achievement/add'
					var post = this.$http.post(url, add_data)
				} else {
					url = 'achievement/update'
					add_data.id = this.id
					post = this.$http_qm.post(url, add_data)
				}
				post.then(res => {
					this.$message.info(res.msg)
					if (res.code == 200) {
						this.$router.push({
							path: '/achivement/admin'
						});
					}
				}).catch(err => {
					console.log(err)
				})
			});
		},
		handleClose(tag) {
			this.ruleForm.tag.splice(this.ruleForm.tag.indexOf(tag), 1);
		},
		showInput() {
			this.inputVisible = true;
			this.$nextTick(() => {
				this.$refs.saveTagInput.$refs.input.focus();
			});
		},
		handleInputConfirm() {
			let inputValue = this.inputValue;
			if (inputValue) {
				this.ruleForm.tag.push(inputValue);
			}
			this.inputVisible = false;
			this.inputValue = '';
		},
		// 图片上传
		handleRemove(file) {
			console.log(file);
		},
		handlePictureCardPreview(file) {
			this.dialogImageUrl = file.url;
			this.dialogVisible = true;
		}
	}
}
</script>

<style lang="less">
#achievemenAdd {
	color: #666;
	background-color: #fff;

	.grzx-bgcont {
		height: auto;
		overflow: hidden;
		background-color: #f5f5f5;

		.container {
			margin: 0 auto;
			width: 1200px;
			margin-top: 26px;
			margin-bottom: 20px;
			overflow: hidden;

			.grzx_left {
				width: 160px;
				height: auto;
				float: left;
				overflow: hidden;
			}

			.grzx-right {
				width: 1020px;

				.grzx-box {
					padding: 20px;
					background-color: #fff;
					margin-bottom: 20px;

					.grzx-wxts {
						height: 120px;
						border: #ffeed4 1px solid;
						margin-bottom: 20px;
						background-color: #fffbf5;

						.wxts-wzts {
							width: 720px;
							height: 100px;
							margin-top: 10px;
							float: left;
							margin-left: 30px;

							h3 {
								height: 30px;
								font-size: 16px;
								color: #f9651e;
								line-height: 30px;
							}

							p {
								width: 100%;
								height: 24px;
								font-size: 14px;
								color: #666666;
								line-height: 24px;

							}
						}
					}

					.el-form-item__label {
						font-size: 16px;
						font-family: "微软雅黑";
						color: #333;
					}

					.el-input__inner {
						width: 100%;
					}

					.el-radio-group {
						align-items: flex-start !important;
					}

					.el-tag {
						margin-left: 10px;
					}

					.button-new-tag {
						margin-left: 10px;
						height: 32px;
						line-height: 30px;
						padding-top: 0;
						padding-bottom: 0;
					}

					.input-new-tag {
						width: 90px;
						margin-left: 10px;
						vertical-align: bottom;
					}

					.join {
						flex-direction: column;

						.price {
							display: flex;
							flex-direction: row;
							align-items: center;

							span {
								font-size: 14px;
							}

							.el-input {
								margin: 0 10px;
							}

							.el-checkbox {
								margin-left: 30px;
							}
						}
					}
				}
			}

			.fr {
				float: right;
			}
		}
	}
}
</style>
