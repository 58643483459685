<template>
	<div id="soar">
		<div class="warp">
			<div class="bannernei">
				<img src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/soar/soar-banner.jpg" width="1920">
			</div>
			<div id="gxrz01">
				<div class="gxrz01 wid1200">
					<div class="neit">
						<span>高新认定的好处</span>
					</div>
					<div class="neib">BENEFITS OF HIGH-TECH RECOGNITION</div>
					<div class="neiline"></div>
					<div class="gxrz01_div">
						<div class="gxrz01_div_01">
							<div class="gxrz01_div_list transition05">
								<div class="img">
									<img src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/soar/gxrd01.png"
										class="gxrd transition05">
									<img src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/soar/gxrd01hover.png"
										class="gxrdhover transition05">
								</div>
								<div class="t transition05">企业税收减免</div>
								<div class="b transition05">高新企业享受优惠所得税率15%，降低公司税收成本</div>
							</div>
							<div class="gxrz01_div_list transition05">
								<div class="img">
									<img src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/soar/gxrd02.png"
										class="gxrd transition05">
									<img src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/soar/gxrd02hover.png"
										class="gxrdhover transition05">
								</div>
								<div class="t transition05">政府资金补贴</div>
								<div class="b transition05">可享受各区以及市里最高35万元认定补贴</div>
							</div>
						</div>
						<div class="gxrz01_div_01 gxrz01_div_02">
							<div class="gxrz01_div_list transition05">
								<div class="img">
									<img src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/soar/gxrd03.png"
										class="gxrd transition05">
									<img src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/soar/gxrd03hover.png"
										class="gxrdhover transition05">
								</div>
								<div class="t transition05">政府优先扶持</div>
								<div class="b transition05">土地、房租、工业用地、节能减排等国家政策资金倾斜，享受优先资金扶持</div>
							</div>
						</div>
						<div class="gxrz01_div_01">
							<div class="gxrz01_div_list transition05">
								<div class="img">
									<img src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/soar/gxrd04.png"
										class="gxrd transition05">
									<img src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/soar/gxrd04hover.png"
										class="gxrdhover transition05">
								</div>
								<div class="t transition05">企业优先上市</div>
								<div class="b transition05">吸引政策和资金扶持促进企业融资上市</div>
							</div>
							<div class="gxrz01_div_list transition05">
								<div class="img">
									<img src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/soar/gxrd05.png"
										class="gxrd transition05">
									<img src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/soar/gxrd05hover.png"
										class="gxrdhover transition05">
								</div>
								<div class="t transition05">增强企业核心竞争力</div>
								<div class="b transition05">进入高新企业圈，提高企业创新能力，增强企业核心竞争力</div>
							</div>
						</div>
						<div style="clear:both;"></div>
						<div class="gxrz01_div_03">
							<div class="gxrz01_div_list transition05">
								<div class="img">
									<img src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/soar/gxrd06.png"
										class="gxrd transition05">
									<img src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/soar/gxrd06hover.png"
										class="gxrdhover transition05">
								</div>
								<div class="t transition05">享受人才扶持政策</div>
								<div class="b transition05">可享受上海高层次人才补贴120万元，稳定企业高层人才</div>
							</div>
						</div>
						<div class="gxrz01_div_03">
							<div class="gxrz01_div_list transition05">
								<div class="img">
									<img src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/soar/gxrd07.png"
										class="gxrd transition05">
									<img src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/soar/gxrd07hover.png"
										class="gxrdhover transition05">
								</div>
								<div class="t transition05">研发费用扣除及补贴</div>
								<div class="b transition05">按175%加计扣除研究开发费用，且最高可获得上一年度研发费用总额20%的高新技术企业培育资助补贴</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="gxrz02">
				<div class="neit">
					<span>官方申报的条件</span>
				</div>
				<div class="neib">CONDITIONS FOR OFFICIAL DECLARATION</div>
				<div class="neiline"></div>
				<div class="gxrz02_li">
					<img src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/soar/gxrz02_01.jpg"
						class="gxrz02_liimg">
					<div class="text transition05">
						<div class="t transition05">时间和企业技术领域</div>
						<div class="b transition05">注册满一年且属于国家重点支持高新技术领域</div>
						<div class="d transition06"><img
								src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/soar/downjiantou.png"></div>
					</div>
				</div>
				<div class="gxrz02_li">
					<img src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/soar/gxrz02_02.jpg"
						class="gxrz02_liimg">
					<div class="text transition05">
						<div class="t transition05">知识产权</div>
						<div class="b transition05">拥有自主核心知识产权</div>
						<div class="d transition06"><img
								src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/soar/downjiantou.png"></div>
					</div>
				</div>
				<div class="gxrz02_li">
					<img src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/soar/gxrz02_03.jpg"
						class="gxrz02_liimg">
					<div class="text transition05">
						<div class="t transition05">研发销售占比</div>
						<div class="b transition05">收入＜5000万/5000万-2亿/＞2亿，研发投入5%/4%/3%</div>
						<div class="d transition06"><img
								src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/soar/downjiantou.png"></div>
					</div>
				</div>
				<div class="gxrz02_li">
					<img src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/soar/gxrz02_04.jpg"
						class="gxrz02_liimg">
					<div class="text transition05">
						<div class="t transition05">科研人员占比</div>
						<div class="b transition05">科技人员比例不低于10%</div>
						<div class="d transition06"><img
								src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/soar/downjiantou.png"></div>
					</div>
				</div>
			</div>
			<div class="midbanner">
				<a href="http://p.qiao.baidu.com/cps/chat?siteId=11939436&userId=25487969&siteToken=bce64c50ca792b2d58d2bb5861832e09"
					class="xmsb">
					<img src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/soar/gzrzmid.jpg" width="1920">
				</a>
			</div>
			<div id="gxrz03">
				<div class="gxrz03 wid1200">
					<div class="neit">
						<span>服务流程</span>
						<p>专人专组对接服务，大数据平台分析评估，服务实时周到，进度随时知晓</p>
					</div>
					<div class="gxrz03_div transition05 thisall">
						<div class="gxrz03_div_li_wai">
							<div class="gxrz03_div_li">
								<div class="l transition05"><img
										src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/soar/gxrz04_01.png">
								</div>
								<div class="text">
									<div class="t transition05">初步评估</div>
									<div class="b transition05">依据高新认定条件对企业现状进行测评并分析，初步评估得分并提出建议</div>
								</div>
								<div class="p transition05"></div>
							</div>
						</div>
						<div class="gxrz03_div_li_wai">
							<div class="gxrz03_div_li">
								<div class="l transition05"><img
										src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/soar/gxrz04_02.png">
								</div>
								<div class="text">
									<div class="t transition05">制定申报方案</div>
									<div class="b transition05">分析现有优势和差距，制定申报方案，确定准备工作和申报时间节点，发送申报进度表</div>
								</div>
								<div class="p transition05"></div>
							</div>
						</div>
						<div class="gxrz03_div_li_wai">
							<div class="gxrz03_div_li">
								<div class="l transition05"><img
										src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/soar/gxrz04_03.png">
								</div>
								<div class="text">
									<div class="t transition05">统筹辅导</div>
									<div class="b transition05">一实知产安排项目专员上门辅导，召开筹备会，明确分配相关对接人员，并随时通知项目申报进展</div>
								</div>
								<div class="p transition05"></div>
							</div>
						</div>
						<div class="gxrz03_div_li_wai">
							<div class="gxrz03_div_li">
								<div class="l transition05"><img
										src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/soar/gxrz04_04.png">
								</div>
								<div class="text">
									<div class="t transition05">材料收集及完善</div>
									<div class="b transition05">
										一对一指导对接，资料收集采用“先收集、后补充、再完善”一实知产安排申报专员对资料并进行逐一核验，协助客户补充及完善部分资料，争取每一个得分点</div>
								</div>
								<div class="p transition05"></div>
							</div>
						</div>
						<div class="gxrz03_div_li_wai">
							<div class="gxrz03_div_li">
								<div class="l transition05"><img
										src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/soar/gxrz04_05.png">
								</div>
								<div class="text">
									<div class="t transition05">项目填报</div>
									<div class="b transition05">资料汇编、资料终审、网上填报、纸质提交、回执反馈</div>
								</div>
								<div class="p transition05"></div>
							</div>
						</div>
						<div class="gxrz03_div_li_wai">
							<div class="gxrz03_div_li">
								<div class="l transition05"><img
										src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/soar/gxrz04_06.png">
								</div>
								<div class="text">
									<div class="t transition05">通过认定</div>
									<div class="b transition05">专家评审、网上公示、成功认定、领取证书</div>
								</div>
								<div class="p transition05"></div>
							</div>
						</div>
						<div class="gxrz03_div_li_wai">
							<div class="gxrz03_div_li">
								<div class="l transition05"><img
										src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/soar/gxrz04_07.png">
								</div>
								<div class="text">
									<div class="t transition05">跟踪服务</div>
									<div class="b transition05">协助办理税收减免手续、指导重新认定工作、接受其他科技项目咨询</div>
								</div>
								<div class="p transition05"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="gxrz04">
				<div class="neit">
					<span>申报所需材料</span>
				</div>
				<div class="neib">MATERIALS REQUIRED FOR DECLARATION</div>
				<div class="neiline"></div>
				<div class="gxrz04_li">
					<img src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/soar/20181213115216476.jpg"
						class="gxrz04_liimg">
					<div class="text transition05">
						<div class="t transition05"><span>企业基本材料</span></div>
						<div class="b transition05">营业执照<br>企业职工花名册</div>
					</div>
				</div>
				<div class="gxrz04_li">
					<img src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/soar/20181213115234667.jpg"
						class="gxrz04_liimg">
					<div class="text transition05">
						<div class="t transition05"><span>知识产权及研发组织管理资料</span></div>
						<div class="b transition05">企业知识产权证书<br>企业研发管理制度文件</div>
					</div>
				</div>
				<div class="gxrz04_li">
					<img src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/soar/20181213115256971.jpg"
						class="gxrz04_liimg">
					<div class="text transition05">
						<div class="t transition05"><span>企业财务资料</span></div>
						<div class="b transition05">近三个会计年度财务审计报告和所得税纳税申报表<br>上一年高新技术产品收入专项审计报告<br>近三年研发费用投入专项审计报告</div>
					</div>
				</div>
			</div>
			<div id="gxrz05">
				<div class="gxrz05">
					<div class="neit">
						<span>八大领域可申请</span>
					</div>
					<div class="neib">APPLICABLE IN EIGHT FIELDS</div>
					<div class="neiline"></div>
					<div class="gxrz05li transition05">
						<img src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/soar/gxrz_ly01.png"
							class="now transition05">
						<img src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/soar/gxrz_ly01_hover.png"
							class="hover transition05">
						<div class="t transition05"><span>电子信息</span></div>
					</div>
					<div class="gxrz05li transition05">
						<img src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/soar/gxrz_ly02.png"
							class="now transition05">
						<img src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/soar/gxrz_ly02_hover.png"
							class="hover transition05">
						<div class="t transition05"><span>生物与新医药</span></div>
					</div>
					<div class="gxrz05li transition05">
						<img src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/soar/gxrz_ly03.png"
							class="now transition05">
						<img src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/soar/gxrz_ly03_hover.png"
							class="hover transition05">
						<div class="t transition05"><span>航天空航</span></div>
					</div>
					<div class="gxrz05li transition05">
						<img src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/soar/gxrz_ly04.png"
							class="now transition05">
						<img src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/soar/gxrz_ly04_hover.png"
							class="hover transition05">
						<div class="t transition05"><span>新材料</span></div>
					</div>
					<div class="gxrz05li transition05">
						<img src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/soar/gxrz_ly05.png"
							class="now transition05">
						<img src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/soar/gxrz_ly05_hover.png"
							class="hover transition05">
						<div class="t transition05"><span>高技术服务</span></div>
					</div>
					<div class="gxrz05li transition05">
						<img src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/soar/gxrz_ly06.png"
							class="now transition05">
						<img src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/soar/gxrz_ly06_hover.png"
							class="hover transition05">
						<div class="t transition05"><span>新能源与节能</span></div>
					</div>
					<div class="gxrz05li transition05">
						<img src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/soar/gxrz_ly07.png"
							class="now transition05">
						<img src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/soar/gxrz_ly07_hover.png"
							class="hover transition05">
						<div class="t transition05"><span>资源与环境</span></div>
					</div>
					<div class="gxrz05li transition05">
						<img src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/soar/gxrz_ly08.png"
							class="now transition05">
						<img src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/soar/gxrz_ly08_hover.png"
							class="hover transition05">
						<div class="t transition05"><span>先进制造与自动化</span></div>
					</div>
				</div>
			</div>
			<div class="gxrz06">
				<div class="neit">
					<span>服务优势</span>
					<p>专业的事一定要找专业的人，高新认定选择一实知产，选择共赢</p>
				</div>
				<div class="gxrz06li">
					<img src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/soar/gxrz_fwys_01.png">
					<div class="t">定制化方案</div>
					<div class="b">资质诊断，以现状条件进行系统化分析，针对企业问题给出方案</div>
				</div>
				<div class="gxrz06li">
					<img src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/soar/gxrz_fwys_02.png">
					<div class="t">实战经验丰富</div>
					<div class="b">十年专注高新企业认定，服务上千家高新企业</div>
				</div>
				<div class="gxrz06li">
					<img src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/soar/gxrz_fwys_03.png">
					<div class="t">服务可视，过程可控</div>
					<div class="b">实时查看工作台项目进度</div>
				</div>
				<div class="gxrz06li">
					<img src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/soar/gxrz_fwys_04.png">
					<div class="t">分析全面</div>
					<div class="b">全面解读评分规则，大数据评估分析，专人专组协助申报，服务进度公开透明</div>
				</div>
				<div class="gxrz06li">
					<img src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/soar/gxrz_fwys_05.png">
					<div class="t">通过率高</div>
					<div class="b">通过率高达90%，无把握不接单</div>
				</div>
				<div class="gxrz06li">
					<img src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/soar/gxrz_fwys_06.png">
					<div class="t">专业服务</div>
					<div class="b">为农业、造纸及纸制品、印刷业、橡胶制品陶瓷制品、软件业、服务业等行业企业申报高新技术企业</div>
				</div>
			</div>
			<div id="gxrz07">
				<div class="gxrz07 wid1200">
					<div class="neit">
						<span>问题及解答</span>
					</div>
					<div class="neib"></div>
					<div class="neiline"></div>
					<div class="onequsetion">
						<div class="bigt">申请不通过的原因</div>
						<div class="onequsetionli">
							<span>01</span>
							<p>材料准备不充分，缺少核心材料</p>
						</div>
						<div class="onequsetionli">
							<span>02</span>
							<p>政策理解不透，错误准备</p>
						</div>
						<div class="onequsetionli">
							<span>03</span>
							<p>兼职负责，缺少长期持续跟进</p>
						</div>
						<div class="onequsetionli">
							<span>04</span>
							<p>没找到合作伙伴，非专业机构</p>
						</div>
						<div class="onequsetionli">
							<span>05</span>
							<p>实战经验不足，分析不准确</p>
						</div>

					</div>
					<div class="qusetions">
						<div class="bigt">高新企业认定申请中的困惑</div>
						<div class="index_news_list01">
							<div class="t">
								<span>Q</span>达不到高新技术企业认定申报的条件怎么办？
							</div>
							<div class="b">
								<span>A</span>硬性指标达不到一实知产建议先培育，软性条件可通过后补资料完成
							</div>
						</div>
						<div class="index_news_list01">
							<div class="t">
								<span>Q</span>应找哪家合作单位帮我一次性通过高新认定？
							</div>
							<div class="b">
								<span>A</span>选择一实知产，专人专组负责，进度可控，透视每个环节
							</div>
						</div>
						<div class="index_news_list01">
							<div class="t">
								<span>Q</span>通过高新企业认证还能享受政府的哪些扶持？
							</div>
							<div class="b">
								<span>A</span>享受最高35万元上海市、区扶持资金，另有上千万科技补贴
							</div>
						</div>
						<div class="index_news_list01">
							<div class="t">
								<span>Q</span>通过高新企业认证还能享受政府的哪些扶持？
							</div>
							<div class="b">
								<span>A</span>各级政府部门上百个项目申报的条件、流程、审批。优先纳入政府采购体系，投标展览的有力资质证明
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="gxrz08">
				<div class="t">相关新闻</div>
				<div class="newsdivli">
					<div class="newsli" v-for="(item, index) in news.list" :key="index" @click="detail(item.id)">
						<span>· </span>{{ item.title }}<span>{{ item.created_at }}</span>
					</div>
				</div>
			</div>
			<div id="sendinfo">
				<div class="banxin">
					<span>不确定企业是否可以申报政府补贴？</span>
					<a href="http://p.qiao.baidu.com/cps/chat?siteId=11939436&userId=25487969&siteToken=bce64c50ca792b2d58d2bb5861832e09"
						class="xmsb">在线咨询</a>
					<a href="http://p.qiao.baidu.com/cps/chat?siteId=11939436&userId=25487969&siteToken=bce64c50ca792b2d58d2bb5861832e09"
						class="mfpg">免费评估</a>
				</div>
				<div class="totop">
					<img src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/soar/totop.png" @click="toTop">
				</div>
			</div>
		</div>
		<div ref="swt"></div>
	</div>

</template>

<script>
export default {
	name: 'SoarSoar',
	metaInfo: {
		title: '高新技术企业认定，税收最高减免80%，最高可获得200万补助',
		meta: [{
			name: 'keywords',
			content: '技术对接,技术交易,技术对接平台,技术创新服务,技术转移,成果转化,技术推广,解决方案'
		},
		{
			name: 'description',
			content: '科技牛是一家专业为企业提供四技服务:技术开发、技术转让、技术咨询、技术服务，精准匹配科研专家及技术解决方案平台公司'
		}
		]
	},
	data() {
		return {
			news: {
				list: [],
				count: 0,
			},
		}
	},
	components: {
		// 'remote-js': {
		// 	render(createElement) {
		// 		return createElement('script', {
		// 			attrs: {
		// 				type: 'text/javascript',
		// 				src: this.src
		// 			}
		// 		});
		// 	},
		// 	props: {
		// 		src: {
		// 			type: String,
		// 			required: true
		// 		},
		// 	},
		// },
	},
	mounted() {
		window.addEventListener("scroll", this.handleScroll, true);
		this.get_list();
		this.server();
		// var oMeta = document.createElement('title');
		// oMeta.innerText = '一实知产高企申报，一站式高企申报服务商'
		// document.getElementsByTagName('head')[0].appendChild(oMeta);
		this._isMobile()
		if (this._isMobile()) {
			// alert("手机端");
			this.$router.replace('/move');
		}
	},
	methods: {
		_isMobile() {
			let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
			return flag;
		},
		get_list() {
			this.$http_qm.post('news/list', {
				limit: 4,
				page: 1,
			}).then(res => {
				if (res.code == 200) {
					this.news.list = res.data;
					this.news.count = res.count;
				}
			}).catch(err => {
				console.log(err)
			})
		},
		detail(id) {
			this.$router.push({
				path: '/news/detail',
				query: {
					id: id
				}
			})
		},
		handleScroll() {
			let scrolltop = document.documentElement.scrollTop || document.body.scrollTop;
			scrolltop > 30 ? (this.gotop = true) : (this.gotop = false);
		},
		toTop() {
			let top = document.documentElement.scrollTop || document.body.scrollTop;
			// 实现滚动效果 
			const timeTop = setInterval(() => {
				document.body.scrollTop = document.documentElement.scrollTop = top -= 50;
				if (top <= 0) {
					clearInterval(timeTop);
				}
			}, 10);
		},
		server() {


		}
	},
	destroyed() {
		this.server()
	}
}
</script>

<style lang="less">
#soar {
	padding: 0;
	margin: 0;

	.warp {
		.bannernei {
			height: 471px;
			margin: 0 auto;
			overflow: hidden;
			position: relative;
			width: 100%;
			z-index: 4;

			img {
				height: 471px;
				left: 50%;
				margin-left: -960px;
				overflow: hidden;
				position: absolute;
				width: 1920px;
				z-index: 101;
			}
		}

		#gxrz01 {
			background: #f8f8f8;

			.gxrz01 {
				overflow: hidden;
				padding: 70px 0 40px;
				width: 1230px;
				margin: 0 auto;

				.neit {
					font-size: 32px;
					color: #e1131e;
					text-align: center;
					font-weight: bold;
					margin-bottom: 25px;

					span {
						display: inline-block;
						position: relative;
					}
				}

				.neib {
					font-size: 18px;
					color: #666;
					font-family: Arial;
					text-align: center;
					margin-bottom: 20px;
				}

				.neiline {
					width: 50px;
					height: 3px;
					margin: 0 auto;
					background: #adadad;
					margin-bottom: 40px;
				}

				.gxrz01_div {
					width: 1230px;
					margin: 0 auto;

					.transition05 {
						transition: all 0.5s ease 0s;
						-moz-transition: all 0.5s ease 0s;
						-webkit-transition: all 0.5s ease 0s;
						-o-transition: all 0.5s ease 0s;
					}

					.gxrz01_div_01 {
						width: 33.333%;
						box-sizing: border-box;
						padding: 0 15px;
						float: left;

						.gxrz01_div_list {
							width: 100%;
							background: #fff;
							height: 240px;
							box-sizing: border-box;
							padding-top: 60px;
							margin-bottom: 30px;

							&:hover {
								background: #e1131e;
								padding-top: 30px;

								.img {
									.gxrdhover {
										display: inline;
									}

									.gxrd {
										display: none;
									}
								}

								.t {
									color: #fff;
								}

								.b {
									color: #f9dedf;
									opacity: 1;
								}
							}

							.img {
								text-align: center;
								margin-bottom: 24px;

								.gxrd {
									display: inline;
								}

								.gxrdhover {
									display: none;
								}
							}

							.t {
								text-align: center;
								font-size: 22px;
								color: #444;
								font-weight: bold;
								margin-bottom: 16px;
							}

							.b {
								text-align: center;
								font-size: 16px;
								color: #666;
								width: 87%;
								margin: 0 auto;
								line-height: 24px;
								opacity: 0;
							}
						}
					}

					.gxrz01_div_02 {
						.gxrz01_div_list {
							height: 510px;
							padding-top: 180px;
						}
					}

					.gxrz01_div_03 {
						width: 50%;
						box-sizing: border-box;
						padding: 0 15px;
						float: left;

						.gxrz01_div_list {
							width: 100%;
							background: #fff;
							height: 240px;
							box-sizing: border-box;
							padding-top: 94px;
							margin-bottom: 30px;

							&:hover {
								background: #e1131e;
								padding-top: 30px;

								.img {
									.gxrdhover {
										display: inline;
									}

									.gxrd {
										display: none;
									}
								}

								.t {
									color: #fff;
								}

								.b {
									color: #f9dedf;
									opacity: 1;
								}
							}

							.img {
								float: left;
								margin: 0 28px 0 145px;

								.gxrd {
									display: inline;
								}

								.gxrdhover {
									display: none;
								}
							}

							.t {
								float: left;
								font-size: 22px;
								color: #444;
								font-weight: bold;
								margin-bottom: 16px;
								line-height: 70px;
							}

							.b {
								clear: both;
								text-align: center;
								font-size: 16px;
								color: #666;
								width: 87%;
								margin: 0 auto;
								line-height: 24px;
								opacity: 0;
							}
						}
					}
				}
			}
		}

		.gxrz02 {
			overflow: hidden;
			padding: 70px 0;
			width: 1200px;
			margin: 0 auto;

			.neit {
				font-size: 32px;
				color: #e1131e;
				text-align: center;
				font-weight: bold;
				margin-bottom: 25px;

				span {
					display: inline-block;
					position: relative;
				}
			}

			.neib {
				font-size: 18px;
				color: #666;
				font-family: Arial;
				text-align: center;
				margin-bottom: 20px;
			}

			.neiline {
				width: 50px;
				height: 3px;
				margin: 0 auto;
				background: #adadad;
				margin-bottom: 40px;
			}

			.transition05 {
				transition: all 0.5s ease 0s;
				-moz-transition: all 0.5s ease 0s;
				-webkit-transition: all 0.5s ease 0s;
				-o-transition: all 0.5s ease 0s;
			}

			.gxrz02_li {
				width: 270px;
				height: 340px;
				float: left;
				position: relative;
				margin-right: 40px;

				&:hover {
					.text {
						bottom: 0px;
						height: 100%;

						.t {
							display: block;
							font-size: 24px;
							padding-top: 80px;
						}

						.b {
							font-size: 16px;
							margin-bottom: 30px;
							line-height: 24px;
							padding: 0 5px;
							box-sizing: border-box;
							color: rgba(255, 255, 255, 0.8);
							display: block;
						}

						.d {
							display: block;

							img {
								width: 19%;
								margin: 0 auto;
							}
						}
					}
				}

				&:last-child {
					margin-right: 0;
				}

				img {
					display: block;
				}

				.text {
					width: 100%;
					height: 60px;
					bottom: 0px;
					text-align: center;
					background: #e1131e;
					position: absolute;
					overflow: hidden;

					.t {
						font-size: 16px;
						color: #fff;
						line-height: 60px;
					}

					.b {
						font-size: 16px;
						color: #fff;
						display: none;
						margin-bottom: 30px;
						line-height: 24px;
						padding: 0 5px;
						box-sizing: border-box;
					}

					.d {
						display: none;
					}
				}
			}
		}

		.midbanner {
			height: 400px;
			margin: 0 auto;
			overflow: hidden;
			position: relative;
			width: 100%;

			img {
				height: 400px;
				left: 50%;
				margin-left: -960px;
				overflow: hidden;
				position: absolute;
				width: 1920px;
				z-index: 101;
			}
		}

		#gxrz03 {
			background: #f8f8f8;

			.gxrz03 {
				overflow: hidden;
				padding: 70px 0;
				width: 1200px;
				margin: 0 auto;
				text-align: center;

				.neit {
					font-size: 32px;
					color: #e1131e;
					text-align: center;
					font-weight: bold;
					margin-bottom: 25px;

					span {
						display: inline-block;
						position: relative;
					}

					p {
						font-size: 16px;
						color: #828282;
						margin-top: 20px;
					}
				}

				.transition05 {
					transition: all 0.5s ease 0s;
					-moz-transition: all 0.5s ease 0s;
					-webkit-transition: all 0.5s ease 0s;
					-o-transition: all 0.5s ease 0s;
				}

				.gxrz03_div {
					width: 100%;
					overflow: hidden;
					padding-bottom: 5px;
					position: relative;
					padding-top: 30px;
					height: auto;

					.gxrz03_div_li_wai {
						clear: both;
						position: relative;
						z-index: 1;

						.gxrz03_div_li {
							width: 100%;
							height: 130px;

							.l {
								float: left;
								text-align: center;
								background: #666666;
								cursor: pointer;

								img {
									border: 0;
								}
							}

							.text {
								float: left;
								background: #fff;
								height: 130px;
								width: 430px;
								box-sizing: border-box;
								padding: 20px 32px 0;
								cursor: pointer;
								box-shadow: 5px 5px 5px #f3f3f3;
								position: relative;

								.t {
									margin-bottom: 10px;
									font-size: 18px;
									color: #333;
									text-align: left;
									font-weight: bold;
								}

								.b {
									font-size: 14px;
									color: #828282;
									line-height: 24px;
									text-align: left;
								}
							}

							.p {
								width: 7px;
								height: 7px;
								background: #666;
								border-radius: 7px;
								float: left;
								margin-top: 60px;
								margin-left: 37px;
							}

							&:hover {
								.l {
									background: #e1131e;
								}

								.text {
									.t {
										color: #e1131e;
									}

									.b {
										color: #e1131e;
									}
								}

								.p {
									background: #e1131e;
								}
							}
						}

						&:nth-child(2n) {
							.gxrz03_div_li {
								.l {
									float: right;
								}

								.text {
									float: right;
									box-shadow: -5px 5px 5px #f3f3f3;
								}

								.p {
									float: right;
									margin-right: 36px;
									margin-left: 0px;
								}
							}
						}
					}

					&:after {
						position: absolute;
						width: 1px;
						height: 100%;
						background: #dddddd;
						left: 50%;
						top: 7px;
						content: " ";
						z-index: 0;
					}
				}
			}
		}

		.gxrz04 {
			overflow: hidden;
			padding: 70px 10px;
			width: 1200px;
			margin: 0 auto;

			.neit {
				font-size: 32px;
				color: #e1131e;
				text-align: center;
				font-weight: bold;
				margin-bottom: 25px;

				span {
					display: inline-block;
					position: relative;
				}
			}

			.neib {
				font-size: 18px;
				color: #666;
				font-family: Arial;
				text-align: center;
				margin-bottom: 20px;
			}

			.neiline {
				width: 50px;
				height: 3px;
				margin: 0 auto;
				background: #adadad;
				margin-bottom: 40px;
			}

			.transition05 {
				transition: all 0.5s ease 0s;
				-moz-transition: all 0.5s ease 0s;
				-webkit-transition: all 0.5s ease 0s;
				-o-transition: all 0.5s ease 0s;
			}

			.gxrz04_li {
				width: 380px;
				height: 480px;
				float: left;
				position: relative;
				margin-right: 30px;

				img {
					display: block;
				}

				.text {
					width: 100%;
					height: 60px;
					bottom: 0px;
					text-align: center;
					background: #fff;
					position: absolute;
					overflow: hidden;
					box-shadow: 5px 5px 5px #f3f3f3;

					.t {
						font-size: 20px;
						color: #555;
						line-height: 60px;
						font-weight: bold;

						span {
							display: inline-block;
							position: relative;
						}
					}

					.b {
						font-size: 14px;
						color: #fff;
						display: none;
						line-height: 24px;
						padding: 0 5px;
						box-sizing: border-box;
						text-align: center;
					}
				}

				&:last-child {
					margin-right: 0;
				}

				&:hover {
					.text {
						bottom: 0px;
						height: 160px;
						background: #e1131e;

						.t {
							display: block;
							font-size: 20px;
							color: #fff;
						}

						.b {
							color: rgba(255, 255, 255, 0.8);
							display: block;
						}
					}
				}
			}
		}

		#gxrz05 {
			background: url(https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/soar/gxrzmidbg.jpg) top center no-repeat;

			.gxrz05 {
				overflow: hidden;
				padding: 70px 0px 40px;
				width: 1200px;
				margin: 0 auto;

				.neit {
					font-size: 32px;
					color: #fff;
					text-align: center;
					font-weight: bold;
					margin-bottom: 25px;

					span {
						display: inline-block;
						position: relative;
					}
				}

				.neib {
					font-size: 18px;
					color: #fff;
					font-family: Arial;
					text-align: center;
					margin-bottom: 20px;
				}

				.neiline {
					width: 50px;
					height: 3px;
					margin: 0 auto;
					background: #adadad;
					margin-bottom: 40px;
				}

				.transition05 {
					transition: all 0.5s ease 0s;
					-moz-transition: all 0.5s ease 0s;
					-webkit-transition: all 0.5s ease 0s;
					-o-transition: all 0.5s ease 0s;
				}

				.gxrz05li {
					width: 270px;
					height: 180px;
					text-align: center;
					background: #fff;
					margin-right: 40px;
					margin-bottom: 40px;
					float: left;
					border-top-right-radius: 20px;
					border-bottom-left-radius: 20px;

					.now {
						display: inline-block;
						max-width: 100%;
						margin-bottom: 30px;
						margin-top: 30px;
					}

					.hover {
						display: none;
						max-width: 100%;
						margin-bottom: 30px;
						margin-top: 30px;
					}

					.t {
						font-size: 18px;
						color: #333;

						span {
							display: inline-block;
							position: relative;

							&:before {
								position: absolute;
								content: " ";
								background: #b6b6b6;
								width: 20px;
								height: 2px;
								top: 10px;
								left: -34px;
							}

							&:after {
								position: absolute;
								content: " ";
								background: #b6b6b6;
								width: 20px;
								height: 2px;
								top: 10px;
								right: -34px;
							}
						}
					}

					&:nth-child(4n+7) {
						margin-right: 0px;
					}

					&:hover {
						background: #e1131e;

						.now {
							display: none;
						}

						.hover {
							display: inline-block;
						}

						.t {
							span {
								display: inline-block;
								position: relative;
								color: #fff;

								&:after {
									background: #fff;
								}

								&:before {
									background: #fff;
								}
							}
						}
					}
				}
			}
		}

		.gxrz06 {
			overflow: hidden;
			padding: 70px 0;
			width: 1200px;
			margin: 0 auto;
			text-align: center;

			.neit {
				font-size: 32px;
				color: #e1131e;
				text-align: center;
				font-weight: bold;
				margin-bottom: 25px;

				span {
					display: inline-block;
					position: relative;
				}

				p {
					font-size: 16px;
					color: #828282;
					margin-top: 20px;
				}
			}

			.gxrz06li {
				width: 360px;
				height: 220px;
				text-align: center;
				border: 1px solid #e2e2e2;
				box-sizing: border-box;
				padding: 37px 40px 0;
				float: left;
				margin-right: 60px;
				margin-bottom: 40px;

				img {
					max-width: 100%;
				}

				.t {
					font-size: 20px;
					color: #444;
					font-weight: bold;
					margin: 20px 0 15px;
				}

				.b {
					font-size: 14px;
					color: #666;
					line-height: 24px;
				}

				&:nth-child(3n+4) {
					margin-right: 0px;
				}
			}
		}

		#gxrz07 {
			background: #f8f8f8;

			.gxrz07 {
				overflow: hidden;
				padding: 70px 0;
				width: 1200px;
				margin: 0 auto;
				text-align: left;

				.neit {
					font-size: 32px;
					color: #e1131e;
					text-align: center;
					font-weight: bold;
					margin-bottom: 25px;

					span {
						display: inline-block;
						position: relative;
					}
				}

				.neib {
					font-size: 18px;
					color: #666;
					font-family: Arial;
					text-align: center;
					margin-bottom: 20px;
				}

				.neiline {
					width: 50px;
					height: 3px;
					margin: 0 auto;
					background: #adadad;
					margin-bottom: 40px;
				}

				.onequsetion {
					width: 574px;
					float: left;

					.bigt {
						font-size: 24px;
						color: #e1131e;
						margin-bottom: 30px;
					}

					.onequsetionli {
						padding: 25px 0;
						border-top: 1px dashed #d8d8d8;
						border-bottom: 1px dashed #d8d8d8;
						overflow: hidden;

						span {
							width: 45px;
							background: #e1131e;
							height: 45px;
							border-radius: 45px;
							line-height: 45px;
							text-align: center;
							font-size: 18px;
							color: #ffffff;
							font-family: Arial;
							float: left;
						}

						p {
							font-size: 18px;
							color: #333333;
							line-height: 45px;
							margin-left: 20px;
							float: left;
						}
					}
				}

				.qusetions {
					width: 575px;
					float: right;

					.bigt {
						font-size: 24px;
						color: #e1131e;
						margin-bottom: 30px;
					}

					.index_news_list01 {
						width: 575px;
						display: block;
						overflow: hidden;
						margin-top: 20px;
						padding: 17px 0 16px 20px;
						border: 1px solid #d8d8d8;
						box-sizing: border-box;

						.t {
							font-size: 16px;
							color: #333;
							line-height: 25px;
							margin-top: 0px;
							text-align: left;
							margin-bottom: 14px;

							span {
								font-size: 16px;
								text-align: center;
								font-family: Arial;
								color: #fff;
								background: #e1131e;
								display: inline-block;
								width: 25px;
								height: 25px;
								margin-right: 20px;
							}
						}

						.b {
							font-size: 14px;
							color: #666;
							line-height: 25px;
							margin-top: 10px;
							text-align: left;

							span {
								font-size: 16px;
								text-align: center;
								font-family: Arial;
								color: #fff;
								background: #edb801;
								display: inline-block;
								width: 25px;
								height: 25px;
								margin-right: 20px;
							}
						}
					}
				}
			}
		}

		.gxrz08 {
			overflow: hidden;
			padding: 70px 0 0;
			width: 1200px;
			margin: 0 auto;
			text-align: left;

			.t {
				width: 100%;
				height: 24px;
				line-height: 24px;
				border-left: 3px solid #df241d;
				box-sizing: border-box;
				font-size: 18px;
				color: #333;
				padding-left: 15px;
				text-align: left;
				margin-bottom: 30px;
			}

			.newsdivli {
				width: 1200px;
				float: left;

				.newsli {
					cursor: pointer;
					font-size: 14px;
					color: #666;
					display: block;
					margin-bottom: 28px;
					height: 20px;
					line-height: 20px;
					width: 575px;
					float: left;

					span:first-child {
						color: #cecece;
						font-size: 20px;
					}

					span:last-child {
						font-size: 12px;
						color: #999;
						float: right;
					}

					&:nth-child(2n) {
						float: right;
					}

					&:hover {
						color: #e1131e;
					}
				}
			}
		}

		#sendinfo {
			width: 100%;
			height: 100px;
			background: #4261be;
			position: relative;

			.banxin {
				position: relative;
				height: 100px;
				text-align: center;
				line-height: 100px;
				font-size: 0px;

				span {
					font-size: 30px;
					color: #fff;
				}

				.xmsb {
					font-size: 22px;
					color: #000;
					background: #fff;
					padding: 12px 40px;
					border-radius: 100px;
					margin-left: 36px;
				}

				.mfpg {
					font-size: 22px;
					color: #000;
					background: #fff;
					padding: 12px 40px;
					border-radius: 100px;
					margin-left: 36px;
				}
			}

			.totop {
				position: absolute;
				left: 50%;
				margin-left: -25px;
				bottom: 0px;

				img {
					border: 0;
				}
			}
		}
	}
}
</style>
