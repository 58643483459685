<template>
	<div class="top" :class="scrollTop ? 'box-active':''">
		<div class="hd">
			<div class="normal_div">
				<router-link to="/">
					<span class="homepage">欢迎来到科技牛官方网站！</span>
				</router-link>

				<img class="icon" src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/other/index_16.jpg" />
				<span class="location" v-if="city">{{city}}</span>
				<span class="location" v-if="province">{{province}}</span>
				<span class="location" v-if="city === null && province === null">全国</span>
				<span class="locationNext" @click="href_city('/change_city')">[切换城市]</span>
			</div>

			<div class="desc">
				<ul>
					<el-dropdown v-if="user&&user.username">
						<span class="el-dropdown-link">
							{{user.username}}<i class="el-icon-arrow-down el-icon--right"></i>
						</span>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item icon="el-icon-user" @click.native="href('/user_center/zc_message')">我的科技牛
							</el-dropdown-item>
							<!-- <el-dropdown-item icon="el-icon-setting" @click.native="href('/setting/editbaseinfo')">账号设置</el-dropdown-item> -->
							<el-dropdown-item icon="el-icon-turn-off" @click.native="Logout">退出</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>

					<li v-else v-for="(n,s) in nav1" @click="login(n.to)" :key="'nav1'+s">{{n.name}}</li>

					<li v-for="(v,k) in nav2" @click="href(v.to)" :class="{'active':v.active}" :key="k">{{v.name}}</li>
					<el-popover placement="bottom" trigger="click">
						<div style="text-align: center; margin: 0">
							<img src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/other/server.jpg" width="100"
								height="100" /><br>
							<span>扫码联系客服</span>
						</div>
						<li slot="reference">客服中心</li>
					</el-popover>
					<el-popover placement="bottom" trigger="click">
						<div style="text-align: center; margin: 0">
							<img src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/other/index1.jpg" width="100"
								height="100" /><br>
							<span>扫码下载</span>
						</div>
						<li slot="reference">科技牛APP</li>
					</el-popover>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		mapGetters,
		mapMutations,
		mapState
	} from 'vuex'
	export default {
		props: {
			path: {
				default: "",
				type: String,
			}
		},
		data() {
			return {
				nav1: [{
					name: '登录',
					to: '/login'
				}, {
					name: '注册',
					to: '/register'
				}],
				nav2: [ {
					name: '企业入驻',
					to: '/company/qy_message',
					active: false,
				}, {
					name: '专家入驻',
					to: '/expert/add',
					active: false,
				}, {
					name: '大数据平台',
					to: '/data',
					active: false,
				}],
				scroll: '',
				scrollTop: false,
			}
		},
		computed: {
			...mapGetters(['user', 'province', 'city']),
			...mapState(['token'])
		},
		watch: {
			$route() {
				this.nav2_active();
			}
		},
		mounted() {
			this.nav2_active();
			// const city_ = localStorage.getItem('city')

			// const province = localStorage.getItem('province')
			// console.log(city_,province)
			window.addEventListener('scroll', this.menu)


		},
		methods: {
			...mapMutations(['setLogout']),
			Logout() {
				this.setLogout();
				this.$router.push({
					path: '/login'
				})
			},
			nav2_active() {
				let path = this.$route.path
				this.nav2.forEach(function(v) {
					if (v.to == path) {
						v.active = true
					} else {
						v.active = false
					}
				})
			},

			menu() {
				this.scroll = document.documentElement.scrollTop || document.body.scrollTop;
				if (this.scroll > 100) {
					this.scrollTop = true;
				} else {
					this.scrollTop = false;
				}
			},
			login(url) {
				this.$router.push({
					path: url,
					query: {}
				})
			},
			href(url) {
				const token = localStorage.getItem('token')
				if (url == '/service'||url == '/service/government') {
					this.$router.push({
						path: url,
						query: {}
					})
				} else {
					if (!token) {
						this.$router.push({
							name: 'login'
						})
					} else {
						this.$router.push({
							path: url,
							query: {}
						})
					}
				}


			},
			href_city(url) {
				this.$router.push({
					path: url,
					query: {}
				})
			}
		}
	}
</script>

<style>
</style>
