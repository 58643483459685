<template>
	<div id="cp_admin">
		<div class="wrapper">
			<div class="main_left">
				<Left></Left>
			</div>
			<div class="main_right">
				<!-- <div class="notice" v-show="notice">
					尊敬的用户：<br>您好，很抱歉，您提交的企业信息 <b style="color:red">未通过审核</b>，原因是：<b style="color:red">其他原因拒审</b>
					<br>请修改企业信息后，重新提交审核 <br>您可以发布产品信息，但只能自己可见，待企业审核通过后才可正式上线。<br>如有疑问，请咨询客服热线：400-650-1997，
					<span class="onlineqq" @click="toUrl('/company/qy_message')">点击修改企业信息</span>
					<div class="close" @click="notice = false">
						<img src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/other/close_bt.gif" width="12" height="10">
					</div>
				</div> -->

				<div class="page">
					<!-- 	<div class="quanxian-tip ">
						<div class="clearfix">
							<div class="words">您的产品发布权限为
								<span class="count'">107</span>条，已发布
								<span class="count">7</span>条，还可发布
								<span class="count">100</span>条。如需发布更多产品,请
								<span class="word-light-blue">购买发布权限</span>。
							</div>
						</div>
					</div> -->
					<div class="page_nav">
						<div class="page_nav_tit"> 产品管理</div>
						<!-- <div class="page_nav_tit_desc">
							您已发布的产品总数为： <span class="highlight">7</span> 条
						</div> -->
					</div>
					<div class="page_list">
						<el-tabs type="card" @tab-click="handleClick">
							<el-tab-pane :label="item.title" v-for="(item, index) in dataList" :key="index">
								<div class="listbox">
									<el-form :inline="true" :model="formInline" class="demo-form-inline"
										v-if="index == 0">
										<el-form-item label="搜索:">
											<el-input size="small" v-model="product_post.keyword"
												placeholder="请输入关键词"></el-input>
										</el-form-item>
										<el-form-item label="发布时间">
											<el-select v-model="create_time" @change="create_time_change()"
												placeholder="发布时间">
												<el-option label="全部" value="all"></el-option>
												<el-option label="当天" value="当天"></el-option>
												<el-option label="一周内" value="一周内"></el-option>
											</el-select>
										</el-form-item>
										<el-form-item>
											<el-button size="small" type="primary" @click="onSubmit">查询</el-button>
										</el-form-item>
									</el-form>

									<el-table ref="multipleTable" :data="product.list" tooltip-effect="dark"
										style="width: 100%" @selection-change="handleSelectionChange">
										<el-table-column type="selection" width="55"></el-table-column>
										<el-table-column label="产品图片" width="120">
											<template slot-scope="scope">
												<img v-if="scope.row.cover != null && scope.row.cover.length > 0"
													:src="scope.row.cover[0]" class="product_img" height="75" width="93"
													alt="正在加载..">

											</template>
										</el-table-column>
										<el-table-column label="产品信息" show-overflow-tooltip>
											<template slot-scope="scope">
												<span class="product_title">{{ scope.row.title }}</span>
												<!-- <span class="product_classes">分类：<b v-if="scope.row.cate">{{scope.row.cate.two_name}}</b></span> -->
												<span class="product_data">更新时间：{{ scope.row.updated_at }}</span>
											</template>
										</el-table-column>
										<el-table-column align="center" label="操作">
											<template slot-scope="scope">

												<el-button size="mini" type="info"
													@click="handleEdit(scope.$index, scope.row.id)">编辑</el-button>
												<el-button size="mini" type="warning"
													@click="handleAedit(scope.$index, scope.row.id)">重发</el-button>
												<template>
													<el-popconfirm @confirm="handleDelete(scope.$index, scope.row.id)"
														title="确定删除吗？">
														<el-button style="margin-left: 10px;" size="mini"
															slot="reference" type="danger">删除</el-button>
													</el-popconfirm>
												</template>

											</template>
										</el-table-column>
									</el-table>
									<div style="margin-top: 20px">
										<el-button size="small" @click="toggleSelection()">删除</el-button>
										<el-button size="small" @click="toggleSelection()">取消选择</el-button>
									</div>
									<div class="product_page">
										<el-pagination @size-change="handleSizeChange"
											@current-change="handleCurrentChange" :current-page.sync="currentPage1"
											:page-size="5" layout="total, prev, pager, next" :total="product.count">
										</el-pagination>
									</div>
								</div>
							</el-tab-pane>
						</el-tabs>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Left from "./Left.vue"
import {
	mapGetters
} from 'vuex'
export default {
	name:'CompanyCpAdmin',
	metaInfo: {
		title: '科技牛—为企业精准匹配科研专家以及提供技术解决方案的服务平台',
		meta: [{
			name: 'keywords',
			content: '技术对接,技术交易,技术对接平台,技术创新服务,技术转移,成果转化,技术推广,解决方案'
		},
		{
			name: 'description',
			content: '科技牛是一家专业为企业提供四技服务:技术开发、技术转让、技术咨询、技术服务，精准匹配科研专家及技术解决方案平台公司'
		}
		]
	},
	components: {
		Left
	},
	data() {
		return {
			notice: true,
			dataList: [{
				title: '已上线',
				content: '123',
				status: 1,
			}, {
				title: '已下线',
				content: '456',
				status: 3
			}, {
				title: '审核中',
				content: '789',
				status: 0,
			}, {
				title: '未通过',
				content: '999',
				status: 2
			},],
			formInline: {
				user: '',
				region: ''
			},
			multipleSelection: [],
			currentPage1: 1,
			product_post: {
				limit: 5,
				page: 1,
				status: 1,
				keyword: '',
				start_time: '',
				end_time: '',
				uid: '',
			},
			product: {
				list: [],
				count: 0,
			},
			create_time: '',
			tab_index: 0,
		}
	},
	mounted() {
		this.get_list();
	},
	computed: {
		...mapGetters(['user'])
	},
	methods: {
		create_time_change() {
			console.log(this.create_time);
			if (this.create_time == '当天') {
				var s = new Date();
				s.setHours(0);
				var e = new Date();
				e.setHours(24);
				this.product_post.start_time = Date.parse(s) / 1000;
				this.product_post.end_time = Date.parse(e) / 1000;
			} else if (this.create_time == 'all') {
				this.product_post.start_time = '';
				this.product_post.end_time = '';
			} else {
				s = new Date();
				e = new Date();
				var day = e.getDate()
				e.setDate(day - 7);
				if (day - 7 < 0) {
					var month = e.getMonth()
					e.setMonth(month - 1)
					if (month - 1 < 0) {
						var year = e.getFullYear()
						e.setFullYear(year - 1)
					}
				}
				this.product_post.start_time = Date.parse(e) / 1000;
				this.product_post.end_time = Date.parse(s) / 1000;
			}

		},

		get_list() {
			this.product_post.start_time = this.product_post.start_time.toString()
			this.product_post.end_time = this.product_post.end_time.toString()
			this.product_post.status = this.product_post.status.toString()
			this.product_post.uid = this.user.id;
			this.$http_qm.post('product/list', this.product_post).then(res => {
				this.product.list = res.data;
				this.product.count = res.count;
			}).catch(err => {
				console.log(err)
			})
		},
		handleSizeChange(val) {
			console.log(`每页 ${val} 条`);
		},
		handleCurrentChange(val) {
			this.product_post.page = val;
			this.get_list();
		},
		toggleSelection(rows) {
			if (rows) {
				rows.forEach(row => {
					this.$refs.multipleTable[this.tab_index].toggleRowSelection(row);
				});
			} else {
				this.$refs.multipleTable[this.tab_index].clearSelection();
			}
		},
		handleSelectionChange(val) {
			console.log(val)
			this.multipleSelection = val;
		},
		// 编辑产品
		handleEdit(index, id) {
			this.$router.push({
				name: 'cpProcess',
				query: {
					id: id
				}
			})
		},
		handleAedit(index, id) {
			console.log(index, id);
			this.update(id, 'reset');
		},
		update(id, update_type) {
			this.$http_qm.post('product/update', {
				id: id,
				update_type: update_type,
			}).then(res => {
				console.log(res);
			}).catch(err => {
				console.log(err);
			})
		},
		// 删除c产品
		handleDelete(index, id) {

			this.$http_qm.post('product/delete', {
				id: id,
			}).then(res => {
				if (res.code == 200) {
					this.product.list.splice(index, 1);
				}
			}).catch(err => {
				console.log(err);
			})
		},
		onSubmit() {
			console.log('submit!');
			this.get_list()
		},
		handleClick(tab) {
			console.log(tab.index);
			this.tab_index = tab.index
			this.product_post.status = this.dataList[tab.index].status;
			this.get_list();
		}
	}
}
</script>

<!-- <style lang="less" src="../../assets/css/firm.less"></style> -->
<style lang="less">
#cp_admin {
	margin: 0;
	padding: 0;
	background: #e6e6e6;

	.wrapper {
		padding: 15px 0;
		width: 990px;
		margin: 0 auto;
		flex-direction: row;
		justify-content: space-between;
		display: flex;

		.main_left {
			width: 195px;
			height: auto;
			text-align: center;
		}

		.main_right {
			width: 755px;
			height: auto;
			min-height: 750px;
			float: right;
			background: #fff;
			border: 1px solid #e0e0e0;
			padding: 15px;

			.notice {
				margin-bottom: 20px;
				position: relative;
				padding: 12px;
				background: #ffefe8;
				color: #ed5f19;
				line-height: 26px;

				.onlineqq {
					color: #2a5db0;
					cursor: pointer;
					display: inline-block;
					vertical-align: middle;
				}

				.close {
					width: 12px;
					height: 10px;
					position: absolute;
					right: 12px;
					top: 5px;
				}
			}

			.page {
				padding: 0;

				.quanxian-tip {
					margin-bottom: 20px;
					line-height: 28px;
					padding: 10px;
					text-align: center;
					background: #fffae2;

					.word-light-blue {
						color: #456ec8 !important;
						cursor: pointer;
					}
				}

				.page_nav {
					padding: 0 15px;
					display: flex;
					border-bottom: 3px #2a5db0 solid;

					.page_nav_tit {
						float: left;
						font-size: 18px;
						font-family: "微软雅黑";
						cursor: pointer;
						margin-right: 20px;
					}

					.page_nav_tit_desc {
						float: left;
						line-height: 16px;
						font-size: 12px;
						margin: 7px 0 0 0;
					}
				}

				.page_list {
					margin-top: 10px;

					.listbox {
						text-align: left;

						.el-form-item {
							margin-bottom: 0px;
						}

						td {
							border: none;
						}

						.product_title {
							font-size: 12px;
							width: 123px;
							height: 25px;
							color: #2a5db0;
							display: block;
							cursor: pointer;
						}

						.product_title:hover {
							color: #db4f33;
							text-decoration: underline;
						}

						.product_classes {
							font-size: 12px;
							display: block;

							b {
								color: #2a5db0;
								cursor: pointer;
							}

							b:hover {
								color: #db4f33;
								text-decoration: underline;
							}
						}

						.product_data {
							font-size: 12px;
							display: block;
						}

						.product_page {
							text-align: center;
							padding: 10px 0 30px;
						}
					}
				}
			}
		}
	}
}
</style>
