<template>
	<div></div>
</template>

<script src="http://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js"></script>
<script>
	//好吧加下面这段话 直接就不能运行了
	/* var obj = new WxLogin({
		self_redirect: true,
		id: "login_container",
		appid: "",
		scope: "",
		redirect_uri: "",
		state: "",
		style: "",
		href: ""
	}); */
	export default {
		name:'CompanyWxLogin',
		data() {

		},
		mounted() {

		},
	}
</script>

<style>
</style>
