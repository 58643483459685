<template>
	<div id="achievementList">
		<Top :search_text="achievemnet_request.keyword" page_type="achievement"></Top>
		<div class="container">
			<!-- 面包宵 搜索-->
			<div class="column-search">
				<!-- 面包屑导航-->
				<el-breadcrumb separator-class="el-icon-arrow-right">
					<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item>成果列表</el-breadcrumb-item>
				</el-breadcrumb>
			</div>

			<!-- 搜索条件框框位-->

			<div class="search-listbox search-margin">
				<div class="sslist-ylxs" v-for="(v, k) in classify" :key="k">
					<strong>{{ v.title }}：</strong>
					<div class="sslist-ssnr">
						<el-radio-group v-model="radio[k]" size="small" @change="tagChange">
							<el-radio-button :label="s" :value="s" v-for="(s, y) in v.tags" :key="y"></el-radio-button>
						</el-radio-group>
					</div>
				</div>
			</div>
			<div class="clearfix">
				<div class="main">
					<div class="well-gray">共{{ achievemnet.count }}条数据</div>
					<!-- 列表-->
					<ul class="main-list">

						<li v-for="(l, u) in achievemnet.list" :key="u" @click="todetail(l.id)">
							<div class="a">
								<div class="jx-img fl">
									<el-image v-if="Array.isArray(l.img)" style="width: 150px; height: 100px"
										:src="l.img[0]" fit="cover"></el-image>
									<el-image v-else style="width: 150px; height: 100px" :src="l.img"
										fit="cover"></el-image>
								</div>
								<h4>{{ l.title }}<span class="jingxuan-icon">精选</span></h4>
								<div class="attribute">
									<span class="width-40">
										<i class="text-gray-light">技术成熟度：</i>应用市场
									</span>
									<span class="width-40">
										<i class="text-gray-light">合作方式：</i>{{ l.cooperation_type_mes }}
									</span>
									<span class="width-20 text-right">
										<i class="text-gray-light">价格：</i>
										<i class="text-orange">{{ l.reference_price }}</i>
									</span>
								</div>
								<p>
									<span class="text-gray-light brief">{{ l.xmhjsqk }}</span>
									<span class="text-gray-light fr">
										<i class="el-icon-location-information"></i>上海
									</span>
								</p>
							</div>
						</li>

					</ul>
					<!-- 分页-->
					<!-- 分页-->
					<div class="page">
						<el-pagination @current-change="handleCurrentChange" layout="prev, pager, next"
							:total="achievemnet.count" background :page-size="achievemnet_request.limit"
							:current-page="currentPage"></el-pagination>
					</div>
				</div>
				
			</div>
		</div>
		<flexRight></flexRight>
	</div>
</template>

<script>
import Top from "@/components/top.vue"
import flexRight from "@/components/flexRight.vue"
export default {
	name: 'AchievementIndex',
	components: {
		Top, flexRight
	},
	metaInfo: {
		title: '科技牛—为企业精准匹配科研专家以及提供技术解决方案的服务平台',
		meta: [{
			name: 'keywords',
			content: '技术对接,技术交易,技术对接平台,技术创新服务,技术转移,成果转化,技术推广,解决方案'
		},
		{
			name: 'description',
			content: '科技牛是一家专业为企业提供四技服务:技术开发、技术转让、技术咨询、技术服务，精准匹配科研专家及技术解决方案平台公司'
		}
		]
	},
	data() {
		return {
			radio: ['不限', '不限', '不限'],
			classify: [{
				title: '产业领域',
				tags: ['不限']
			},
			/* 	{
				title: '技术类型',
				tags: ['不限', '专利', '非专利', '发明专利', '外观专利', '实用新型专利', '软件著作权']
			}, */
			{
				title: '合作方式',
				tags: ['不限', '完全转让', '许可转让', '技术入股']
			},
			{
				title: '成熟度',
				tags: ['不限', '研发阶段', '已有样品', '批量生产', '应用市场']
			},
			],
			hotTag: ['日常用品', '电子信息', '资源利用与开发', '建筑材料', '休闲娱乐', '食品加工', '新能源', '中药', '天然药物', '控制系统', '通信', '汽车', '建筑装饰', '太阳能',
				'电气电工', '钒钛新材料'
			],
			achievemnet_request: {
				limit: 10,
				page: 1,
				cooperation_type: '', //合作方式
				industrial: '',
				maturity: '', //成熟度
			},
			currentPage: 1,
			achievemnet: {
				list: [],
				count: 0,
			},
		}
	},
	watch: {
		$route() {
			let query = this.$route.query
			if (typeof (query.page) != 'undefined') {
				this.achievemnet_request.page = query.page
				this.currentPage = parseInt(query.page)
			} else {
				this.achievemnet_request.page = 1
				this.currentPage = 1
			}
			if (typeof (query.keyword) != 'undefined') {
				this.achievemnet_request.keyword = query.keyword
				this.currentPage = 1
			}
			this.demendList();
		},
	},
	created() {
		let query = this.$route.query
		if (typeof (query.page) != 'undefined') {
			this.achievemnet_request.page = query.page
			this.currentPage = parseInt(query.page)
		} else {
			this.achievemnet_request.page = 1
			this.currentPage = 1
		}
		if (typeof (query.keyword) != 'undefined') {
			this.achievemnet_request.keyword = query.keyword
			this.currentPage = 1
		}
	},
	mounted() {
		this.get_cate();
		this.demendList();
	},
	methods: {

		get_cate() {
			this.$http_qm.post('expert/cate', { type: 'industrial' }).then(res => {
				this.classify[0].tags = this.classify[0].tags.concat(res.data.industrial);
			}).catch(err => {
				console.log(err)
			})
		},
		async demendList() {
			await this.$http.post('achievement/list', this.achievemnet_request).then(res => {
				this.achievemnet.list = res.data;
				this.achievemnet.count = res.count;
			}).catch(err => {
				console.log(err);
			})
		},
		tagChange() {

			if (this.radio[0] == '不限') {
				this.achievemnet_request.industrial = ''
			} else {
				this.achievemnet_request.industrial = this.radio[0]
			}
			if (this.radio[1] == '不限') {
				this.achievemnet_request.cooperation_type = ''
			} else {
				this.achievemnet_request.cooperation_type = this.radio[1]
			}
			if (this.radio[2] == '不限') {
				this.achievemnet_request.maturity = ''
			} else {
				this.achievemnet_request.maturity = this.radio[2]
			}
			this.achievemnet_request.page = 1
			this.demendList();
		},
		handleCurrentChange(page) {
			this.$router.push({
				path: '/achievement',
				query: {
					page: page
				}
			})
			/* this.achievemnet_request.page = page
			this.demendList();
			document.querySelector('#app').scrollIntoView({
				behavior: "auto"
			}); */
		},
		todetail(id) {
			this.$router.push({
				path: '/achivement/detail',
				query: {
					id: id
				}
			})
		}
	}
}
</script>

<style lang="less" src="../../assets/css/achievement.less">