<template>
	<div class="change_city">

		<div class="city-filter" id="utopia_widget_3">
			<div class="filter-current">
				<span class="filter-title">当前城市</span>
				<div class="filter-content" data-linkid="zbjchangecity-current-backcurrent">
					<a class="current-city" @click="location_href()">进入{{ LocationProvince }}站</a>
				</div>
			</div>
			<div class="filter-hot">
				<span class="filter-title">热门城市</span>
				<div class="filter-content">
					<ul class="hot-list">

						<li class="hot-item" v-for="(v1, k1) in city.hot" :key="k1">
							<a @click="click_select_city(v1)" class="item-city">{{ v1 }}</a>
						</li>

					</ul>
				</div>
			</div>
			<div class="filter-province">
				<span class="filter-title">按省份选择</span>
				<div class="filter-content">
					<div class="city-select">
						<el-select v-model="select_city.province_key" @change="province_change" placeholder="省份">
							<el-option v-for="(item, sf_key) in city.sf" :key="sf_key" :label="item.name"
								:value="sf_key">
							</el-option>
						</el-select>
						<el-select class="select_city" v-model="select_city.city" @change="city_change"
							placeholder="城市">
							<template
								v-if="city.sf[select_city.province_key] && city.sf[select_city.province_key].children.length > 0">
								<el-option v-for="(item, city_key) in city.sf[select_city.province_key].children"
									:key="city_key" :label="item.name" :value="item.name">
								</el-option>
							</template>
						</el-select>
					</div>
					<span class="filter-title">直接搜索</span>
					<div class="city-search">
						<el-select v-model="city_value" filterable @change="city_search" placeholder="请选择">
							<el-option v-for="(item, search_key) in city.search" :key="search_key" :label="item.name"
								:value="item.name">
							</el-option>
						</el-select>
						<button class="search-btn" type="primary" @click="search_city()">搜索</button>
					</div>
				</div>
			</div>
		</div>
		<div class="city-list clearfix" id="utopia_widget_4">
			<p class="list-title">
				按省份首字母选择：
				<!-- :href="'#anchor-'+v1.anchor" -->
				<a class="frist-anchor" @click="goAnchor('anchor-' + v1.anchor)" v-for="(v1, k1) in city.zm" :key="k1">
					{{ v1.anchor }}
				</a>

			</p>

			<ul class="list-content">
				<li class="item-content clearfix" v-for="(v1, k1) in city.zm" :key="k1">
					<div class="item-frist" :id="'anchor-' + v1.anchor">
						<a>
							{{ v1.anchor }}
						</a>
					</div>

					<div class="item-provinces clearfix">
						<div class="item-province clearfix" v-for="(v2, k2) in v1.list" :key="k2">
							<a class="item-province-name" @click="click_select_province(v2.name)">
								{{ v2.name }}
							</a>
							<div class="item-citys">

								<div class="citys-city">

									<a class="city-city" v-for="(v3, k3) in v2.children" :key="k3"
										@click="click_select_city(v3.name)">{{ v3.name }}</a>

								</div>
							</div>
						</div>
					</div>
				</li>
			</ul>
		</div>


	</div>
</template>

<script>

import {
	mapMutations,
} from 'vuex';
import BMap from 'BMap';
export default {
	name: 'ChangeCity',
	metaInfo: {
		title: '科技牛—为企业精准匹配科研专家以及提供技术解决方案的服务平台',
		meta: [{
			name: 'keywords',
			content: '技术对接,技术交易,技术对接平台,技术创新服务,技术转移,成果转化,技术推广,解决方案'
		},
		{
			name: 'description',
			content: '科技牛是一家专业为企业提供四技服务:技术开发、技术转让、技术咨询、技术服务，精准匹配科研专家及技术解决方案平台公司'
		}
		]
	},
	data() {
		return {
			city: {
				"hot": [],
				"sf": [],
				"zm": [],
				"search": [],
			},
			select_city: {
				province: '',
				city: '',
				province_key: 0,
			},
			city_value: '',
			scrollTop: null,
			isScrollTop: false,
			LocationProvince: "正在定位...", //给渲染层定义一个初始值
		}
	},

	mounted() {
		this.get_city('hot');
		this.get_city('sf');
		this.get_city('zm');
		this.get_city('search');
		this.ready();
	},
	methods: {
		...mapMutations(['set_city', 'set_province']),

		location_href() {
			this.set_city(this.LocationProvince)
			let province = ''
			this.set_province(province)
			this.$router.push({
				"path": "/"
			})
		},

		ready() {
			const geolocation = new BMap.Geolocation();
			var _this = this
			geolocation.getCurrentPosition(function getinfo(position) {
				console.log(position)
				_this.LocationProvince = position.address.province; //获取省份信息
			}, function (e) {
				console.log(e);
				_this.LocationCity = "定位失败"
			}, { provider: 'baidu' });
		},
		city_change() {
			this.set_city(this.select_city.city)
			this.$router.push({
				"path": "/"
			})
		},
		city_search() {
			console.log(this.city_value);
			this.set_city(this.city_value)
			this.$router.push({
				"path": "/"
			})
		},
		click_select_city(city) {
			if (city === '全国') {
				let city = ''
				this.set_city(city)
			} else {
				this.set_city(city)
			}
			let province = ''
			this.set_province(province)
			this.$router.push({
				"path": "/"
			})
		},
		click_select_province(province) {
			this.set_province(province)
			let city = ''
			this.set_city(city)
			this.$router.push({
				"path": "/"
			})
		},
		province_change(key) {
			console.log('province_change')
			this.select_city.province = this.city.sf[key].name;
			this.select_city.city = "";
		},
		goAnchor(selector) {
			document.querySelector('#' + selector).scrollIntoView({
				behavior: "smooth"
			});
		},
		get_city(type) {
			this.$http_qm.post('city/list', {
				type: type
			}).then(res => {
				if (type == 'sf') {
					this.city.sf = res.data.sf;
				} else if (type == 'hot') {
					this.city.hot = res.data.hot;
				} else if (type == 'zm') {
					this.city.zm = res.data.zm;
				} else if (type == 'search') {
					this.city.search = res.data.search;
				}
			}).catch(err => {
				console.log(err)
			})
		},
	}
}
</script>

<style lang="less" src="../../assets/css/city_change.less" />
