<template>
  <div id="vip01_header">
    <div class="company_Nav">
      <div class="vip01_comNavin">
        <div class="firmLogo">
          <el-image style="width: 80px; height: 80px" :src="localData.logo" :alt="localData.name" fit="cover"></el-image>
          <div class="firmName">
            <h5>{{ localData.name }}</h5>
            <div class="topRbtn">
              <span class="followShow" @click="do_collect()">
                <i :class="localData.is_collect ? 'el-icon-star-on' : 'el-icon-star-off'"></i>
                {{ localData.is_collect ? '已收藏' : '收藏' }}</span>
              <span class="TalkShow">
                <i class="el-icon-chat-dot-round"></i>
                <router-link :to="{ path: '/c/vip01/contact', query: { id: id, product_id: product_id } }">留言咨询</router-link>
              </span>
            </div>
          </div>
        </div>
        <div class="firmNav">
          <el-menu active-text-color="#ff9900" :default-active="$route.path" router mode="horizontal">
            <el-menu-item :class="$route.path == item.name ? 'clickItem' : ''" v-for="(item, i) in navList" :key="i"
              :index="item.name + '?id=' + id">
              {{ item.navItem }}
            </el-menu-item>
          </el-menu>
        </div>
        <div class="tel-box"><i class="tel"></i><span>{{ localData.contact_tel }}</span></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CompanyHeader',
  props: {
    data: {
      type: Object,
      default: () => { }
    },
    id: {
      type: String,
    },
    product_id: {
      type: String,
    }
  },
  data() {
    return {
      activeIndex: '/c/vip01/index',
      localData: { ...this.data },
      navList: [{
        name: '/c/vip01/index',
        navItem: '首页'
      },
      {
        name: '/c/vip01/introduction',
        navItem: '简介'
      },
      {
        name: '/c/vip01/news',
        navItem: '新闻中心'
      },
      {
        name: '/c/vip01/product',
        navItem: '产品/服务中心'
      },
      {
        name: '/c/vip01/contact',
        navItem: '联系我们'
      }
      ]
    }
  },
  methods: {
    do_collect() {
      this.$http.post('collect/add', { collect_id: this.id, type: 4, is_collect: this.localData.is_collect }).then(res => {
        if (res.code == 200) {
          this.locallocalData.is_collect = res.data.is_collect
        }
      }).catch(err => {
        console.log(err);
      })
    }
  }
}
</script>

<style lang="less">
#vip01_header {
  .clickItem {
    color: #0088ff !important;
    font-weight: bolder;
    border-bottom-color: #0088ff !important;
  }

  .company_Nav {
    width: 100%;
    height: 80px;
    background: #fff;
    box-sizing: border-box;

    .vip01_comNavin {
      width: 1200px;
      height: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .firmLogo {
        width: 280px;
        height: 80px;
        overflow: hidden;
        display: flex;
        flex-direction: row;

        .firmName {
          display: flex;
          flex-direction: column;
          padding: 20px 0;

          h5 {
            margin-left: 10px;
            color: #333;
            font-size: 14px;
            font-weight: bold;
            margin-bottom: 7px;
            padding-right: 12px;
            position: relative;
            z-index: 1;
          }

          .topRbtn {
            display: flex;
            flex-direction: row;

            .followShow {
              padding-left: 10px;
            }

            .TalkShow {
              margin-left: 10px;
            }

            span {
              color: #666;
              font-size: 12px;
              float: left;
              min-width: 60px;
              display: flex;
              cursor: pointer;
              align-items: center;

              i {
                font-size: 14px;
                margin-right: 5px;
              }
            }
          }
        }
      }

      .firmNav {
        height: 80px;
        float: left;
        color: #000;
        font-size: 14px;

        .el-menu--horizontal {
          border: none;
        }

        .el-menu-item {
          height: 80px;
          line-height: 80px;
          padding: 0 35px;

          &.is-active {
            color: #409EFF !important;
          }
        }


      }

      .tel-box {
        height: 80px;
        line-height: 80px;

        .tel {
          display: inline-block;
          width: 30px;
          height: 30px;
          margin-right: 10px;
          background: url(https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/other/icon_tel_big.png) no-repeat 50%;
          vertical-align: middle;
        }

        span {
          font-size: 26px;
          vertical-align: middle;
        }
      }
    }
  }
}
</style>
