<template>
	<div id="list-news">
		<Top page_type="news"></Top>
		<div class="mui-home-item">
			<div class="bannerImg">
				<img src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/other/news.png" />
			</div>
			<div class="main">
				<div class="column-search">
					<el-breadcrumb separator-class="el-icon-arrow-right">
						<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
						<el-breadcrumb-item>新闻列表</el-breadcrumb-item>
					</el-breadcrumb>
				</div>
				<div class="selectNumberScreen">
					<!-- <div class="sxrr">
						<div class="flex select_time ">
							<div class="title">发布时间:</div>
							<ul style="flex: 1; display: flex;">
								<li v-for="(v,k) in time_arr" @click="time_change(k,v)" :class="{active:k==time_index}" :key="k">{{v}}</li>
							</ul>
						</div>

					</div> -->
				</div>
				<div class="meet_list">
					<ul>
						<li v-for="(v, k) in news.list" :key="k" class="li" @click="detail(v.id)">
							<el-image style="width: 270px !important; height: 160px" lazy :src="v.cover[0]"
								fit="cover"></el-image>
							<div class="info">
								<div class="title">
									<img v-if="v.is_top" class="title-img"
										src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/other/index.gif" alt="">
									{{ v.title }}
								</div>
								<p class="cnt" v-html="v.describe"></p>
								<div class="fot">
									<p>
										<span>观看人数：{{ v.view_num }}</span>
										<span>评论：{{ v.comment_num }}</span>
										<span>发布时间：{{ v.created_at }}</span>
									</p>
									<div class="share">
										<i class="el-icon-share"></i>
										<share :config="config"></share>
									</div>
								</div>
							</div>
						</li>
					</ul>
					<div class="page">
						<el-pagination @current-change="handleCurrentChange" background :page-size="news_post.limit"
							layout="prev, pager, next" :total="news.count" :current-page="currentPage">
						</el-pagination>
					</div>
				</div>
			</div>
		</div>
		<flexRight></flexRight>
	</div>
</template>

<script>
import Top from "../../components/top.vue"
import flexRight from "@/components/flexRight.vue"
export default {
	name: 'NewsList',
	metaInfo: {
		title: '科技牛—为企业精准匹配科研专家以及提供技术解决方案的服务平台',
		meta: [{
			name: 'keywords',
			content: '技术对接,技术交易,技术对接平台,技术创新服务,技术转移,成果转化,技术推广,解决方案'
		},
		{
			name: 'description',
			content: '科技牛是一家专业为企业提供四技服务:技术开发、技术转让、技术咨询、技术服务，精准匹配科研专家及技术解决方案平台公司'
		}
		]
	},
	components: {
		Top, flexRight
	},
	data() {
		return {
			radio: '全部',
			province_index: 0,
			time_index: 0,
			time_arr: ['全部', '今天', '最近三天', '最近一周', '一个月内'],
			city_active: false,
			cate: [],
			news: {
				list: [],
				count: 0,
			},
			news_post: {
				limit: 6,
				page: 1,
				time: '',
				province: '',
				request_type: 'new',
				status: '1',
			},
			config: {
				title: '科技牛新闻分享',
				description: '科技牛最新需求链接内容详情分享',
				url: 'http://pc.keji6.cn/#/demand/detail?id=' + this.id,
				source: 'http://pc.keji6.cn/#/demand/detail?id=' + this.id,
				image: 'http://hellofood.fun/webicon.png',
				// summary : '吃货召唤', //相当于description
				sites: ['wechat', 'qzone', 'qq', 'weibo'], // 启用的站点
				// disabled: ['google', 'facebook', 'twitter', 'linyin'], // 禁用的站点
				wechatQrcodeTitle: "微信扫一扫：分享", // 微信二维码提示文字
				wechatQrcodeHelper: '立即下载发送专属二维码,邀请朋友加入',
			},
			currentPage: 1
		}
	},
	watch: {
		$route() {
			let query = this.$route.query
			if (typeof (query.page) != 'undefined') {
				this.news_post.page = parseInt(query.page);
				this.currentPage = parseInt(query.page);
			}
			this.get_list();
		},
	},
	created() {
		var querys = this.$route.query
		if (typeof (querys.page) != 'undefined') {
			this.news_post.page = parseInt(querys.page);
			this.currentPage = parseInt(querys.page);
		} else {
			this.news_post.page = 1;
			this.currentPage = 1;
		}
	},
	mounted() {
		this.get_cate();
		this.get_list();
	},
	methods: {
		time_change(index, name) {
			console.log(index, name)
			this.time_index = index;
			if (name == '全部') {
				this.news_post.time = '';
			} else {
				this.news_post.time = name;
			}
			this.news_post.page = 1;
			this.get_list();
		},
		handleCurrentChange(page) {
			this.$router.push({
				path: '/news',
				query: {
					page: page
				}
			})
			// this.news_post.page = page;
			// this.get_list();
			document.querySelector('#app').scrollIntoView({
				behavior: "auto"
			});
		},
		async get_cate() {
			await this.$http_qm.post('demand/cate', {}).then(res => {
				this.cate = res.data;
				this.cate.city.unshift({
					name: '不限'
				})
				this.cate.industry.unshift({
					name: '不限'
				})
				this.cate.type.unshift({
					name: '不限'
				})
			}).catch(err => {
				console.log(err)
			})
		},
		province_click(index, name) {
			console.log(index, name);
			this.province_index = index;
			if (name == '不限') {
				this.news_post.province = ''
			} else {
				this.news_post.province = name
			}
			this.news_post.page = 1;
			this.get_list();
		},
		get_list() {
			this.$http_qm.post('news/list', this.news_post).then(res => {
				if (res.code == 200) {
					this.news.list = res.data;
					this.news.count = res.count;
				}
			}).catch(err => {
				console.log(err)
			})
		},
		detail(id) {
			this.$router.push({
				path: '/news/detail',
				query: { id: id }
			})
		}
	}
}
</script>

<style lang="less" src="../../assets/css/news.less"></style>
