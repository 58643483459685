<template>
	<div id="protocol">
		<Top></Top>
		<div class="container">
			<div class="row">
				<section>
					<section class="Powered-by-XIUMI V5" style="position: static;" powered-by="xiumi.us">
						<section class="" style="position: static;">
							<section class="" style="font-size: 14px;">
								<p style="margin-top: 0px; margin-bottom: 0px;">
									<span style="font-size: 18px;"><strong>科技牛用户协议</strong></span><span
										style="font-size: 16px;"></span>
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									<strong><br></strong>
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									<strong>1.服务协议的确认和接纳</strong>
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									科技牛是由上海一实网络科技有限公司拥有所有权与经营权产品。用户在接受科技牛的服务之前，请务必仔细阅读本条款并同意本声明。访问者访问本网站或通过各类方式使用本网站的行为，都将被视为是对本声明全部内容的无异议的认可。
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									1.1
									用户直接或间接通过各类方式（如站外API引用等）产生与本网站相关的行为时，都将被视为已无条件接受本声明所涉及的全部内容；若用户对本声明的任何条款有异议，请停止使用科技牛所提供的全部服务。
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									<br>
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									1.2 当用户使用科技牛的服务时，需遵守中国的法律法规并保证信息的真实性，同时应当恪守以下承诺：
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									1)科技牛在修改条款时不再另行通知用户，用户可随时通过科技牛网站浏览最新服务协议条款。用户只有在同意遵守相关法律法规和本协议时，才有权使用科技牛的服务，无论用户是否有意访问或使用此服务。
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									2)如果用户不同意科技牛对协议相关条款的修改，则有权停止使用服务。如果继续使用服务，则视为已接受科技牛最新服务协议条款。
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									3)用户注册时请按照提示填写正确的手机号、邮箱、姓名和密码，并确保日后更新的相关资料具有有效性和合法性。若用户提交任何违法或者科技牛认为不适合在其平台上展示的资料，科技牛则有理由怀疑用户的资料属于恶意操作，并且有权暂停或终止用户的账号。
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									4)科技牛仅对用户的信息与资料进行形式审查，不进行实质性审查（实质性审查包括但不限于鉴别、核实任何登记资料的真实性、正确性以及是否为最新资料的审查）对此所产生的纠纷科技牛不承担过错责任。
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									5)用户有保证其信息和资料详尽、准确并对其及进行时更改的义务。
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									6)用户有义务在其注册信息发生变动时，及时更新相关资料以保证在使用该信息时的准确性和合法性。
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									<br>
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									<strong>2.用户使用规则</strong>
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									2.1
									用户有义务保管好个人账号的相关信息并对以其进行的所有行为负法律责任。用户不应将其账号、密码转让或借予他人使用。因黑客行为或用户的保管疏忽导致账号、密码外泄，科技牛不承担任何责任。
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									<br>
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									2.2 用户在使用科技牛的服务时，须向科技牛提供真实资料并及时更新。科技牛会根据用户的权限设置对用户资料的信息进行保密。
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									<br>
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									2.3 用户同意接受科技牛通过电子邮件、短信或其他方式向用户发送活动通知及其相关信息。
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									<br>
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									2.4
									根据有关法律法规，科技牛在此郑重提醒用户：任何经由本服务发布的文字、图片信息，无论是公开还是私密发布，均需由科技牛工作人员进行审核。科技牛仅为用户提供活动发布平台，无法控制经由本服务发布的活动现场情况，因此不保证活动是否符合用户的预期。但科技牛有权依法停止发布任何不符合平台规则的内容并采取相应措施，包括但不限于暂停用户使用本服务，保存有关记录，并向有关机构报告。
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									<br>
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									2.5
									用户保证对其通过科技牛服务发布到网站上的内容拥有完整的合法的知识产权，保证科技牛使用该内容不违反国家的法律法规，也不侵犯第三方的合法权益或承担任何义务。用户承诺上传的内容不涉及侵犯他人著作权、商标、名誉、隐私及其他一切违法内容。用户应对其所提供内容因形式、内容及授权的不完善、不合法所造成的一切后果承担完全责任。
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									<br>
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									2.6
									用户承诺遵循中华人民共和国有关互联网使用以及金融支付的的法律法规，不从事洗钱、诈骗、以及其他任何破坏社会主义经济秩序的活动；用户使用本服务的行为若有任何违反国家法律法规或侵犯第三方的合法权益时，科技牛将保留向公安机关以及国家有关监管部门举报的权利。
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									<br>
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									2.7
									用户在使用科技牛服务期间，本协议将保持完整的效力。如果用户所发布的活动与法律相抵触或者不符合本协议，科技牛可以在不发出任何通知的情况下，随时终止用户的注册用户资格。
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									<br>
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									<strong>3.隐私保护</strong>
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									3.1
									用户注册科技牛账号时，系统会要求其提供个人信息：如姓名、电话、邮箱以及账号密码。若涉及支付行为，用户需提供银行卡等相关付款账户信息，科技牛将以加密的形式储存在安全的服务器上。
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									<br>
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									3.2 科技牛在用户使用其服务时可能获取的信息：
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									1)Cookie信息：当用户使用科技牛的服务时，系统会将一个或多个Cookie（包含一串字符的小型档案）传送到用户的计算机或其他装置上，以便储存用户的偏好设定，从而提供更好的服务。
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									2)日志信息：当用户使用科技牛的服务时，其服务器会自动记录用户浏览器所传送的信息。服务器日志包含用户的网页请求、因特网通讯协议地址、浏览器类型、浏览器语言、传送请求的日期和时间，以及一个或多个用来识别用户浏览器的Cookie等信息。
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									3)位置信息：科技牛提供基于Google Map开放API的定位服务，无论用户使用哪种设备使用此服务，
									科技牛皆会主动询问用户是否要提供地理位置相关信息：包含用户的实际位置信息（例如移动装置传送的GPS讯号）以及可用来判断大概位置的信息（例如基地台编号）
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									4)链接信息：科技牛可能会以特定格式显示链接，以此改善定制化内容和推荐的质量以及追踪用户是否可以访问这些链接。
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									5)用户向科技牛联盟网站提供的信息：科技牛会通过其他网站提供某些服务。用户提供给这些网站的个人信息可能会传送给科技牛，科技牛会根据本《隐私权政策》处理此类信息。每个网站所采用的隐私权政策各不相同，建议用户详阅他们的隐私权政策。
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									<br>
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									3.3 用户的个人信息将在下述情况下部分或全部被披露：
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									1)经用户同意，向第三方披露；
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									2)根据有关法律规定，或应行政、司法机构的要求，向第三方或者行政、司法机构披露；
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									3)如果用户出现违反中国有关法律或者网站政策的情况，需要向第三方披露；
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									4)为提供用户所要求的商品和服务，而必须和第三方分享用户的个人信息；
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									5)其他科技牛根据法律或者网站政策认为必要的披露。
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									<br>
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									3.4 请用户妥善保管账户及密码信息。如果用户发现个人信息泄密，尤其是科技牛的账户和密码泄露，请立即联络科技牛客服，以便科技牛采取相应措施。
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									<br>
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									<br>
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									<strong>4.服务内容介绍</strong>
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									4.1
									网络信息服务：指活动主办方根据本协议的规定使用科技牛发布活动信息、作为主办方和参与者确认票种交易信息、用户参加科技牛的有关活动以及其他由科技牛同意提供的信息服务。
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									<br>
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									4.2 科技牛平台群发信息服务：指科技牛平台为商业提供的活动推广和通知邀请等服务。
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									<br>
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									4.3
									其他服务：包括但不限于商业推广、广告等服务，由协议方在补充协议中确定。用户在此同意科技牛在任何情况下都无需向其或第三方在传播过程中的不准确或疏漏而导致的损害承担赔偿责任。
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									<br>
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									<br>
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									<strong>5.知识产权</strong>
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									5.1
									科技牛提供的服务中所包含的任何文字、图片资料均受相关知识产权法律的保护，未经相关权利人同意，上述资料均不得在任何渠道改写或发布。科技牛不对上述资料产生过程中的不准确予以赔偿并负责。
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									<br>
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									5.2
									本服务的相关软件含有受法律保护的专有保密资料。未经科技牛或广告商明示授权，用户不得修改、兜售、散布本服务或软件的任何部分。用户同意将通过由科技牛所提供的界面而非任何其它途径使用本服务。
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									<br>
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									5.3
									用户在科技牛发布的内容应保证其为著作权人或已取得合法授权，并且该内容不会侵犯任何第三方的合法权益，用户同意授予科技牛所有上述内容在全球范围内的免费、不可撤销的的使用权许可，据该许可科技牛将有权以展示、推广及其他不为我国法律所禁止的方式使用前述内容。
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									<br>
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									<br>
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									<strong>6.禁止发布的内容</strong>
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									6.1 请用户谨慎选择发布在科技牛平台的信息，根据相关法律法规，任何组织和个人都不得利用科技牛发布以下内容：
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									1)反对宪法所确定的基本原则的；
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									2)危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									3)损害国家荣誉和利益的；
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									4)煽动民族仇恨、民族歧视，破坏民族团结的；
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									5)破坏国家宗教政策，宣扬邪教和封建迷信的；
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									6)散布谣言，扰乱社会秩序，破坏社会稳定的；
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									7)散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									8)侮辱或者诽谤他人，侵害他人合法权益的；
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									9)含有法律、行政法规禁止的其他内容的信息。
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									如果用户知道任何人违反上述规定，请直接联系科技牛客服。科技牛不对任何用户提供的资料承担责任。
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									<br>
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									6.2 除上述一般条款的规定，如下列出了部分在科技牛发布后被设为私有的活动类型：
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									1)批量重复发布同一活动，重复的活动将被设置为私有状态；
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									2)明显销售性质、虚假宣传、招商（生）、征集项目、留学（投资）咨询、销售产品、线上活动、展览、相亲（恋爱交友）、医疗、美容、众筹、宗教类等等其他类型暂时都会被设置为私有状态；
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									3)无具体活动内容的英语培训、相亲交友、演讲口才等营销类活动将被设置为私有状态；
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									4)展会招商类广告，都将被设置为私有状态。
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									<br>
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									<br>
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									<strong>7.风险与免责</strong>
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									用户通过本网站与广告商或其他第三方进行任何形式的通讯或商业往来，或参与活动，包含相关服务的付款，以及达成的其它任何条款，完全为用户与广告商或其他第三方之间之行为。用户因前述任何交易或前述广告商及其他第三方而遭受的任何性质的损失或损害，科技牛对此不负任何法律责任。
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									7.1 基于互联网的开放性和社交网络服务的传播特殊性，科技牛特别提醒用户谨慎注意以下风险：
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									1)本服务仅提供在线活动管理平台，用户应当对其他用户使用本服务所发布的内容进行独立判断并承担相应风险，用户了解并确认，科技牛不对本服务用户之间的纠纷承担任何责任。
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									2)用户在使用本服务过程中所发布的内容有可能会被第三方修改或做其他用途，脱离用户的预期和控制，用户必须充分意识此类风险的存在，科技牛对由此产生的纠纷不予负责。
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									3)请用户切勿在使用本服务时透露任何涉及财产、本服务账号及密码等重要信息，否则，由此带来的损失由用户自行承担。
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									<br>
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									7.2
									在法律法规所允许的限度内，因使用科技牛服务而引起的任何损害或经济损失，科技牛承担的全部责任均不超过用户所购买的与该索赔有关的服务价格。这些责任限制条款将在法律所允许的最大限度内适用，并在用户资格被撤销或终止后仍继续有效。
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									<br>
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									7.3 艾科创意信息技术有限公司不担保科技牛提供给用户的全部信息或从科技牛发出的信息没有病毒或其他有害成分。
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									<br>
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									7.4 以下情况使您无法使用各项服务时，本公司不承担任何损害赔偿责任，该状况包括但不限于：
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									1)由于用户将用户密码告知他人或与他人共享注册帐户，由此导致的任何个人信息的泄漏，或其他非因科技牛原因导致的个人信息的泄漏；
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									2)任何由于政府管制而造成的暂时性网站关闭；
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									3)因不可抗力导致的任何后果；
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									4)因本公司公告之后系统停机维护期间；
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									5)因黑客攻击、电信部门技术调整或故障、网站升级、银行、第三方支付工具方面的问题等原因而造成的服务中断或者延迟。
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									<br>
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									7.5 本协议适用中华人民共和国的法律，所有的争端将诉诸北京市艾科创意信息技术有限公司所在地的人民法院。
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									<br>
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									<br>
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									<strong>8.协议变更、服务变更、中断或终止</strong>
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									8.1 本服务条款可由科技牛根据需要随时更新，且毋须另行通知。如果用户不同意相关变更，必须停止使用“服务”。登录或继续使用“服务”将表示用户接受经修订的协议。
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									<br>
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									8.2
									鉴于服务的特殊性，用户同意科技牛有权随时变更、中断或终止服务。如变更、中断或终止的服务属于免费服务，科技牛无需通知用户，也无需对任何用户或任何第三方承担任何责任。
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									<br>
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									8.3
									用户理解科技牛需要定期或不定期地对提供服务的平台或相关设备进行维护，如因此类情况而造成收费服务在合理时间内的中断，科技牛无需为此承担任何责任，科技牛会尽可能事先进行通告。
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									<br>
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									8.4 如发生下列任何一种情形，科技牛有权随时中断或终止向用户提供本协议项下的服务（包括收费服务）且无需对用户或任何第三方承担任何责任：
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									1）用户提供的个人资料不真实；
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									2）用户违反本协议中规定的使用规则；
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									3）用户在使用收费服务时未按规定向科技牛支付相应的服务费。
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									<br>
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									8.5
									用户同意科技牛基于自行考虑，因任何理由，包含但不限于缺乏使用，或认为用户已经违反本使用协议，终止用户的账号或本服务的使用，并将用户在本服务内任何内容加以移除。用户同意依本使用协议提供的服务，无需进行事先通知即可中断或终止。科技牛对用户或任何第三人均不承担任何责任。
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									<br>
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									<br>
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									<strong>9.适用的法律和管辖权</strong>
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									9.1
									用户同意遵守中华人民共和国相关法律法规的所有规定，并对其在使用本服务过程中产生的结果承担全部责任。同时如果科技牛有理由认为用户有违反国家法律法规法人行为，科技牛可在任何时候不经事先通知终止向用户提供服务。
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									<br>
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									9.2 用户因本协议产生的争议，均应依照中华人民共和国法律予以处理，并由艾科创意信息技术有限公司所在地的人民法院提起诉讼。
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									<br>
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									<br>
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									<strong>10.支付服务</strong>
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									10.1
									风险自担：在使用本服务时，您已完全了解可能存在的风险（包括但不限于活动主办方信息的真实性、活动内容的真实性、支付前后活动的变更、活动内容与您的预期不符等），并且承担由此带来的一切损失和责任，您不应以任何原因要求科技牛退款或承担任何责任。
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									<br>
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									10.2 支付：当您报名参加活动并点击确认付款后，您即授权本公司将您已支付的款项支付给活动主办方。
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									<br>
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									10.3 退款：当您需要退款时，您应根据活动主办方所选择的处理方式自行依照流程申请退款，退款之外的其他要求，应致电客服提出申请。情况如下：
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									1)如果活动主办方在发布活动时选择的是可以退款的处理方式，请您于活动开始前24小时外申请退款，科技牛将统一收取原票价的10%作为退票手续费，并在收到主办方通知后的10个工作日内处理退款。
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									2)如果活动主办方选择的是活动参与者不得申请退款，则您不得依照本条约定提出退款申请，您需要自行联系主办方协商处理，本公司不负责处理任何退款事宜。
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									<br>
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									10.4
									提款：活动结束后的次日即可发起提款申请。每月15号中午12：00前申请的提款会在25号操作打款，每月30号中午12：00前申请提款会在次月10号操作打款。每月10日与25日为出款日。
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									1)科技牛对于发布的免费活动或未涉及收款功能的活动，将继续完全免费的提供平台的基础服务。
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									2)科技牛对于使用收款功能的用户，在科技牛平台内产生的付费报名订单，科技牛将收取每张票券手续费为票款金额*2.5%+1元。
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									<br>
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									<br>
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									<strong>11.其他</strong>
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									11.1
									若用户对本声明的任何条款有异议，请停止使用科技牛所提供的全部服务。科技牛修改条款时不再另行通知用户，如果用户继续使用服务，则视为用户接受科技牛最新服务协议条款。
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									<br>
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									11.2 本协议的成立、生效、履行、解释及纠纷解决，适用中华人民共和国大陆地区法律（不包括冲突法）。
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									<br>
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									11.3 若用户和科技牛之间发生任何纠纷或争议，首先应友好协商解决；协商不成的，用户同意将纠纷或争议提交艾科创意信息技术有限公司所在地的人民法院管辖。
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									<br>
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									11.4 本协议所有条款的标题仅为阅读方便，本身并无实际涵义，不能作为本协议涵义解释的依据。
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									<br>
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									11.5 本协议条款无论因何种原因部分无效或不可执行，其余条款仍有效，对双方具有约束力。
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									<br>
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									11.6 本服务协议的最终解释权归北京市艾科创意信息技术有限公司所有。
								</p>
								<p style="margin-top: 0px; margin-bottom: 0px;">
									<br>
								</p>
							</section>
						</section>
					</section>
				</section>
				<p>
					<br>
				</p>
			</div>
		</div>
	</div>
</template>

<script>
import Top from "../../components/top.vue"
export default {
	name:'ActivityProtocol',
	metaInfo: {
		title: '科技牛—为企业精准匹配科研专家以及提供技术解决方案的服务平台',
		meta: [{
			name: 'keywords',
			content: '技术对接,技术交易,技术对接平台,技术创新服务,技术转移,成果转化,技术推广,解决方案'
		},
		{
			name: 'description',
			content: '科技牛是一家专业为企业提供四技服务:技术开发、技术转让、技术咨询、技术服务，精准匹配科研专家及技术解决方案平台公司'
		}
		]
	},
	components: {
		Top
	},
	data() {
		return {}
	}
}
</script>

<style lang="less">
#protocol {
	.container {
		width: 1080px;
		margin-right: auto;
		margin-left: auto;

		.row {
			margin-top: 40px;
			margin-left: 0px;
		}
	}
}
</style>
