<template>
	<div id="shop">

		<Top v-if="data.name" :data="data" :TabCur="1"></Top>

		<div class="new-shop-index shop-case about-shop">
			<!-- bottom -->
			<div class="w1200 margin-top-20 clearfix">
				<!-- left -->
				<Left :data="data" :id="id"></Left>
				<!-- cur2 最新产品 -->
				<div class="index-right fl margin-left-20">
					<div class="case-latest">
						<div class="column-box clearfix">
							<p class="f16 text-bold fl">最新产品</p>
						</div>
						<ul class="clearfix" id="video_container">
							<li class="fl" v-for="(item, index) in new_product.list" :key="index">
								<div class="img-box">
									<router-link :to="{ path: '/store/anli_detail?', query: { id: item.id, company_id: id } }">
										<el-image v-if="item.cover != null" style="width: 280px; height: 160px"
											:src="item.cover[0]" fit="cover"></el-image>
									</router-link>
								</div>
								<div class="txt-box padding-top-10">
									<router-link class="f14 cur-por text-overflow"
										:to="{ path: '/store/anli_detail?', query: { id: item.id, company_id: id } }">
										{{ item.title }}
										<p class="margin-top-10 text-muted" v-if="item.cate">{{ item.cate.two_name }}</p>
									</router-link>
								</div>
							</li>
						</ul>
						<div class="pagination">
							<el-pagination background @current-change="handleCurrentChange" :current-page="currentPage"
								:page-size="pageSize" layout="total, prev, pager, next, jumper"
								:total="new_product.count">
							</el-pagination>
						</div>
					</div>
				</div>

			</div>

		</div>
		<flexRight></flexRight>
	</div>
</template>


<script>
import Top from "./top.vue"
import Left from "./left.vue"
import flexRight from "@/components/flexRight.vue"
export default {
	name: 'StoreAnli',
	metaInfo: {
		title: '科技牛—为企业精准匹配科研专家以及提供技术解决方案的服务平台',
		meta: [{
			name: 'keywords',
			content: '技术对接,技术交易,技术对接平台,技术创新服务,技术转移,成果转化,技术推广,解决方案'
		},
		{
			name: 'description',
			content: '科技牛是一家专业为企业提供四技服务:技术开发、技术转让、技术咨询、技术服务，精准匹配科研专家及技术解决方案平台公司'
		}
		]
	},
	data() {
		return {
			id: '',
			currentPage: 1, //初始页
			pageSize: 10, //每页个数
			// 显示案例图片
			data: {},
			new_product: {
				list: [],
				limit: 9,
				page: 1,
				count: 0,
			}
		}
	},
	components: {
		Top, Left, flexRight
	},
	computed: {},
	mounted() {
		let query = this.$route.query;
		this.id = query.id;
		this.get_detail();

	},
	methods: {
		href() {

		},
		submit() {
			this.$http_qm.post('leave_message/add', this.form).then(res => {
				this.$message(res.msg);
			}).catch(err => {
				console.log(err);
			})
		},
		get_new_product() {
			this.$http_qm.post('product/list', {
				limit: this.new_product.limit,
				page: this.new_product.page,
				uid: this.data.uid,
				request_type: 'store',
			}).then(res => {
				this.new_product.list = res.data;
				this.new_product.count = res.count;
			}).catch(err => {
				console.log(err);
			})
		},
		get_detail() {
			this.$http_qm.post('company/detail', {
				id: this.id
			}).then(res => {
				this.data = res.data;
				this.get_new_product()
			}).catch(err => {
				console.log(err);
			})
		},

		handleCurrentChange(val) {
			console.log(`当前页: ${val}`);
			this.new_product.page = val
			this.get_new_product()
		},
	}
}
</script>
<style type="text/css" lang="less" src="../../assets/css/store.less"></style>