import { render, staticRenderFns } from "./anli.vue?vue&type=template&id=23c8596f"
import script from "./anli.vue?vue&type=script&lang=js"
export * from "./anli.vue?vue&type=script&lang=js"
import style0 from "../../assets/css/store.less?vue&type=style&index=0&prod&lang=less&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports