<template>
	<div id="vip01_footer">
		<div class="link">
			<div class="vip_freand">
				<h3>友情链接：</h3>
				<a v-for="(item, index) in linkList.list" :key="index" target="_blank" :href="item.link"
					v-html="item.name"></a>
			</div>
		</div>
		<div class="footer">
			<div class="copyright">
				<p>{{ data.name }} 版权所有</p>

				<p>地址：{{ data.company_website }}<span>电话：{{ data.contact_tel }}</span></p>
				<p><span>技术支持：<a href="http://www.192kjzxfw.com" style="color: #999;">上海一实科技网络科技有限公司</a></span></p>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name:'Vip1Footer',
	props: {
		data: {
			type: Object,
			default: () => { }
		},
		id: {
			type: String,
		}
	},
	data() {
		return {
			linkList: {
				list: [],
				limit: 10,
				page: 1
			},
		}
	},
	methods: {
		link_list(id) {
			this.linkList.company_id = id
			let data = this.linkList;
			this.$http_qm.post('company_link/list', data).then(res => {
				if (res.code == 200) {
					this.linkList.list = res.data;
					this.$forceUpdate()
				}
			}).catch(err => {
				console.log(err)
			})
		}
	}
}
</script>

<style lang="less">
#vip01_footer {
	.link {
		border-top: 2px solid #999;
		width: 100%;
		background: #F2F2F2;
		text-align: center;
		display: flex;

		.vip_freand {
			width: 1200px;
			margin: auto;
			padding: 10px 0;

			h3 {
				width: auto;
				float: left;
				font-size: 14px;
				color: #666;
				line-height: 30px;
			}

			a {
				margin-left: 45px;
				float: left;
				font-size: 14px;
				color: #666;
				line-height: 30px;
				display: block;

				&:hover {
					color: #0088ff;
				}
			}
		}
	}

	.footer {
		border-top: 2px solid #999;

		.copyright {
			background-color: #fff;
			height: 76px;
			padding-top: 15px;
			text-align: center;

			p {
				font-size: 12px;
				font-weight: normal;
				font-family: "黑体";
				line-height: 25px;
				color: #999;

				span {
					margin-left: 20px;
				}
			}
		}
	}
}
</style>
