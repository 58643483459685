<template>
	<div id="storeTop">
		<div class="new-shop-header">
			<div class="w1200">
				<div class="logo-box" v-if="data.logo">
					<el-image style="width: 60px; height: 60px" :src="data.logo" :alt="data.name"
						fit="cover"></el-image>
				</div>
				<div class="shop-title-box">
					<div class="title">
						<h2 class="text-bold">{{ data.name }}</h2>
					</div>
					<div class="des">
						<p class="text-light-gray text-overflow">{{ data.main_product }}</p>
					</div>
				</div>
				<div class="tel-box">
					<i class="tel"></i>
					<span>{{ data.contact_tel }}</span>
				</div>
			</div>
		</div>
		<div class="new-shop-nav">
			<div class="w1200">
				<ul class="clearfix">
					<li class="fl" v-for="(item, index) in title_list" :key="index" @click="tabSelect(item.path)"
						:data-id="index" :class="index == TabCur ? 'Active' : ''">
						<span class="f14 text-center" :class="index == TabCur ? 'Active' : ''">
							{{ item.title }}
						</span>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'StoreTop',
	props: {
		data: {
			type: Object,
			default: function () { // 使用函数返回默认值
				return {}; // 默认值应该是一个对象
			}
		},
		TabCur: {
			type: Number,
			default: 0,
		}
	},
	data() {
		return {
			title_list: [{
				title: '店铺首页',
				path: '/store/index'
			}, {
				title: '最新产品',
				path: '/store/anli'
			}, {
				title: '关于我们',
				path: '/store/about'
			}, {
				title: '留言咨询',
				path: '/store/leave'
			}],
		}
	},
	methods: {
		tabSelect(url) {
			this.$router.push({
				path: url,
				query: {
					id: this.data.id
				}
			})
		},
	}
}
</script>

<style lang="less">
#storeTop {

	.new-shop-header {
		width: 100%;
		height: 120px;
		background: #ffffff;

		.w1200 {
			position: relative;
			height: 120px !important;
			width: 1200px;
			margin: 0 auto;
			display: flex;

			.logo-box {
				position: absolute;
				top: 50%;
				left: 0;
				transform: translateY(-50%);
				width: 60px;
				height: 60px;
				margin-right: 20px;
				text-align: center;
				line-height: 60px;

				img {
					display: inline;
					width: auto;
					height: auto;
					max-width: 60px;
					max-height: 60px;
					margin: auto;
				}
			}

			.shop-title-box {
				position: absolute;
				top: 50%;
				left: 80px;
				transform: translateY(-50%);
				z-index: 9;

				.title {
					h2 {
						display: inline-block;
						max-width: 830px;
						vertical-align: middle;
						font-size: 24px;
					}
				}

				.des {
					width: 480px;
					margin-top: 6px;
				}
			}

			.tel-box {
				position: absolute;
				top: 50%;
				right: 0;
				transform: translateY(-50%);
				height: 30px;

				.des {
					width: 480px;
					margin-top: 6px;
				}

				span {
					font-size: 26px;
					vertical-align: middle;
				}
			}
		}
	}

	.new-shop-nav {
		width: 100%;
		display: flex;
		background: #ffffff;

		ul {
			li {
				width: 100px;
				height: 60px !important;
				line-height: 60px !important;
				display: flex;
				justify-content: center;
				list-style: none;
				cursor: pointer;

				span {
					color: black !important;
					font-size: 16px;
				}

				span:hover {
					color: #ff6028;
				}
			}

			.Active {
				color: #ffffff !important;
				font-weight: 600;
				background-color: #0088ff;
			}
		}
	}
}
</style>
