<template>
	<div id="mail">
		<div class="pass_header clearfix">
			<span>邮箱验证</span>
		</div>
		<div class="pass_content">
			<div class="f_jsp_bottom">
				<p class="blue">完成邮箱验证，用于修改账号密码，并可及时收到商机邮件。</p>
				<img v-if="email" src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/other/f_true.jpg">
				<p v-if="email">您的邮箱<span class="mail"> {{ email }} </span>已完成验证。</p>
				<span @click="toUrl('/company')">返回会员中心</span>
				<span @click="toUrl('/company/qy_message')">返回会员基本信息</span>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name:'CompanyMail',
	metaInfo: {
		title: '科技牛—为企业精准匹配科研专家以及提供技术解决方案的服务平台',
		meta: [{
			name: 'keywords',
			content: '技术对接,技术交易,技术对接平台,技术创新服务,技术转移,成果转化,技术推广,解决方案'
		},
		{
			name: 'description',
			content: '科技牛是一家专业为企业提供四技服务:技术开发、技术转让、技术咨询、技术服务，精准匹配科研专家及技术解决方案平台公司'
		}
		]
	},
	data() {
		return {
			email: '',
			code: '',
		}
	},
	mounted() {
		this.code = this.$route.query.code;
		this.$http_qm.post('user/verify_email', { code: this.code }).then(res => {
			this.email = res.data.email
		}).catch(err => {
			console.log(err)
		})
	},
	methods: {
		toUrl(url) {
			this.$router.push({
				path: url
			})
		}
	}
}
</script>

<style lang="less">
#mail {
	.pass_header {
		width: 1000px;
		margin: 0 auto;
		padding: 38px 0 38px 0;
		height: 59px;
		overflow: hidden;
		font-size: 22px;
		display: flex;
		flex-direction: row;
		color: #333;

		span {
			padding-left: 10px;
			line-height: 68px;
			font-weight: bold;
		}
	}

	.pass_content {
		width: 952px;
		height: 467px;
		border: 1px solid #ddd;
		margin: 0 auto 30px;
		padding: 31px 23px 0;

		.f_jsp_bottom {
			display: block;
			width: 340px;
			margin: 130px auto;

			.blue {
				color: #2a5db0;
				line-height: 25px;
				font-size: 12px;
			}

			img {
				float: left;
				margin-right: 15px;
			}

			p {
				line-height: 25px;
				font-size: 12px;
			}

			.mail {
				color: red;
				text-decoration: none;
			}

			span {
				color: #039;
				text-decoration: underline;
				margin-right: 15px;
				cursor: pointer;
			}
		}
	}
}
</style>
