<template>
	<div id="message">
		<div class="wrapper">
			<div class="main_left">
				<Left></Left>
			</div>
			<div class="main_right">
				<div class="page">
					<div class="page_nav">
						<div class="page_nav_tit">留言管理</div>
					</div>
					<div class="page_list">
						<!-- @row-click="clickTr" 行点击-->
						<el-table :data="message.list" style="width: 100%" ref="topicTable" @cell-click="handle">
							<el-table-column type="expand">
								<template slot-scope="props">
									<el-form label-position="left" inline class="demo-table-expand">
										<el-col :span="24">

											<el-form-item v-if="props.row.product_info" label="产品名称">
												<span>{{ props.row.product_info.title }}</span>
											</el-form-item>
										</el-col>
										<el-col :span="24">
											<el-form-item label="类型" style="width: 100%;">
												<span>{{ props.row.type[0] }}</span>
											</el-form-item>
											<el-form-item label="详细描述" style="width: 100%;">
												<span>{{ props.row.describe }}</span>
											</el-form-item>
										</el-col>
									</el-form>
								</template>
							</el-table-column>
							<el-table-column label="主题" prop="title">
							</el-table-column>
							<el-table-column label="公司名称" prop="contact_company_name">
							</el-table-column>
							<el-table-column label="联系人" prop="contact_name">
							</el-table-column>
							<el-table-column label="联系电话" prop="contact_tel">
							</el-table-column>

							<el-table-column label="操作">
								<template slot-scope="scope">
									<el-button @click.native.prevent="deleteRow(scope.$index, scope.row.id)" type="text"
										size="small">
										移除
									</el-button>
								</template>
							</el-table-column>
						</el-table>
						<div class="product_page">
							<el-pagination @current-change="handleCurrentChange" :page-size="message.post.limit"
								layout="total, prev, pager, next" :total="message.count">
							</el-pagination>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Left from "../company/Left.vue"
export default {
	name: 'StoreMessage',
	metaInfo: {
		title: '科技牛—为企业精准匹配科研专家以及提供技术解决方案的服务平台',
		meta: [{
			name: 'keywords',
			content: '技术对接,技术交易,技术对接平台,技术创新服务,技术转移,成果转化,技术推广,解决方案'
		},
		{
			name: 'description',
			content: '科技牛是一家专业为企业提供四技服务:技术开发、技术转让、技术咨询、技术服务，精准匹配科研专家及技术解决方案平台公司'
		}
		]
	},
	components: {
		Left
	},
	data() {
		return {
			demand: {
				post: {
					page: 1,
					limit: 4,
					keywod: '',
					status: 9,
				},
				list: [],
				count: 0,
			},

			message: {
				list: [],
				count: 0,
				post: {
					page: 1,
					limit: 5,
					company_id: '',
				}
			},
		}
	},
	mounted() {
		this.get_list()
	},
	methods: {
		get_list() {
			this.$http_qm.post('leave_message/list', this.message.post).then(res => {
				if (res.code == 200) {
					this.message.list = res.data;
					this.message.count = res.count;
				}

			}).catch(err => {
				console.log(err)
			})
		},
		handleCurrentChange(val) {
			console.log(val)
		},
		deleteRow(index, id) {
			this.$http_qm.post('leave_message/del', { id: id }).then(res => {
				if (res.code == 200) {
					this.message.list.splice(index, 1);
				}
			}).catch(err => {
				console.log(err)
			})

		},
		// clickTr(row){
		// 	 row.expanded = !row.expanded;
		// 	 this.$refs.topicTable.toggleRowExpansion(row, row.expanded)
		// },
		handle(cell) {
			if (cell.cellIndex == 4) {
				this.deleteRow()
			}
		}
	}
}
</script>

<style lang="less">
#message {
	margin: 0;
	padding: 0;


	.wrapper {
		padding: 15px 0;
		width: 1200px;
		margin: 0 auto;
		flex-direction: row;
		justify-content: space-between;
		display: flex;

		.main_left {
			width: 200px;
			height: auto;
			text-align: center;
		}

		.main_right {
			width: 980px;
			height: auto;
			min-height: 750px;
			float: right;
			background: #fff;
			border: 1px solid #e0e0e0;
			padding: 15px;
			box-sizing: border-box;

			.page {
				padding: 0;

				.page_nav {
					padding: 0 15px;
					display: flex;
					border-bottom: 3px #0088ff solid;

					.page_nav_tit {
						float: left;
						font-size: 18px;
						font-family: "微软雅黑";
						cursor: pointer;
						margin-right: 20px;
					}

					.page_nav_tit_desc {
						float: left;
						line-height: 16px;
						font-size: 12px;
						margin: 7px 0 0 0;
					}
				}

				.page_list {
					margin-top: 10px;

					td {
						border: none;
					}

					.demo-table-expand {
						font-size: 0;

						label {
							width: 90px;
							color: #99a9bf;
						}

						.el-form-item {
							margin-right: 0;
							margin-bottom: 0;
							width: 50%;
						}
					}

					.product_page {
						text-align: center;
						padding: 10px 0 30px;
					}

					.cell {
						overflow: hidden; //超出的文本隐藏
						text-overflow: ellipsis; //溢出用省略号显示
						white-space: nowrap; //溢出不换行
					}

					.el-table__body {
						width: 100% !important;
					}
				}
			}
		}
	}
}
</style>
